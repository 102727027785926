(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$rootScope', '$state', '$timeout', 'Auth', '$uibModalInstance', 'Principal'];

    function LoginController ($rootScope, $state, $timeout, Auth, $uibModalInstance, Principal) {
        var vm = this;

        vm.authenticationError = false;
        vm.cancel = cancel;
        vm.credentials = {};
        vm.login = login;
        vm.password = null;
        vm.register = register;
        vm.rememberMe = true;
        vm.requestResetPassword = requestResetPassword;
        vm.username = null;

        $timeout(function (){angular.element('#username').focus();});

        function cancel () {
            vm.credentials = {
                username: null,
                password: null,
                rememberMe: true
            };
            vm.authenticationError = false;
            $uibModalInstance.dismiss('cancel');
        }

        function login (event) {
            event.preventDefault();
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function () {
                vm.authenticationError = false;

                Principal.hasAuthority('ROLE_TERMS')
	    	        .then(function (result) {
	    	            if (result) {
	    	            	  $uibModalInstance.close();
	    	                  if ($state.current.name === 'register' || $state.current.name === 'activate' ||
	    	                      $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
	    	                      $state.go('home');
	    	                  }

	    	                  $rootScope.$broadcast('authenticationSuccess');

	    	                  // previousState was set in the authExpiredInterceptor before being redirected to login modal.
	    	                  // since login is succesful, go to stored previousState and clear previousState
	    	                  if (Auth.getPreviousState()) {
	    	                      var previousState = Auth.getPreviousState();
	    	                      Auth.resetPreviousState();
	    	                      $state.go(previousState.name, previousState.params);
	    	                  }
	    	            } else {
	    	            	//NO ROLE USER -> LICENCE NOT ACCEPTED!
	    	            	//redirect to special state
	    	            	  $uibModalInstance.close();
    	                      
	    	                  $rootScope.$broadcast('authenticationSuccess');
	    	            	  
	    	                  if (Auth.getPreviousState()) {
	    	                      Auth.resetPreviousState();
	    	                  }
	    	                  
	    	            	  $state.go('terms-and-conditions');
	    	            }
	            });
            }).catch(function (err) {
            	var message = err && err.data && err.data.message ? err.data.message : '';
            	if (message == 'EXPIRED_CREDENTIALS') {
            		//go to renew password page
                     $uibModalInstance.close();
            		 $state.go('expiredPassword');
            	} else {
            		vm.authenticationError = true;
            	}
            });
        }

        function register () {
            $uibModalInstance.dismiss('cancel');
            $state.go('register');
        }

        function requestResetPassword () {
            $uibModalInstance.dismiss('cancel');
            $state.go('requestReset');
        }
    }
})();
