(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('azienda', {
            parent: 'entity',
            url: '/azienda?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Aziendas'
            },
            params: {
                page: {
                    value: '1',
                    squash: true,
                },
                sort: {
                    value: '',
                    squash: true,
                },
                search: null,
            },  
            views: {
                'content@': {
                    templateUrl: 'app/entities/azienda/aziendas.html',
                    controller: 'AziendaController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            	 pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                     return {
                         page: PaginationUtil.parsePage($stateParams.page),
                         sort: $stateParams.sort,
                         predicate: PaginationUtil.parsePredicate($stateParams.sort),
                         ascending: PaginationUtil.parseAscending($stateParams.sort),
                         search: $stateParams.search
                     };
                 }],  
            },
        	ncyBreadcrumb: {
        		label: 'Elenco aziende'
        	}
        })
        .state('azienda-detail.allegato-new', {
            parent: 'azienda-detail',
            url: '/allegato/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/allegato/allegato-dialog.html',
                    controller: 'AllegatoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                nome: null,
                                descrizione: null,
                                bytes: null,
                                bytesContentType: null,
                                id: null,
                                aziendaId : $stateParams.id
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: 'azienda-detail' });
                }, function() {
                    $state.go('^');
                });
            }]
        })  
        .state('azienda-detail.allegato-edit', {
        	parent: 'azienda-detail',
        	url: '/allegato/{allegatoId}/edit',
        	data: {
        		authorities: ['ROLE_USER']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/allegato/allegato-dialog.html',
        			controller: 'AllegatoDialogController',
        			controllerAs: 'vm',
        			size: 'md',
        			resolve: {
        				entity: ['Allegato', function(Allegato) {
        					return Allegato.get({id : $stateParams.allegatoId}).$promise;
        				}]
        			}
        		}).result.then(function() {
        			$state.go('^', null, { reload: 'azienda-detail' });
        		}, function() {
        			$state.go('^');
        		});
        	}]
        })        
        .state('azienda-detail.allegato-delete', {
            parent: 'azienda-detail',
            url: '/allegato/{allegatoId}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/allegato/allegato-delete-dialog.html',
                    controller: 'AllegatoDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Allegato', function(Allegato) {
                            return Allegato.get({id : $stateParams.allegatoId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: 'azienda-detail' });
                }, function() {
                    $state.go('^');
                });
            }]
        })           
        .state('azienda-detail', {
            parent: 'azienda',
            url: '/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Azienda'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/azienda/azienda-detail.html',
                    controller: 'AziendaDetailController',
                    controllerAs: 'vm'
                },
	            'info@': {
	            	controller: 'AziendaInfoController',
	            	controllerAs: 'vm'
	            }
            },
            resolve: {
                entity: ['$stateParams', 'Azienda', function($stateParams, Azienda) {
                    return Azienda.get({id : $stateParams.id, logo: true}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'azienda',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            },
        	ncyBreadcrumb: {
        		label: 'Dettaglio Azienda'
        	}
        })
        .state('azienda-personale', {
        	parent: 'azienda-detail',
        	url: '/personale?page&sort&search',
        	data: {
        		authorities: ['ROLE_USER'],
        		pageTitle: 'Personale Azienda'
        	},
        	views: {
        		'content@': {
                    templateUrl: 'app/entities/personale/personales.html',
                    controller: 'PersonaleController',
                    controllerAs: 'vm'
        		},
	            'info@': {
	            	controller: 'AziendaInfoController',
	            	controllerAs: 'vm'
	            }
        	},
        	resolve: {
        		 entity: ['$stateParams', 'Azienda', function($stateParams, Azienda) {
                     return Azienda.get({id : $stateParams.id, logo: false}).$promise;
                 }],
                 //idea: state.current.name != personale -> to something i.e. reset params
                 pagingParams: ['$state', '$stateParams', 'PaginationUtil', function ($state, $stateParams, PaginationUtil) {
                     return {
                         page: PaginationUtil.parsePage($stateParams.page),
                         sort: $stateParams.sort,
                         predicate: PaginationUtil.parsePredicate($stateParams.sort),
                         ascending: PaginationUtil.parseAscending($stateParams.sort),
                         search: $stateParams.search
                     };
                 }],     
        	},
        	ncyBreadcrumb: {
        		label: 'Personale'
        	}
        })
        .state('azienda-detail.edit', {
            parent: 'azienda-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/azienda/azienda-dialog.html',
                    controller: 'AziendaDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Azienda', function(Azienda) {
                            return Azienda.get({id : $stateParams.id, logo: true}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('azienda.new', {
            parent: 'azienda',
            url: '/new/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/azienda/azienda-dialog.html',
                    controller: 'AziendaDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                nome: null,
                                logo: null,
                                logoContentType: null,
                                partitaIva: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('azienda', null, { reload: 'azienda' });
                }, function() {
                    $state.go('azienda');
                });
            }]
        })
        .state('azienda.delete', {
            parent: 'azienda',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/azienda/azienda-delete-dialog.html',
                    controller: 'AziendaDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Azienda', function(Azienda) {
                            return Azienda.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('azienda', null, { reload: 'azienda' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
