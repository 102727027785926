(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('ModelloController', ModelloController);

    ModelloController.$inject = ['$scope', '$state', 'Modello'];

    function ModelloController ($scope, $state, Modello) {
        var vm = this;
        
        vm.modellos = [];

        loadAll();

        function loadAll() {
            Modello.query(function(result) {
                vm.modellos = result;
            });
        }
    }
})();
