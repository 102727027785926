(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('TraduzioneDeleteController',TraduzioneDeleteController);

    TraduzioneDeleteController.$inject = ['$uibModalInstance', 'entity', 'Traduzione'];

    function TraduzioneDeleteController($uibModalInstance, entity, Traduzione) {
        var vm = this;

        vm.traduzione = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Traduzione.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
