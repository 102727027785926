(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('PersonaleController', PersonaleController);

    PersonaleController.$inject = ['$scope', '$state', '$stateParams', 'Personale', 'Azienda', 'entity', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];

    function PersonaleController ($scope, $state, $stateParams, Personale, Azienda, entity, ParseLinks, AlertService, pagingParams, paginationConstants) {
        var vm = this;
        
        vm.personales = [];
        
        vm.azienda = entity;

        //paging
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;        

        //search
        vm.search = pagingParams.search;
        vm.clear = clear;

        loadAll();
        
        function loadAll() {
        	 Azienda.getPersonale({
        		 id : vm.azienda.id,
        		 page: vm.page - 1,
                 size: vm.itemsPerPage,
                 sort: sort(),
                 search: vm.search
             }, onSuccess, onError);
             function onSuccess(data, headers) {
                 vm.links = ParseLinks.parse(headers('link'));
                 vm.totalItems = headers('X-Total-Count');
                 vm.queryCount = vm.totalItems;
                 vm.personales = data;
                 vm.searched = vm.search;
             }
             function onError(error) {
                 AlertService.error(error.data.message);
             }
        }
        
        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
        	loadAll();
        	
//            $state.transitionTo($state.$current, {
//                page: vm.page,
//                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
//                search: vm.search,
//                id : $stateParams.id
//            });
            
        }
        
        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }        
        
	    function clear() {
	    	vm.search = null;
	    	vm.transition();
	    }
    }
})();
