(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('DettaglioVCAttrezzaturaController', DettaglioVCAttrezzaturaController);

    DettaglioVCAttrezzaturaController.$inject = ['$scope', '$state', 'DettaglioVCAttrezzatura'];

    function DettaglioVCAttrezzaturaController ($scope, $state, DettaglioVCAttrezzatura) {
        var vm = this;
        
        vm.dettaglioVCAttrezzaturas = [];

        loadAll();

        function loadAll() {
            DettaglioVCAttrezzatura.query(function(result) {
                vm.dettaglioVCAttrezzaturas = result;
            });
        }
    }
})();
