(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('PersonaleDetailController', PersonaleDetailController);

    PersonaleDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Personale', 'TitoloStudio', 'Azienda'];

    function PersonaleDetailController($scope, $rootScope, $stateParams, previousState, entity, Personale, TitoloStudio, Azienda) {
        var vm = this;

        vm.personale = entity;
        vm.previousState = previousState.name;
        vm.foglioPresenzas = [];
        vm.personaleCostos = [];

        loadAll();

        function loadAll() {
        	Personale.getFogliPresenza({id: vm.personale.id}, function(result) {
                vm.foglioPresenzas = result;
            });
        	Personale.getCostos({id: vm.personale.id}, function(result) {
                vm.personaleCostos = result;
            });
        }
        
        var unsubscribe = $rootScope.$on('rendicontaApp:personaleUpdate', function(event, result) {
            vm.personale = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
