(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('dettaglio-vc-attrezzatura', {
            parent: 'entity',
            url: '/dettaglio-vc-attrezzatura',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'DettaglioVCAttrezzaturas'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/dettaglio-vc-attrezzatura/dettaglio-vc-attrezzaturas.html',
                    controller: 'DettaglioVCAttrezzaturaController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('dettaglio-vc-attrezzatura-detail', {
            parent: 'entity',
            url: '/dettaglio-vc-attrezzatura/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'DettaglioVCAttrezzatura'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/dettaglio-vc-attrezzatura/dettaglio-vc-attrezzatura-detail.html',
                    controller: 'DettaglioVCAttrezzaturaDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'DettaglioVCAttrezzatura', function($stateParams, DettaglioVCAttrezzatura) {
                    return DettaglioVCAttrezzatura.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'dettaglio-vc-attrezzatura',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('dettaglio-vc-attrezzatura-detail.edit', {
            parent: 'dettaglio-vc-attrezzatura-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/dettaglio-vc-attrezzatura/dettaglio-vc-attrezzatura-dialog.html',
                    controller: 'DettaglioVCAttrezzaturaDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['DettaglioVCAttrezzatura', function(DettaglioVCAttrezzatura) {
                            return DettaglioVCAttrezzatura.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('dettaglio-vc-attrezzatura.new', {
            parent: 'dettaglio-vc-attrezzatura',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/dettaglio-vc-attrezzatura/dettaglio-vc-attrezzatura-dialog.html',
                    controller: 'DettaglioVCAttrezzaturaDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                nome: null,
                                descrizione: null,
                                dataAcquisto: null,
                                costoIniziale: null,
                                ammortamentoMesi: null,
                                percentualeUso: null,
                                tempoUtilizzoMesi: null,
                                numeroFattura: null,
                                dataFattura: null,
                                id: null,
        						voceCostoId: $stateParams.voceCostoId
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('dettaglio-vc-attrezzatura', null, { reload: 'dettaglio-vc-attrezzatura' });
                }, function() {
                    $state.go('dettaglio-vc-attrezzatura');
                });
            }]
        })
        .state('dettaglio-vc-attrezzatura.edit', {
            parent: 'dettaglio-vc-attrezzatura',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/dettaglio-vc-attrezzatura/dettaglio-vc-attrezzatura-dialog.html',
                    controller: 'DettaglioVCAttrezzaturaDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['DettaglioVCAttrezzatura', function(DettaglioVCAttrezzatura) {
                            return DettaglioVCAttrezzatura.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('dettaglio-vc-attrezzatura', null, { reload: 'dettaglio-vc-attrezzatura' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('dettaglio-vc-attrezzatura.delete', {
            parent: 'dettaglio-vc-attrezzatura',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/dettaglio-vc-attrezzatura/dettaglio-vc-attrezzatura-delete-dialog.html',
                    controller: 'DettaglioVCAttrezzaturaDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['DettaglioVCAttrezzatura', function(DettaglioVCAttrezzatura) {
                            return DettaglioVCAttrezzatura.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('dettaglio-vc-attrezzatura', null, { reload: 'dettaglio-vc-attrezzatura' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('dettaglio-vc-attrezzaturax-new', {
        	parent: 'voce-costo-detailx',
        	url: '/attrezzatura/new',
        	data: {
        		authorities: ['ROLE_USER']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/dettaglio-vc-attrezzatura/dettaglio-vc-attrezzatura-dialog.html',
        			controller: 'DettaglioVCAttrezzaturaDialogController',
        			controllerAs: 'vm',
        			backdrop: 'static',
        			size: 'lg',
        			resolve: {
        				entity: function () {
        					return {
        						nome: null,
        						descrizione: null,
        						dataAcquisto: null,
        						costoIniziale: null,
        						ammortamentoMesi: null,
        						percentualeUso: null,
        						tempoUtilizzoMesi: null,
        						numeroFattura: null,
        						dataFattura: null,
        						id: null,
        						voceCostoId : $stateParams.voceCostoId
        					};
       				 }
        			}
        		}).result.then(function() {
        			$state.go('^', null, { reload: 'voce-costo-detailx'  });
        		}, function() {
        			$state.go('^');
        		});
        	}]
        })
        .state('dettaglio-vc-attrezzaturax-edit', {
        	parent: 'dettaglio-vc-attrezzaturax-detail',
        	url: '/edit',
        	data: {
        		authorities: ['ROLE_USER']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/dettaglio-vc-attrezzatura/dettaglio-vc-attrezzatura-dialog.html',
        			controller: 'DettaglioVCAttrezzaturaDialogController',
        			controllerAs: 'vm',
        			backdrop: 'static',
        			size: 'lg',
        			resolve: {
        				entity: ['DettaglioVCAttrezzatura', function(DettaglioVCAttrezzatura) {
        					return DettaglioVCAttrezzatura.get({id : $stateParams.dettaglioId}).$promise;
        				}]        			}
        		}).result.then(function() {
        			$state.go('^', null, { reload: 'dettaglio-vc-attrezzaturax-detail' });
        		}, function() {
        			$state.go('^');
        		});
        	}], 
        	ncyBreadcrumb: {
        		label: 'Edit'
        	}
        })
        .state('dettaglio-vc-attrezzaturax-delete', {
        	parent: 'voce-costo-detailx',
        	url: '/attrezzatura/{dettaglioId}/delete',
        	data: {
        		authorities: ['ROLE_USER']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/dettaglio-vc-attrezzatura/dettaglio-vc-attrezzatura-delete-dialog.html',
        			controller: 'DettaglioVCAttrezzaturaDeleteController',
        			controllerAs: 'vm',
        			size: 'md',
        			resolve: {
        				entity: ['DettaglioVCAttrezzatura', function(DettaglioVCAttrezzatura) {
        					return DettaglioVCAttrezzatura.get({id : $stateParams.dettaglioId}).$promise;
        				}]
        			}
        		}).result.then(function() {
        			$state.go('^', null, { reload: 'voce-costo-detailx' });
        		}, function() {
        			$state.go('^');
        		});
        	}]
        })
        .state('dettaglio-vc-attrezzaturax-detail', {
        	parent: 'voce-costo-detailx',
        	url: '/attrezzatura/{dettaglioId}/detail',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'DettaglioVCAttrezzatura'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/dettaglio-vc-attrezzatura/dettaglio-vc-attrezzatura-detail.html',
                    controller: 'DettaglioVCAttrezzaturaDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'DettaglioVCAttrezzatura', function($stateParams, DettaglioVCAttrezzatura) {
                    return DettaglioVCAttrezzatura.get({id : $stateParams.dettaglioId}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'dettaglio-vc-attrezzaturax-detail',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }, 
        	ncyBreadcrumb: {
        		label: 'Dettaglio',
        		parent: 'voce-costo-detailx'
        	}
        })
        .state('dettaglio-vc-attrezzaturax-detail.allegato-edit', {
        	parent: 'dettaglio-vc-attrezzaturax-detail',
        	url: '/allegato/{allegatoId}/edit',
        	data: {
        		authorities: ['ROLE_USER']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/allegato/allegato-dialog.html',
        			controller: 'AllegatoDialogController',
        			controllerAs: 'vm',
        			size: 'md',
        			resolve: {
        				entity: ['Allegato', function(Allegato) {
        					return Allegato.get({id : $stateParams.allegatoId}).$promise;
        				}]
        			}
        		}).result.then(function() {
        			$state.go('^', null, { reload: 'dettaglio-vc-attrezzaturax-detail' });
        		}, function() {
        			$state.go('^');
        		});
        	}]
        })         
        .state('dettaglio-vc-attrezzaturax-detail.allegato-delete', {
            parent: 'dettaglio-vc-attrezzaturax-detail',
            url: '/allegato/{allegatoId}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/allegato/allegato-delete-dialog.html',
                    controller: 'AllegatoDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Allegato', function(Allegato) {
                            return Allegato.get({id : $stateParams.allegatoId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: 'dettaglio-vc-attrezzaturax-detail' });
                }, function() {
                    $state.go('^');
                });
            }]
        })       
         .state('dettaglio-vc-attrezzaturax-detail.allegato-new', {
            parent: 'dettaglio-vc-attrezzaturax-detail',
            url: '/allegato/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/allegato/allegato-dialog.html',
                    controller: 'AllegatoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                nome: null,
                                descrizione: null,
                                bytes: null,
                                bytesContentType: null,
                                id: null,
                                voceCostoParentId : $stateParams.voceCostoId,
                                dettaglioVoceCostoId : $stateParams.dettaglioId
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: 'dettaglio-vc-attrezzaturax-detail' });
                }, function() {
                    $state.go('^');
                });
            }]
        }) 
        ;
    }

})();
