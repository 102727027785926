(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('ScadenziarioDetailController', ScadenziarioDetailController);

    ScadenziarioDetailController.$inject = ['$scope', '$state', 'previousState', 'ProgettoAzienda', 'entity'];

    function ScadenziarioDetailController ($scope, $state, previousState, ProgettoAzienda, entity) {
        var vm = this;
        
        vm.progettoAzienda = entity;
        vm.previousState = previousState;
        vm.scadenziario = [];
        
        //CSV export
        vm.download = download;
        vm.missingOnly = true;
        vm.phaseNotStarted = true;
        vm.requiredOnly = true;
        
        vm.livelloLabels = {PR : 'Progetto', PA : 'Azienda', PL : 'Attività', VC: 'Voce Costo', DVC : 'Dettaglio Voce Costo'};
                
        function trackByFn(scadenziario) {
        	return scadenziario.livello+'-'+scadenziario.uniqueId;
        };;
        
        
        vm.trackByFn = trackByFn;
        
        
        function download() {
        	if (!missingOnly) {
        		missingOnly = false;
        	}
        	var data = ProgettoAzienda.exportScadenziario({id: vm.progettoAzienda.id, missingOnly : vm.missingOnly, phaseNotStarted : vm.phaseNotStarted, requiredOnly : vm.requiredOnly}).$promise.then(
        		function (data) {
        			 var file = new Blob([data.data], { type: 'application/vnd.ms-excel' });
        	         saveAs(file, 'scadenziario_'+vm.progettoAzienda.aziendaNome.replace(/\s+/g, '-').toLowerCase() + '.xls');
        		}
        	);
        }

        loadAll();

        function loadAll() {
        	ProgettoAzienda.getScadenziario({id: vm.progettoAzienda.id}, (function(result) {
                 vm.scadenziario = result;
             }));
        }
        
      //goto selected change
        
        //go to detail
        vm.getChangeUrl = getChangeUrl;
        
		function getChangeUrl (change) {
			var url = '';
			if (change.dettaglioVoceCostoId) {
				var state = '';
				var typeToConsider = change.entityType;
				//which state?
				if (typeToConsider == 'DettaglioVCPersonale') {
					state = 'dettaglio-vc-personalex-detail';
				} else if (typeToConsider == 'DettaglioVCConsulenza') {
					state = 'dettaglio-vc-consulenzax-detail';
				} else if (typeToConsider == 'DettaglioVCAttrezzatura') {
					state = 'dettaglio-vc-attrezzaturax-detail';
				} else if (typeToConsider == 'DettaglioVCMateriale') {
					state = 'dettaglio-vc-materialex-detail';
				} 
				
				url = $state.href(state, { 
					dettaglioId: change.dettaglioVoceCostoId,
					voceCostoId: change.voceCostoId,
					paccoLavoroId: change.paccoLavoroId,
					progettoAziendaId: change.progettoAziendaId,
					progettoId: change.progettoId,
				});
			} else if (change.voceCostoId) {
				url = $state.href('voce-costo-detailx', { 
					voceCostoId: change.voceCostoId,
					paccoLavoroId: change.paccoLavoroId,
					progettoAziendaId: change.progettoAziendaId,
					progettoId: change.progettoId,
				});
			} else if (change.paccoLavoroId) {
				url = $state.href('pacco-lavoro-detailx', { 
					paccoLavoroId: change.paccoLavoroId,
					progettoAziendaId: change.progettoAziendaId,
					progettoId: change.progettoId,
				});
			} else if (change.progettoAziendaId) {
				url = $state.href('progetto-azienda-detailx', { 
					progettoAziendaId: change.progettoAziendaId,
					progettoId: change.progettoId,
				});
			} else if (change.progettoId) {
				url = $state.href('progetto-detailx', { 
					progettoId: change.progettoId,
				});
			}
			return url;
		}
    }
})();
