(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('DettaglioVCPersonaleDetailController', DettaglioVCPersonaleDetailController);

    DettaglioVCPersonaleDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'DettaglioVCPersonale', 'Personale', 'LivelloTipologiaAllegato', 'Allegato','DettaglioVC', '$state'];

    function DettaglioVCPersonaleDetailController($scope, $rootScope, $stateParams, previousState, entity, DettaglioVCPersonale, Personale, LivelloTipologiaAllegato, Allegato, DettaglioVC, $state) {
        var vm = this;

        vm.dettaglioVCPersonale = entity;
        vm.previousState = previousState.name;
        
        vm.downloadTimesheet = downloadTimesheet;
        vm.isTimesheetHidden = isTimesheetHidden;
        
        vm.checklist = LivelloTipologiaAllegato.query({voceCostoParentId: $stateParams.voceCostoId});
        
        vm.valida = function(value) {
        	DettaglioVC.validate({id : vm.dettaglioVCPersonale.id, value : value}, {}).$promise.then(
        		function() {
        			$state.reload();
        		}
        	);
        }
        
        function isActiveDate(date) {
	   		 if (vm.timesheetInizio && vm.timesheetFine) {
	   			 if (date >= vm.timesheetInizio && date <= vm.timesheetFine) {
	   				 return true;
	   			 } else {
	   				 return false;
	   			 }
	   		 } else {
	   			 return true;
	   		 }
        }
        
        function isTimesheetHidden(year) {
       	 if (!vm.timesheetInizio|| !vm.timesheetFine) {
   			 //no from/to param set
   			 return false;
   		 }
   		 
   		 var active = false;

   		 //check id there is at least one row to show
       	 for (var index = 1; index <= 12; index++) {
       		 var firstDay = new Date(year, index-1); //month in javascript starts from 0
       		 var lastDay = new Date(year, index, 0 ); //day 0: day before
       		 
       		 if (!isActiveDate(firstDay) && !isActiveDate(lastDay)
       				 && !(vm.timesheetInizio < lastDay && vm.timesheetFine > firstDay)
       		 ) {
       			 //no need to show row
       		 } else {
       			 active = true;
       			 break;
       		 }
       	 }
       	 return !active;
       }
        
        function downloadTimesheet() {
        	var data = DettaglioVCPersonale.downloadTimesheet({id: vm.dettaglioVCPersonale.id}).$promise.then(
        		function (data) {
        			 var file = new Blob([data.data], { type: 'application/vnd.ms-excel' });
        	         saveAs(file, 'timesheet_'+vm.dettaglioVCPersonale.personaleCognome.replace(/\s+/g, '-').toLowerCase() + '.xls');
        		}
        	);
        }
        
        creaMatrice();
        calcolaInizioFineSal();
        
        Allegato.query({dettaglioVoceCostoId : entity.id}).$promise.then(function (allegati) {
        	vm.allegatos = allegati;
   		 	vm.tipologieCaricateIds = allegati.map(function(all) { return all.tipologiaId });
        });
        
        function removeHHMM(date) {
        	return new Date(date.getFullYear(), date.getMonth(), date.getDate());
        }
        
        function calcolaInizioFineSal () {
        	
        	var salDataInizio = removeHHMM(new Date(vm.dettaglioVCPersonale.salDataInizio));
    		var salDataFine = removeHHMM(new Date(vm.dettaglioVCPersonale.salDataFine));
    		var faseDataInizio = removeHHMM(new Date(vm.dettaglioVCPersonale.faseDataInizio));
    		var faseDataFine = removeHHMM(new Date(vm.dettaglioVCPersonale.faseDataFine));

    		//intersect
    		vm.timesheetInizio = salDataInizio > faseDataInizio ? salDataInizio : faseDataInizio;
   			vm.timesheetFine = salDataFine < faseDataFine ? salDataFine : faseDataFine;
        }
        
        function creaMatrice () {
    		vm.anno = new Date(vm.dettaglioVCPersonale.salDataFine).getFullYear();
    		
        	if (vm.dettaglioVCPersonale.id && vm.dettaglioVCPersonale.timesheet) {
        		//parse value
        		vm.matrice = JSON.parse(vm.dettaglioVCPersonale.timesheet);
        	}
        }
        
        var unsubscribe = $rootScope.$on('rendicontaApp:dettaglioVCPersonaleUpdate', function(event, result) {
            vm.dettaglioVCPersonale = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
