(function() {
	'use strict';
	angular
			.module('ui.bootstrap.datetimepicker',
					[ 'ui.bootstrap.dateparser', 'ui.bootstrap.position' ])
			.constant(
					'uibDatepickerPopupConfig',
					{
						altInputFormats : [],
						appendToBody : false,
						clearText : 'Cancella',
						closeOnDateSelection : true,
						closeText : 'Fatto',
						currentText : 'Oggi',
						datepickerPopup : 'yyyy-MM-dd',
						datepickerPopupTemplateUrl : 'uib/template/datepickerPopup/popup.html',
						datepickerTemplateUrl : 'uib/template/datepicker/datepicker.html',
						html5Types : {
							date : 'yyyy-MM-dd',
							'datetime-local' : 'yyyy-MM-ddTHH:mm:ss.sss',
							'month' : 'yyyy-MM'
						},
						onOpenFocus : true,
						showButtonBar : true,
						placement : 'auto bottom-left'
					})

})();
