/* globals $ */
(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .directive('timesheet', timesheet);

    function timesheet () {
        var directive = {
            restrict: 'A',
            scope:{ 
            	year: '=year',
            	matrix: '=matrix',
            	upperbound: '=upperbound',
            	modify: '@modify',
            	from: '=from',
            	to: '=to',
            	checkupperbound: '=checkupperbound',
            },
            templateUrl: 'app/components/progetto/timesheet.directive.html',
            controller: ['$scope', 'TimesheetUtil', function TimesheetController($scope, TimesheetUtil) {
            	 //year calendar
            	var vm = {};
            	$scope.vm = vm; 
            	
            	vm.totale = totale;
            	vm.errorDays = {};
            	vm.hasErrorDays = hasErrorDays;
            	
            	vm.isHidden = isHidden;
            	vm.isCheckUBEnabled = isCheckUBEnabled;
            	vm.isOverflowCell = isOverflowCell;
            	
                vm.mesi = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];

                creaMatrice();
                calcolaDayType();
  
                if ($scope.modify == "false") {
                	vm.tableClass = 'ts_readonly';
                }
                
                $scope.$watch('year', function() {
                    calcolaDayType();
                });

                function hasErrorDays() {    
                	return $scope.checkupperbound && Object.keys(vm.errorDays).length > 0;
                }

                function isCheckUBEnabled() {    
                	return $scope.checkupperbound;
                }
                
                function isOverflowCell(month,day) {    
                	try {
						if (typeof ($scope.upperbound[$scope.year]) !== 'undefined') {
							//check limit
							if (month == 0 || month > 12) {
								return false;
							}
							if ($scope.upperbound[$scope.year][month][day] < $scope.matrix[month][day]) {
								vm.errorDays[month * 100 + day] = 1;
								return true;
							} else {
								delete vm.errorDays[month * 100 + day];
							}
						}
						return false;
					} catch (e) {
						return false;
					}
                }
                
                function roundToTwo(num) {    
                	return +(Math.round(num + "e+2")  + "e-2");
                }
                
                function totale(mese) {
                	var totale = 0;
                	if (!mese) {
                		return totale
                	}
                	var length = mese.length;
                	for (var i = 0; i < length ; i++) {
                		totale += Number(mese[i]) || 0;
                 	}
                	return roundToTwo(totale);
                }

                function isHidden(index) {
            		 if (index == 0) {
            			 //first row
            			 return true;
            		 }
            		
            		 if (!$scope.from || !$scope.to) {
            			 //no from/to param set
            			 return false;
            		 }
            		 
            		 var firstDay = new Date($scope.year, index-1); //month in javascript starts from 0
            		 var lastDay = new Date($scope.year, index, 0 ); //day 0: day before
            		 
            		 var active = false;
            		 
            		 if (!isActive(firstDay) && !isActive(lastDay)
            				 && !($scope.to < lastDay && $scope.from > firstDay)
            		 ) {
            			 //no need to show row
            			 return true;
            		 } else {
            			 return false;
            		 }
            	 }
            		
            	
            	 function creaMatrice() {
                	 if (!$scope.matrix) {
                     	//create new
                     	var dt = $scope.matrix = new Array();
                     	
                     	for (var m = 0; m <= 12; m++) {
                     		dt[m] = new Array();
                     		for (var d = 0; d <= 31; d++) {
                     			dt[m][d] = "";	
                     		}
                     	}
                     } 
                }
            	 
            	 function calcolaDayType() {
                 	//dayType stuff
                 	  vm.types = [];
                       if ($scope.year) {
                       	var easter = TimesheetUtil.easterDate($scope.year);
                       	
                       	var ds = vm.types;
                       	
                       	for (var m = 1; m <= 12; m++) {
                       		ds[m] = new Array();
                       		var date;
                       		for (var d = 1; d <= 31; d++) {
                       			date = TimesheetUtil.buildDate($scope.year, m, d);
                       			if (!date) {
                       				//disabled
                           			ds[m][d] = 'dateNE'; //disabilitato	
                           			$scope.matrix[m][d] = '';	
                       			} else if (!isActive(date)) {
                           			ds[m][d] = 'dateNE'; //non incluso nel SAL	
                           			$scope.matrix[m][d] = '';	
                       			} else if (TimesheetUtil.isHoliday(date, easter)) {
                       					ds[m][d] = 'dateH'; //festivo
                       			} else if (date.getDay() === 0 || date.getDay() === 6) {
                       				ds[m][d] = 'dateW'; //weekend
                       			} else {
                       				//normale
                       				ds[m][d] = ''; //weekday
                       			};
                       		}
                       	}
                       }
                 }
                 
            	 function isActive(date) {
            		 if ($scope.from && $scope.to) {
            			 if (date >= $scope.from && date <= $scope.to) {
            				 return true;
            			 } else {
            				 return false;
            			 }
            		 } else {
            			 return true;
            		 }
            	 }
            	 
                 
              }]            
        };
        return directive;
    }

})();
