(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .filter('yesOrNo', yesOrNo);

    function yesOrNo() {
        return yesOrNoFilter;

        function yesOrNoFilter (input) {
        	return input == true ? 'Si' : 'No' ;
        }
    }
})();
