(function() {
	'use strict';
	 
	angular
	 .module('rendicontaApp')
					.config(['$uibTooltipProvider', function($uibTooltipProvider) {
						$uibTooltipProvider.options({
					        'appendToBody' : true,
					    });
					}]);

})();
