(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('TipologiaAllegatoController', TipologiaAllegatoController);

    TipologiaAllegatoController.$inject = ['$scope', '$state', 'TipologiaAllegato'];

    function TipologiaAllegatoController ($scope, $state, TipologiaAllegato) {
        var vm = this;
        
        vm.tipologiaAllegatoes = [];

		vm.currentPage = 1;
		vm.pageSize = 10;
		vm.totalItems = 0;	
		vm.currentPageItems = [];
		
		vm.updateCurrentPageItems = function() {
			if (vm.tipologiaAllegatoes) {
				vm.currentPageItems = vm.tipologiaAllegatoes.slice(((vm.currentPage-1)*vm.pageSize), ((vm.currentPage)*vm.pageSize)); 
			} else {
				vm.currentPageItems = [];
			}
		}

        loadAll();
        
        function loadAll() {
            TipologiaAllegato.query(function(result) {
            	result = (result || []).sort(function(a, b) { return (a.nome || '').localeCompare(b.nome || '') });
                vm.tipologiaAllegatoes = result;
				vm.totalItems = result ? result.length : 0;
				vm.updateCurrentPageItems();                
            });
        }
    }
})();
