(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('StatoCalcolatoDialogController', StatoCalcolatoDialogController);

    StatoCalcolatoDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'StatoCalcolato', 'Progetto', 'ProgettoAzienda', 'PaccoLavoro', 'VoceCosto'];

    function StatoCalcolatoDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, StatoCalcolato, Progetto, ProgettoAzienda, PaccoLavoro, VoceCosto) {
        var vm = this;

        vm.statoCalcolato = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.statoCalcolato.id !== null) {
                StatoCalcolato.update(vm.statoCalcolato, onSaveSuccess, onSaveError);
            } else {
                StatoCalcolato.save(vm.statoCalcolato, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('rendicontaApp:statoCalcolatoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
