(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('ModelloCloneController', ModelloCloneController);

    ModelloCloneController.$inject = ['$timeout', '$scope', '$rootScope', '$stateParams', 'entity', 'Modello', '$uibModalInstance', 'AlertService'];

    function ModelloCloneController($timeout, $scope, $rootScope, $stateParams, entity, Modello, $uibModalInstance, AlertService) {
        var vm = this;

        vm.modello = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            Modello.clone({id : vm.modello.id}, vm.modello, onSaveSuccess, onSaveError);
        }


        function onSaveSuccess (result) {
            $scope.$emit('rendicontaApp:progettoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
            AlertService.success("Modello creato con successo");
        }

        function onSaveError () {
            vm.isSaving = false;
        }
        
        var unsubscribe = $rootScope.$on('rendicontaApp:modelloUpdate', function(event, result) {
            vm.modello = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
