(function() {
    'use strict';
    angular
        .module('rendicontaApp')
        .factory('Sal', Sal);

    Sal.$inject = ['$resource', 'DateUtils'];

    function Sal ($resource, DateUtils) {
        var resourceUrl =  'api/sals/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dataInizio = DateUtils.convertLocalDateFromServer(data.dataInizio);
                        data.dataFine = DateUtils.convertLocalDateFromServer(data.dataFine);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dataInizio = DateUtils.convertLocalDateToServer(copy.dataInizio);
                    copy.dataFine = DateUtils.convertLocalDateToServer(copy.dataFine);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dataInizio = DateUtils.convertLocalDateToServer(copy.dataInizio);
                    copy.dataFine = DateUtils.convertLocalDateToServer(copy.dataFine);
                    return angular.toJson(copy);
                }
            },
            'downloadDVC': {
            	method: 'GET',
            	url: 'api/sals/:id/downloadDVC',
            	responseType: 'arraybuffer',
            	transformResponse: function(data, headersGetter) { 
            		return { data : data }
            	}            
            },
	        'downloadTS': {
	        	method: 'GET',
	        	url: 'api/sals/:id/downloadTS',
	        	responseType: 'arraybuffer',
	        	transformResponse: function(data, headersGetter) { 
	        		return { data : data }
	        	}            
	        },
	        'downloadCreditoImposta': {
	        	method: 'GET',
	        	url: 'api/sals/:id/downloadCreditoImposta',
	        	responseType: 'arraybuffer',
	        	transformResponse: function(data, headersGetter) { 
	        		return { data : data }
	        	}            
	        },
	        'getCreditoImposta': {
                method: 'GET',
            	url: 'api/sals/:id/creditoImposta',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
        });
    }
})();
