(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('pacco-lavoro', {
            parent: 'entity',
            url: '/pacco-lavoro',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'PaccoLavoros'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/pacco-lavoro/pacco-lavoros.html',
                    controller: 'PaccoLavoroController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('pacco-lavoro-detail', {
            parent: 'entity',
            url: '/pacco-lavoro/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Attività'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/pacco-lavoro/pacco-lavoro-detail.html',
                    controller: 'PaccoLavoroDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'PaccoLavoro', function($stateParams, PaccoLavoro) {
                    return PaccoLavoro.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'pacco-lavoro',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('pacco-lavoro-detailx', {
        	parent: 'progetto-azienda-detailx',
        	url: '/pacco-lavoro/{paccoLavoroId}',
        	data: {
        		authorities: ['ROLE_USER'],
        		pageTitle: 'Attività'
        	},
        	views: {
        		'content@': {
        			templateUrl: 'app/entities/pacco-lavoro/pacco-lavoro-detailx.html',
        			controller: 'PaccoLavoroDetailController',
        			controllerAs: 'vm'
        		}
        	},
        	resolve: {
        		entity: ['$stateParams', 'PaccoLavoro', 'StatoCertificazione', function($stateParams, PaccoLavoro, StatoCertificazione) {
        			var entity = PaccoLavoro.get({id : $stateParams.paccoLavoroId}).$promise;
        			entity.then(function (entity) {StatoCertificazione.setCertificato(entity.certificato)});
        			return entity;
        		}],
        		previousState: ["$state", function ($state) {
        			var currentStateData = {
        					name: $state.current.name || 'pacco-lavoro',
        					params: $state.params,
        					url: $state.href($state.current.name, $state.params)
        			};
        			return currentStateData;
        		}]
        	}, 
        	ncyBreadcrumb: {
        		label: 'Attività',
        		parent: 'progetto-azienda-detailx'
        	}
        })
        .state('pacco-lavoro-detail.edit', {
            parent: 'pacco-lavoro-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/pacco-lavoro/pacco-lavoro-dialog.html',
                    controller: 'PaccoLavoroDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PaccoLavoro', function(PaccoLavoro) {
                            return PaccoLavoro.get({id : $stateParams.paccoLavoroId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('pacco-lavoro.new', {
            parent: 'pacco-lavoro',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/pacco-lavoro/pacco-lavoro-dialog.html',
                    controller: 'PaccoLavoroDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('pacco-lavoro', null, { reload: 'pacco-lavoro' });
                }, function() {
                    $state.go('pacco-lavoro');
                });
            }]
        })
        .state('pacco-lavoro-detailx.edit', {
            parent: 'pacco-lavoro-detailx',
            url: '/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/pacco-lavoro/pacco-lavoro-dialog.html',
                    controller: 'PaccoLavoroDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PaccoLavoro', function(PaccoLavoro) {
                            return PaccoLavoro.get({id : $stateParams.paccoLavoroId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: 'pacco-lavoro-detailx' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('pacco-lavoro.delete', {
            parent: 'pacco-lavoro',
            url: '/{paccoLavoroId}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/pacco-lavoro/pacco-lavoro-delete-dialog.html',
                    controller: 'PaccoLavoroDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['PaccoLavoro', function(PaccoLavoro) {
                            return PaccoLavoro.get({id : $stateParams.paccoLavoroId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('pacco-lavoro', null, { reload: 'pacco-lavoro' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('pacco-lavoro-detailx.voce-costo-delete', {
            parent: 'pacco-lavoro-detailx',
            url: '/voce-costo/{voceCostoId}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/voce-costo/voce-costo-delete-dialog.html',
                    controller: 'VoceCostoDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['VoceCosto', function(VoceCosto) {
                            return VoceCosto.get({id : $stateParams.voceCostoId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: 'pacco-lavoro-detailx' });
                }, function() {
                    $state.go('^');
                });
            }]
        })       
        .state('pacco-lavoro-detailx.allegato-delete', {
        	parent: 'pacco-lavoro-detailx',
        	url: '/allegato/{allegatoId}/delete',
        	data: {
        		authorities: ['ROLE_USER']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/allegato/allegato-delete-dialog.html',
        			controller: 'AllegatoDeleteController',
        			controllerAs: 'vm',
        			size: 'md',
        			resolve: {
        				entity: ['Allegato', function(Allegato) {
        					return Allegato.get({id : $stateParams.allegatoId}).$promise;
        				}]
        			}
        		}).result.then(function() {
        			$state.go('^', null, { reload: 'pacco-lavoro-detailx' });
        		}, function() {
        			$state.go('^');
        		});
        	}]
        })       
        .state('pacco-lavoro-detailx.allegato-edit', {
        	parent: 'pacco-lavoro-detailx',
        	url: '/allegato/{allegatoId}/edit',
        	data: {
        		authorities: ['ROLE_USER']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/allegato/allegato-dialog.html',
        			controller: 'AllegatoDialogController',
        			controllerAs: 'vm',
        			size: 'md',
        			resolve: {
        				entity: ['Allegato', function(Allegato) {
        					return Allegato.get({id : $stateParams.allegatoId}).$promise;
        				}]
        			}
        		}).result.then(function() {
        			$state.go('^', null, { reload: 'pacco-lavoro-detailx' });
        		}, function() {
        			$state.go('^');
        		});
        	}]
        })                
         .state('pacco-lavoro-detailx.allegato-new', {
            parent: 'pacco-lavoro-detailx',
            url: '/allegato/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/allegato/allegato-dialog.html',
                    controller: 'AllegatoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                nome: null,
                                descrizione: null,
                                bytes: null,
                                bytesContentType: null,
                                id: null,
                                paccoLavoroId : $stateParams.paccoLavoroId
                            };
                        },
                        relatedEntity: ['PaccoLavoro', function(PaccoLavoro) {
                            return PaccoLavoro.get({id : $stateParams.paccoLavoroId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: 'pacco-lavoro-detailx' });
                }, function() {
                    $state.go('^');
                });
            }]
        }) 
        ;        
    }

})();
