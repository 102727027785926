(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('ruolo', {
            parent: 'entity',
            url: '/ruolo',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'Ruolos'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/ruolo/ruolos.html',
                    controller: 'RuoloController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('ruolo-detail', {
            parent: 'entity',
            url: '/ruolo/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'Ruolo'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/ruolo/ruolo-detail.html',
                    controller: 'RuoloDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'Ruolo', function($stateParams, Ruolo) {
                    return Ruolo.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'ruolo',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('ruolo-detail.edit', {
            parent: 'ruolo-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/ruolo/ruolo-dialog.html',
                    controller: 'RuoloDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Ruolo', function(Ruolo) {
                            return Ruolo.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('ruolo.new', {
            parent: 'ruolo',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/ruolo/ruolo-dialog.html',
                    controller: 'RuoloDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                codice: null,
                                nome: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('ruolo', null, { reload: 'ruolo' });
                }, function() {
                    $state.go('ruolo');
                });
            }]
        })
        .state('ruolo.edit', {
            parent: 'ruolo',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/ruolo/ruolo-dialog.html',
                    controller: 'RuoloDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Ruolo', function(Ruolo) {
                            return Ruolo.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('ruolo', null, { reload: 'ruolo' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('ruolo.delete', {
            parent: 'ruolo',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/ruolo/ruolo-delete-dialog.html',
                    controller: 'RuoloDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Ruolo', function(Ruolo) {
                            return Ruolo.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('ruolo', null, { reload: 'ruolo' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
