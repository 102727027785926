(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('ProgettoAziendaController', ProgettoAziendaController);

    ProgettoAziendaController.$inject = ['$scope', '$state', 'ProgettoAzienda'];

    function ProgettoAziendaController ($scope, $state, ProgettoAzienda) {
        var vm = this;
        
        vm.progettoAziendas = [];

        loadAll();

        function loadAll() {
            ProgettoAzienda.query(function(result) {
                vm.progettoAziendas = result;
            });
        }
    }
})();
