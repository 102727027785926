(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('AllegatoController', AllegatoController);

    AllegatoController.$inject = ['$scope', '$state', 'DataUtils', 'Allegato'];

    function AllegatoController ($scope, $state, DataUtils, Allegato) {
        var vm = this;
        
        vm.allegatoes = [];
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;

        loadAll();

        function loadAll() {
            Allegato.query(function(result) {
                vm.allegatoes = result;
            });
        }
    }
})();
