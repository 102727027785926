(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('DettaglioVCController', DettaglioVCController);

    DettaglioVCController.$inject = ['$scope', '$state', 'DettaglioVC'];

    function DettaglioVCController ($scope, $state, DettaglioVC) {
        var vm = this;
        
        vm.dettaglioVCS = [];

        loadAll();

        function loadAll() {
            DettaglioVC.query(function(result) {
                vm.dettaglioVCS = result;
            });
        }
    }
})();
