(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('VoceCostoController', VoceCostoController);

    VoceCostoController.$inject = ['$scope', '$state', 'VoceCosto'];

    function VoceCostoController ($scope, $state, VoceCosto) {
        var vm = this;
        
        vm.voceCostos = [];

        loadAll();

        function loadAll() {
            VoceCosto.query(function(result) {
                vm.voceCostos = result;
            });
        }
    }
})();
