(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('dettaglio-vc-consulenza', {
            parent: 'entity',
            url: '/dettaglio-vc-consulenza',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'DettaglioVCConsulenzas'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/dettaglio-vc-consulenza/dettaglio-vc-consulenzas.html',
                    controller: 'DettaglioVCConsulenzaController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('dettaglio-vc-consulenza-detail', {
            parent: 'entity',
            url: '/dettaglio-vc-consulenza/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'DettaglioVCConsulenza'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/dettaglio-vc-consulenza/dettaglio-vc-consulenza-detail.html',
                    controller: 'DettaglioVCConsulenzaDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'DettaglioVCConsulenza', function($stateParams, DettaglioVCConsulenza) {
                    return DettaglioVCConsulenza.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'dettaglio-vc-consulenza',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('dettaglio-vc-consulenza-detail.edit', {
            parent: 'dettaglio-vc-consulenza-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/dettaglio-vc-consulenza/dettaglio-vc-consulenza-dialog.html',
                    controller: 'DettaglioVCConsulenzaDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['DettaglioVCConsulenza', function(DettaglioVCConsulenza) {
                            return DettaglioVCConsulenza.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('dettaglio-vc-consulenza.new', {
            parent: 'dettaglio-vc-consulenza',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/dettaglio-vc-consulenza/dettaglio-vc-consulenza-dialog.html',
                    controller: 'DettaglioVCConsulenzaDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                descrizione: null,
                                costoImponibile: null,
                                fornitore: null,
                                numeroFattura: null,
                                dataFattura: null,
                                estremiContratto: null,
                                dataPagamento: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('dettaglio-vc-consulenza', null, { reload: 'dettaglio-vc-consulenza' });
                }, function() {
                    $state.go('dettaglio-vc-consulenza');
                });
            }]
        })
        .state('dettaglio-vc-consulenzax-new', {
        	parent: 'voce-costo-detailx',
        	url: '/consulenza/new',
        	data: {
        		authorities: ['ROLE_USER']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/dettaglio-vc-consulenza/dettaglio-vc-consulenza-dialog.html',
        			controller: 'DettaglioVCConsulenzaDialogController',
        			controllerAs: 'vm',
        			backdrop: 'static',
        			size: 'lg',
        			resolve: {
        				entity: function () {
        					return {
        						descrizione: null,
        						costoImputabile: null,
                                percentualeImputazione: 100,
        						fornitore: null,
        						numeroFattura: null,
        						dataFattura: null,
        						estremiContratto: null,
        						dataPagamento: null,
        						id: null,
        						voceCostoId : $stateParams.voceCostoId
        					};
       				 }     			}
        		}).result.then(function() {
        			$state.go('^', null, { reload: 'voce-costo-detailx' });
        		}, function() {
        			$state.go('^');
        		});
        	}]
        })
        .state('dettaglio-vc-consulenza.edit', {
            parent: 'dettaglio-vc-consulenza',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/dettaglio-vc-consulenza/dettaglio-vc-consulenza-dialog.html',
                    controller: 'DettaglioVCConsulenzaDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['DettaglioVCConsulenza', function(DettaglioVCConsulenza) {
                            return DettaglioVCConsulenza.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('dettaglio-vc-consulenza', null, { reload: 'dettaglio-vc-consulenza' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('dettaglio-vc-consulenzax-edit', {
        	parent: 'dettaglio-vc-consulenzax-detail',
        	url: '/edit',
        	data: {
        		authorities: ['ROLE_USER']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/dettaglio-vc-consulenza/dettaglio-vc-consulenza-dialog.html',
        			controller: 'DettaglioVCConsulenzaDialogController',
        			controllerAs: 'vm',
        			backdrop: 'static',
        			size: 'lg',
        			resolve: {
        				entity: ['DettaglioVCConsulenza', function(DettaglioVCConsulenza) {
        					return DettaglioVCConsulenza.get({id : $stateParams.dettaglioId}).$promise;
        				}]       			}
        		}).result.then(function() {
        			$state.go('^', null, { reload: 'dettaglio-vc-consulenzax-detail' });
        		}, function() {
        			$state.go('^');
        		});
        	}], 
        	ncyBreadcrumb: {
        		label: 'Edit'
        	}
        })
        .state('dettaglio-vc-consulenza.delete', {
            parent: 'dettaglio-vc-consulenza',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/dettaglio-vc-consulenza/dettaglio-vc-consulenza-delete-dialog.html',
                    controller: 'DettaglioVCConsulenzaDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['DettaglioVCConsulenza', function(DettaglioVCConsulenza) {
                            return DettaglioVCConsulenza.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('dettaglio-vc-consulenza', null, { reload: 'dettaglio-vc-consulenza' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('dettaglio-vc-consulenzax-delete', {
        	parent: 'voce-costo-detailx',
        	url: '/consulenza/{dettaglioId}/delete',
        	data: {
        		authorities: ['ROLE_USER']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/dettaglio-vc-consulenza/dettaglio-vc-consulenza-delete-dialog.html',
        			controller: 'DettaglioVCConsulenzaDeleteController',
        			controllerAs: 'vm',
        			size: 'md',
        			resolve: {
        				entity: ['DettaglioVCConsulenza', function(DettaglioVCConsulenza) {
        					return DettaglioVCConsulenza.get({id : $stateParams.dettaglioId}).$promise;
        				}]
        			}
        		}).result.then(function() {
        			$state.go('^', null, { reload: 'voce-costo-detailx' });
        		}, function() {
        			$state.go('^');
        		});
        	}]
        })
        .state('dettaglio-vc-consulenzax-detail', {
        	parent: 'voce-costo-detailx',
        	url: '/consulenza/{dettaglioId}/detail',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'DettaglioVCConsulenza'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/dettaglio-vc-consulenza/dettaglio-vc-consulenza-detail.html',
                    controller: 'DettaglioVCConsulenzaDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'DettaglioVCConsulenza', function($stateParams, DettaglioVCConsulenza) {
                    return DettaglioVCConsulenza.get({id : $stateParams.dettaglioId}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'dettaglio-vc-consulenza',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }, 
        	ncyBreadcrumb: {
        		label: 'Dettaglio',
        		parent: 'voce-costo-detailx'
        	}
        })        
        .state('dettaglio-vc-consulenzax-detail.allegato-edit', {
        	parent: 'dettaglio-vc-consulenzax-detail',
        	url: '/allegato/{allegatoId}/edit',
        	data: {
        		authorities: ['ROLE_USER']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/allegato/allegato-dialog.html',
        			controller: 'AllegatoDialogController',
        			controllerAs: 'vm',
        			size: 'md',
        			resolve: {
        				entity: ['Allegato', function(Allegato) {
        					return Allegato.get({id : $stateParams.allegatoId}).$promise;
        				}]
        			}
        		}).result.then(function() {
        			$state.go('^', null, { reload: 'dettaglio-vc-consulenzax-detail' });
        		}, function() {
        			$state.go('^');
        		});
        	}]
        })         
        .state('dettaglio-vc-consulenzax-detail.allegato-delete', {
            parent: 'dettaglio-vc-consulenzax-detail',
            url: '/allegato/{allegatoId}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/allegato/allegato-delete-dialog.html',
                    controller: 'AllegatoDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Allegato', function(Allegato) {
                            return Allegato.get({id : $stateParams.allegatoId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: 'dettaglio-vc-consulenzax-detail' });
                }, function() {
                    $state.go('^');
                });
            }]
        })       
         .state('dettaglio-vc-consulenzax-detail.allegato-new', {
            parent: 'dettaglio-vc-consulenzax-detail',
            url: '/allegato/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/allegato/allegato-dialog.html',
                    controller: 'AllegatoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                nome: null,
                                descrizione: null,
                                bytes: null,
                                bytesContentType: null,
                                id: null,
                                voceCostoParentId : $stateParams.voceCostoId,
                                dettaglioVoceCostoId : $stateParams.dettaglioId
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: 'dettaglio-vc-consulenzax-detail' });
                }, function() {
                    $state.go('^');
                });
            }]
        })         
        ;
    }

})();
