(function() {
    'use strict';
    angular
        .module('rendicontaApp')
        .factory('Progetto', Progetto);

    Progetto.$inject = ['$resource', 'DateUtils'];

    function Progetto ($resource, DateUtils) {
        var resourceUrl =  'api/progettos/:id';

        return $resource(resourceUrl, {}, {
            'query': { 
            	method: 'GET', 
            	isArray: true,
            },
            'getProfiliUtente' : {
            	method: 'GET', 
            	isArray: true,
            	url: 'api/progettos/:id/profili-utente'
            },
            'getProfili' : {
            	method: 'GET', 
            	isArray: true,
            	url: 'api/progettos/:id/profili'
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.inizio = DateUtils.convertLocalDateFromServer(data.inizio);
                        data.fine = DateUtils.convertLocalDateFromServer(data.fine);
                        data.disabilitazione = DateUtils.convertLocalDateFromServer(data.disabilitazione);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.inizio = DateUtils.convertLocalDateToServer(copy.inizio);
                    copy.fine = DateUtils.convertLocalDateToServer(copy.fine);
                    copy.disabilitazione = DateUtils.convertLocalDateToServer(copy.disabilitazione);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.inizio = DateUtils.convertLocalDateToServer(copy.inizio);
                    copy.fine = DateUtils.convertLocalDateToServer(copy.fine);
                    copy.disabilitazione = DateUtils.convertLocalDateToServer(copy.disabilitazione);
                    return angular.toJson(copy);
                }
            },
            'addpm': {
            	url: 'api/progettos/:id/addpm',
            	method: 'POST'
            }
        });
    }
})();
