(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('TipologiaPaccoLavoroController', TipologiaPaccoLavoroController);

    TipologiaPaccoLavoroController.$inject = ['$scope', '$state', 'TipologiaPaccoLavoro'];

    function TipologiaPaccoLavoroController ($scope, $state, TipologiaPaccoLavoro) {
        var vm = this;
        
        vm.tipologiaPaccoLavoros = [];

		vm.currentPage = 1;
		vm.pageSize = 10;
		vm.totalItems = 0;		
		vm.currentPageItems = [];
		
		vm.updateCurrentPageItems = function() {
			if (vm.tipologiaPaccoLavoros) {
				vm.currentPageItems = vm.tipologiaPaccoLavoros.slice(((vm.currentPage-1)*vm.pageSize), ((vm.currentPage)*vm.pageSize)); 
			} else {
				vm.currentPageItems = [];
			}
		}	
		
        loadAll();

        function loadAll() {
            TipologiaPaccoLavoro.query(function(result) {
            	result = (result || []).sort(function(a, b) { return (a.nome || '').localeCompare(b.nome || '') });

                vm.tipologiaPaccoLavoros = result;
				vm.totalItems = result ? result.length : 0;
				vm.updateCurrentPageItems();                     
            });
        }
    }
})();
