(function() {
    'use strict';
    angular
        .module('rendicontaApp')
        .factory('Allegato', Allegato);

    Allegato.$inject = ['$resource'];

    function Allegato ($resource) {
        var resourceUrl =  'api/allegatoes/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'download': {
            	method: 'GET',
            	url: 'api/allegatoes/:id/download',
            	responseType: 'arraybuffer',
            	transformResponse: function(data, headersGetter) { 
            		return { data : data }
            	}            
            },                
            'update': { method:'PUT' }
        });
    }
})();
