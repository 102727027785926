/* globals $ */
(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .directive('certificazione', certificazione);

    function certificazione () {
        var directive = {
            restrict: 'A',
            scope:{ 
            	vm: '=vm',
            	stato: '=stato',
            	entity: '=entity'
            },
            templateUrl: 'app/components/progetto/certificazione.directive.html'
        };
        return directive;
    }

})();
