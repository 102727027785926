(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('ModelloTipologiaPaccoLavoroController', ModelloTipologiaPaccoLavoroController);

    ModelloTipologiaPaccoLavoroController.$inject = ['$scope', '$state', 'ModelloTipologiaPaccoLavoro'];

    function ModelloTipologiaPaccoLavoroController ($scope, $state, ModelloTipologiaPaccoLavoro) {
        var vm = this;
        
        vm.modelloTipologiaPaccoLavoros = [];

        loadAll();

        function loadAll() {
            ModelloTipologiaPaccoLavoro.query(function(result) {
                vm.modelloTipologiaPaccoLavoros = result;
            });
        }
    }
})();
