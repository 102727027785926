(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('ProfiloDialogController', ProfiloDialogController);

    ProfiloDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Profilo', 'ProgettoAzienda', 'Ruolo', 'Progetto', 'User'];

    function ProfiloDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Profilo, ProgettoAzienda, Ruolo, Progetto, User) {
        var vm = this;

        vm.profilo = entity;
        vm.clear = clear;
        vm.save = save;
        vm.progettos = Progetto.query({size : 200});
        vm.showAziendaField = false;
        vm.setProjectManager = setProjectManager;
        vm.localSearch = localSearch;
        vm.progettoAutocomplete = progettoAutocomplete;

        
        Ruolo.query().$promise.then(function(ruoli) {
        	vm.ruolos = ruoli;
        	vm.showAziendaField =  isResponsabileAziendaSelected();
        	 $scope.$watch('vm.profilo.ruoloId', function (progettoId) {
             	vm.showAziendaField =  isResponsabileAziendaSelected();
             	if (!vm.showAziendaField) {
             		vm.profilo.progettoAziendaId = null;
             	}
             });
        });
        
        loadProgettoAziendas();
        
        function progettoAutocomplete (selected) {
        	if (selected && selected.originalObject) {
        		vm.profilo.progettoId = selected.originalObject.id;
        	} else {
        		vm.profilo.progettoId = null;
        	}
        }

		function localSearch(str, progetti) {
			var matches = [];
			progetti.forEach(function(progetto) {
				if ((progetto.nome.toLowerCase().indexOf(
						str.toString().toLowerCase()) >= 0)) {
					matches.push(progetto);
				}
			});
			return matches;
		}
		;
        
        $scope.$watch('vm.profilo.progettoId', function (progettoId) {
        	loadProgettoAziendas(progettoId);
        });
        
        function setProjectManager (pm) {
        	if (pm && pm.originalObject) {
        		vm.profilo.userId = pm.originalObject.id;
        	} else {
        		vm.profilo.userId = null;
        	}
        }
       
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function loadProgettoAziendas (progettoId) {
        	vm.progettoAziendas = [];
        	if (progettoId) {
        		ProgettoAzienda.query({progettoId : progettoId}).$promise.then( function(progettoAziendas) {
        			vm.progettoAziendas = progettoAziendas;
        		});
        	}
        }
        
        function isResponsabileAziendaSelected () {
        	var a = vm.ruolos.filter(function(e) {
        		return e.id == vm.profilo.ruoloId;
        	})
        	return (a && a.length && a[0].codice == 'RA');
        }
        
        function clear () {
        	$uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.profilo.id !== null) {
                Profilo.update(vm.profilo, onSaveSuccess, onSaveError);
            } else {
                Profilo.save(vm.profilo, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('rendicontaApp:profiloUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
