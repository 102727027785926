(function() {
    'use strict';

    angular
    	.module('rendicontaApp')
    	.config(['$translateProvider', function ($translateProvider) {
	       $translateProvider.useUrlLoader('api/help/inline');
	       $translateProvider.preferredLanguage('it');
    }]);
    
})();
