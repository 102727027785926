(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .factory('TermsAndConditions', TermsAndConditions);

    TermsAndConditions.$inject = ['$resource'];

    function TermsAndConditions($resource) {
        var service = $resource('api/account/terms_and_conditions', {}, {});

        return service;
    }
})();
