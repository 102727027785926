(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('ModelloTipologiaAllegatoController', ModelloTipologiaAllegatoController);

    ModelloTipologiaAllegatoController.$inject = ['$scope', '$state', 'ModelloTipologiaAllegato'];

    function ModelloTipologiaAllegatoController ($scope, $state, ModelloTipologiaAllegato) {
        var vm = this;
        
        vm.modelloTipologiaAllegatoes = [];

        loadAll();

        function loadAll() {
            ModelloTipologiaAllegato.query(function(result) {
                vm.modelloTipologiaAllegatoes = result;
            });
        }
    }
})();
