(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('DettaglioVCConsulenzaDetailController', DettaglioVCConsulenzaDetailController);

    DettaglioVCConsulenzaDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'DettaglioVCConsulenza', 'TipologiaConsulenza','LivelloTipologiaAllegato', 'Allegato', 'DettaglioVC', '$state'];

    function DettaglioVCConsulenzaDetailController($scope, $rootScope, $stateParams, previousState, entity, DettaglioVCConsulenza, TipologiaConsulenza,LivelloTipologiaAllegato, Allegato, DettaglioVC, $state) {
        var vm = this;

        vm.dettaglioVCConsulenza = entity;
        vm.previousState = previousState.name;
        
        vm.checklist = LivelloTipologiaAllegato.query({voceCostoParentId: $stateParams.voceCostoId});
        
        vm.valida = function(value) {
        	DettaglioVC.validate({id : vm.dettaglioVCConsulenza.id, value : value}, {}).$promise.then(
        		function() {
        			$state.reload();
        		}
        	);
        }
        
        Allegato.query({dettaglioVoceCostoId : entity.id}).$promise.then(function (allegati) {
        	vm.allegatos = allegati;
   		 	vm.tipologieCaricateIds = allegati.map(function(all) { return all.tipologiaId });
        });

        var unsubscribe = $rootScope.$on('rendicontaApp:dettaglioVCConsulenzaUpdate', function(event, result) {
            vm.dettaglioVCConsulenza = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
