(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('VoceCostoDetailController', VoceCostoDetailController);

    VoceCostoDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'VoceCosto', 'PaccoLavoro', 'TipologiaVoceCosto', 'DettaglioVC', 'Allegato', 'LivelloTipologiaAllegato', '$state'];

    function VoceCostoDetailController($scope, $rootScope, $stateParams, previousState, entity, VoceCosto, PaccoLavoro, TipologiaVoceCosto, DettaglioVC, Allegato, LivelloTipologiaAllegato, $state) {
        var vm = this;

        vm.voceCosto = entity;
        vm.previousState = previousState;

        vm.allegatos = Allegato.query({voceCostoId : entity.id}); 
        
        vm.dettagli = DettaglioVC.query({voceCostoId: entity.id});

        vm.checklist = LivelloTipologiaAllegato.query({voceCostoId: entity.id});
        
        vm.download = download;
        vm.downloadCostoOrario = downloadCostoOrario;
        
        vm.valida = function(value) {
        	VoceCosto.validate({id : vm.voceCosto.id, value : value}, {}).$promise.then(
        		function() {
        			$state.reload();
        		}
        	);
        }
        
        function download() {
        	var data = DettaglioVC.exportCSV({voceCostoId: vm.voceCosto.id}).$promise.then(
        		function (data) {
        			 var file = new Blob([data.data], { type: 'application/vnd.ms-excel' });
        	         saveAs(file, 'dettagli_'+vm.voceCosto.tipologiaNome.replace(/\s+/g, '-').toLowerCase() + '.xls');
        		}
        	);
        }
        
        function downloadCostoOrario() {
        	var data = VoceCosto.downloadCostoOrario({id: vm.voceCosto.id}).$promise.then(
        			function (data) {
        				var file = new Blob([data.data], { type: 'application/vnd.ms-excel' });
        				saveAs(file, 'costo_orario.xls');
        			}
        	);
        }
        
        Allegato.query({voceCostoId : entity.id}).$promise.then(function (allegati) {
        	vm.allegatos = allegati;
   		 	vm.tipologieCaricateIds = allegati.map(function(all) { return all.tipologiaId });
        });
        
        var unsubscribe = $rootScope.$on('rendicontaApp:voceCostoUpdate', function(event, result) {
            vm.voceCosto = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
