(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .directive('hasAnyState', hasAnyState);

    hasAnyState.$inject = ['Principal'];

    function hasAnyState(Principal) {
        var directive = {
            restrict: 'A',
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs) {
            var states = attrs.hasAnyState.replace(/\s+/g, '');
            var statoAllegati = attrs.stato.replace(/\s+/g, '');

        	var hasAnyState = function() {
        		if (!states || !statoAllegati) {
        			return false;
        		}
        		var statesArray = states.split(",");
        		return (statesArray.indexOf(statoAllegati) !== -1);
        	};

            var setVisible = function () {
                    element.removeClass('hidden-has');
                },
                setHidden = function () {
                    element.addClass('hidden-has');
                },
                defineVisibility = function (reset) {

                    if (reset) {
                        setVisible();
                    }

                    if (hasAnyState()) {
                    	setVisible();	
                    } else {
                        setHidden();
                    }
                };

            if (states.length > 0) {
                defineVisibility(true);
            }
        }
    }
})();
