(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('ProgettoDialogController', ProgettoDialogController);

    ProgettoDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Progetto', 'ProgettoAzienda', 'Modello', 'User', 'AlertService'];

    function ProgettoDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Progetto, ProgettoAzienda, Modello, User, AlertService) {
        var vm = this;

        vm.progetto = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.saveModello = saveModello;
        vm.setProjectManager = setProjectManager;
        
        if (!entity.id) {
        	vm.modellos = Modello.query();
        }
        
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function setProjectManager (pm) {
        	if (pm && pm.originalObject) {
        		vm.progetto.userId = pm.originalObject.id;
        	} else {
        		vm.progetto.userId = null;
        	}
        }

        function clear () {
        	$uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.progetto.id !== null) {
                Progetto.update(vm.progetto, onSaveSuccess, onSaveError);
            } else {
                Progetto.save(vm.progetto, onSaveSuccess, onSaveError);
            }
        }

        function saveModello () {
        	vm.isSaving = true;
        	if (vm.progetto.id !== null) {
                Modello.saveFromProject(vm.progetto, onSaveSuccess, onSaveError);
        	}
        }

        function onSaveSuccess (result) {
            $scope.$emit('rendicontaApp:progettoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.inizio = false;
        vm.datePickerOpenStatus.fine = false;
        vm.datePickerOpenStatus.disabilitazione = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
