/* globals $ */
(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .directive('oregg', oregg);

    function oregg () {
    	var regexp = /^(\d)?([.]?\d{0,1})?$/;
        return {
            restrict: "A",
            link: function(scope, elem, attrs) {
                var char;
                elem.on("keypress", function(event) {
                	var key = event.charCode || event.keyCode;
                    char = String.fromCharCode(event.which)
                	if (key == 37 || key == 39 || key == 8 || key == 9 || key == 46) {
                		//consider char as empty for special chars -> <- TAB BACKSPACE DEL
                		char = '';
                	}
                    var newVal = elem.val() + char;
                    if(!regexp.test(newVal)) {
                    	event.preventDefault();
                    }
                	var curVal = Number(newVal);
                	if (curVal > 24) {
                    	event.preventDefault();
                	}
                });
            }
        }
    }
})();
