(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('ModelloFaseDetailController', ModelloFaseDetailController);

    ModelloFaseDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ModelloFase', 'Modello'];

    function ModelloFaseDetailController($scope, $rootScope, $stateParams, previousState, entity, ModelloFase, Modello) {
        var vm = this;

        vm.modelloFase = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('rendicontaApp:modelloFaseUpdate', function(event, result) {
            vm.modelloFase = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
