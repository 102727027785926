/* globals $ */
(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .directive('anno', anno);

    function anno () {
        var directive = {
            restrict: 'A',
            scope:{ 
            	value: '=value',
            	name: '@name',
            	required: '@required',
            },
            controller: ['$scope', function MyTabsController($scope) {
            	 //anno calendar
            	var vm = this;
            	$scope.vm = vm; 
            	
                vm.openCalendar = openCalendar;
                vm.onChangeDate = onChangeDate;

            	
            	vm.dateOptions = {
                	datepickerMode : 'year',
                	formatYear: 'yyyy',
                	minMode: 'year',
                	'show-button-bar': false
                };
                
            	if ($scope.required) {
            		vm.required = "required";
            	}
                vm.valueAsDate = new Date(''+$scope.value) || new Date();
                vm.datePickerOpenStatus = false;

                function openCalendar () {
                    vm.datePickerOpenStatus = true;
                }
                
                
                function onChangeDate() {
                	$scope.value = vm.valueAsDate ? vm.valueAsDate.getFullYear() :  '';
                }
                
              }],            
            templateUrl: 'app/components/progetto/anno.directive.html'
        };
        return directive;
    }

})();
