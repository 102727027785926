(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('RuoloController', RuoloController);

    RuoloController.$inject = ['$scope', '$state', 'Ruolo'];

    function RuoloController ($scope, $state, Ruolo) {
        var vm = this;
        
        vm.ruolos = [];

        loadAll();

        function loadAll() {
            Ruolo.query(function(result) {
                vm.ruolos = result;
            });
        }
    }
})();
