(function() {
    'use strict';
    angular
        .module('rendicontaApp')
        .factory('StatoCalcolato', StatoCalcolato);

    StatoCalcolato.$inject = ['$resource'];

    function StatoCalcolato ($resource) {
        var resourceUrl =  'api/stato-calcolatoes/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'findOne': { 
            	 url: 'api/stato-calcolatoes/find',
            	 method: 'GET',
            	 transformResponse: function (data) {
                     if (data) {
                         data = angular.fromJson(data);
                     }
                     return data;
                 }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
