(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('TipologiaPaccoLavoroDetailController', TipologiaPaccoLavoroDetailController);

    TipologiaPaccoLavoroDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TipologiaPaccoLavoro'];

    function TipologiaPaccoLavoroDetailController($scope, $rootScope, $stateParams, previousState, entity, TipologiaPaccoLavoro) {
        var vm = this;

        vm.tipologiaPaccoLavoro = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('rendicontaApp:tipologiaPaccoLavoroUpdate', function(event, result) {
            vm.tipologiaPaccoLavoro = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
