(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('StatoCalcolatoDetailController', StatoCalcolatoDetailController);

    StatoCalcolatoDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'StatoCalcolato', 'Progetto', 'ProgettoAzienda', 'PaccoLavoro', 'VoceCosto'];

    function StatoCalcolatoDetailController($scope, $rootScope, $stateParams, previousState, entity, StatoCalcolato, Progetto, ProgettoAzienda, PaccoLavoro, VoceCosto) {
        var vm = this;

        vm.statoCalcolato = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('rendicontaApp:statoCalcolatoUpdate', function(event, result) {
            vm.statoCalcolato = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
