(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('PaccoLavoroDetailController', PaccoLavoroDetailController);

    PaccoLavoroDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PaccoLavoro', 'Azienda', 'Fase', 'VoceCosto', 'Allegato', 'LivelloTipologiaAllegato', '$state'];

    function PaccoLavoroDetailController($scope, $rootScope, $stateParams, previousState, entity, PaccoLavoro, Azienda, Fase, VoceCosto, Allegato, LivelloTipologiaAllegato, $state) {
        var vm = this;

        vm.paccoLavoro = entity;
        vm.previousState = previousState;

  		vm.voci = VoceCosto.query({paccoLavoroId : entity.id});

        vm.checklist = LivelloTipologiaAllegato.query({paccoLavoroId : entity.id});

        vm.valida = function(value) {
        	PaccoLavoro.validate({id : vm.paccoLavoro.id, value : value}, {}).$promise.then(
        		function() {
        			$state.reload();
        		}
        	);
        }

        vm.certifica = function(value) {
        	PaccoLavoro.certify({id : vm.paccoLavoro.id, value : value}, {}).$promise.then(
        			function() {
        				$state.reload();
        			}
        	);
        }
        
        Allegato.query({paccoLavoroId : entity.id}).$promise.then(function (allegati) {
        	vm.allegatos = allegati;
   		 	vm.tipologieCaricateIds = allegati.map(function(all) { return all.tipologiaId });
        });
        
        var unsubscribe = $rootScope.$on('rendicontaApp:paccoLavoroUpdate', function(event, result) {
            vm.paccoLavoro = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
