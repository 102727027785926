(function() {
    'use strict';
    angular
        .module('rendicontaApp')
        .factory('StatoCertificazione', StatoCertificazione);

    function StatoCertificazione() {
    	var certificato = false;
    	
    	function setCertificato(stato) {
    		certificato = stato;
    	}
    	
       	function isCertificato() {
    		return certificato;
    	}

    	return {
    		isCertificato : isCertificato,
    		setCertificato: setCertificato
        };
    }
})();
