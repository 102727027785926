/* globals $ */
(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .directive('semaforobool', semaforobool);

    function semaforobool () {
        var directive = {
            restrict: 'A',
            scope:{ 
            	value: '@value' 
            },
            template: '<span class="glyphicon checkvalidazione checkvalidazione-{{value}}"></span>'
        };
        return directive;
    }

})();
