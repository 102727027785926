(function() {
    'use strict';
    angular
        .module('rendicontaApp')
        .factory('RuoliInProgetto', RuoliInProgetto);

    function RuoliInProgetto() {
    	var roles = [];
    	var codesOfRoles = [];
    	
    	function hasAnyRole(roleCodesToCheck) {
    		var rolesArray = roleCodesToCheck.split(",");
    		 for (var i = 0; i < rolesArray.length; i++) {
                 if (codesOfRoles.indexOf(rolesArray[i]) !== -1) {
                     return true;
                 }
             }
             return false;
    	}

    	function setRoles(rolesInProject) {
    		if (!rolesInProject) {
    			rolesInProject = [];
    		}  
    		roles = rolesInProject;
    		codesOfRoles = roles.map(function (r) { return r.ruoloCodice })
    	}
    	
       	function getRoles() {
    		return roles;
    	}

    	return {
    		getRoles : getRoles,
        	hasAnyRole: hasAnyRole,
        	setRoles: setRoles
        };
    }
})();
