(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('ModelloTipologiaAllegatoDetailController', ModelloTipologiaAllegatoDetailController);

    ModelloTipologiaAllegatoDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ModelloTipologiaAllegato', 'Modello', 'TipologiaAllegato', 'TipologiaVoceCosto'];

    function ModelloTipologiaAllegatoDetailController($scope, $rootScope, $stateParams, previousState, entity, ModelloTipologiaAllegato, Modello, TipologiaAllegato, TipologiaVoceCosto) {
        var vm = this;

        vm.modelloTipologiaAllegato = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('rendicontaApp:modelloTipologiaAllegatoUpdate', function(event, result) {
            vm.modelloTipologiaAllegato = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
