(function() {
    'use strict';

    angular
    .module('rendicontaApp')
    .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('lastchanges', {
        	parent: 'entity',
        	url: '/lastchanges',
        	data: {
        		authorities: ['ROLE_USER'],
        		pageTitle: 'Ultime Modifiche'
        	},
        	views: {
        		'content@': {
        			templateUrl: 'app/entities/progetto/lastchanges.html',
        			controller: 'LastChangesController',
        			controllerAs: 'vm'
        		}
        	},
        	resolve: {
        	}, 
        	ncyBreadcrumb: {
        		label: 'Ultime Modifiche',
        		parent: 'progettosx'
        	}
        })        
        .state('creditoimposta', {
        	parent: 'progetto-detailx',
        	url: '/creditoimposta/{salId}',
        	data: {
        		authorities: ['ROLE_USER'],
        		pageTitle: 'Credito Imposta'
        	},
        	views: {
        		'content@': {
        			templateUrl: 'app/entities/progetto/creditoimposta.html',
        			controller: 'CreditoImpostaDetailController',
        			controllerAs: 'vm'
        		}
        	},
        	resolve: {
        		entity: ['$stateParams', 'Sal', function($stateParams, Sal) {
        			return Sal.get({id : $stateParams.salId}).$promise;
        		}],
        		progetto: ['$stateParams', 'Progetto', function($stateParams, Progetto) {
        			return Progetto.get({id : $stateParams.progettoId}).$promise;
        		}]
        	}, 
        	ncyBreadcrumb: {
        		label: 'Credito Imposta',
        		parent: 'progetto-detailx'
        	}
        })
        .state('progetto', {
            parent: 'entity',
            url: '/progetto?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Progetti'
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },        
            views: {
                'content@': {
                    templateUrl: 'app/entities/progetto/progettos.html',
                    controller: 'ProgettoController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            	 pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                     return {
                         page: PaginationUtil.parsePage($stateParams.page),
                         sort: $stateParams.sort,
                         predicate: PaginationUtil.parsePredicate($stateParams.sort),
                         ascending: PaginationUtil.parseAscending($stateParams.sort),
                         search: $stateParams.search
                     };
                 }],    
            }
        })
        .state('progetto-detail', {
            parent: 'entity',
            url: '/progetto/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Progetto'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/progetto/progetto-detail.html',
                    controller: 'ProgettoDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'Progetto', function($stateParams, Progetto) {
                    return Progetto.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'progetto',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('progettosx', {
        	parent: 'entity',
        	url: '/progettosx?page&sort&search',
        	data: {
        		authorities: ['ROLE_USER'],
        		pageTitle: 'Progetti'
        	},
        	  params: {
                  page: {
                      value: '1',
                      squash: true
                  },
                  sort: {
                      value: 'id,asc',
                      squash: true
                  },
                  search: null
              },        	
        	views: {
        		'content@': {
        			templateUrl: 'app/entities/progetto/progettosx.html',
        			controller: 'ProgettoController',
        			controllerAs: 'vm'
        		}
        	},
        	resolve: {
        		  pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                      return {
                          page: PaginationUtil.parsePage($stateParams.page),
                          sort: $stateParams.sort,
                          predicate: PaginationUtil.parsePredicate($stateParams.sort),
                          ascending: PaginationUtil.parseAscending($stateParams.sort),
                          search: $stateParams.search
                      };
                  }],        		
        	},
        	ncyBreadcrumb: {
        		label: 'Elenco progetti'
        	}
        })
        .state('progetto-detailx', {
        	parent: 'progettosx',
        	url: '/{progettoId}',
        	data: {
        		authorities: ['ROLE_USER'],
        		pageTitle: 'Progetto'
        	},
        	views: {
        		'content@': {
        			templateUrl: 'app/entities/progetto/progetto-detailx.html',
        			controller: 'ProgettoDetailController',
        			controllerAs: 'vm'
        		},
	            'info@': {
	            	controller: 'ProgettoInfoController',
	            	controllerAs: 'vm'
	            }
        	},
        	resolve: {
        		entity: ['$stateParams', 'Progetto', 'StatoCertificazione' , 'StatoProgetto', function($stateParams, Progetto, StatoCertificazione, StatoProgetto) {
        			var entity =  Progetto.get({id : $stateParams.progettoId}).$promise;
        			entity.then(function (entity) {
        				StatoCertificazione.setCertificato(entity.certificato)
        				StatoProgetto.process(entity);
        			});
        			return entity;

        		}],
        		ruoliUtente: ['$stateParams', 'Progetto', 'RuoliInProgetto', function($stateParams, Progetto, RuoliInProgetto) {
        			var ruoli = Progetto.getProfiliUtente({id : $stateParams.progettoId}).$promise;
        			ruoli.then(function (rolesInProject) {RuoliInProgetto.setRoles(rolesInProject)});
        			return ruoli;
        		}],
        		previousState: ["$state", function ($state) {
        			var currentStateData = {
        					name: $state.current.name || 'progetto',
        					params: $state.params,
        					url: $state.href($state.current.name, $state.params)
        			};
        			return currentStateData;
        		}]
        	}
        	,
        	ncyBreadcrumb: {
        		label: 'Progetto',
        		parent: 'progettosx'
        	}
        })
        .state('progetto-detailx.edit', {
            parent: 'progetto-detailx',
            url: '/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/progetto/progetto-dialog.html',
                    controller: 'ProgettoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Progetto', function(Progetto) {
                            return Progetto.get({id : $stateParams.progettoId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: 'progetto-detailx' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        
        .state('progetto.new', {
            parent: 'progetto',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/progetto/progetto-dialog.html',
                    controller: 'ProgettoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                nome: null,
                                inizio: null,
                                fine: null,
                                bando: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('progetto', null, { reload: 'progetto' });
                }, function() {
                    $state.go('progetto');
                });
            }]
        })
        .state('progetto.creaModello', {
        	parent: 'progetto',
        	url: '/{id}/modello',
        	data: {
        		authorities: ['ROLE_USER']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/progetto/progetto-modello.dialog.html',
        			controller: 'ProgettoDialogController',
        			controllerAs: 'vm',
        			backdrop: 'static',
        			size: 'lg',
        			resolve: {
        				  entity: ['Progetto', function(Progetto) {
                              return Progetto.get({id : $stateParams.id}).$promise;
                          }]
        			}
        		}).result.then(function() {
        			$state.go('modello', null, { reload: false });
        		}, function() {
        			$state.go('progetto');
        		});
        	}]
        })
        .state('progetto.edit', {
            parent: 'progetto',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/progetto/progetto-dialog.html',
                    controller: 'ProgettoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Progetto', function(Progetto) {
                            return Progetto.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('progetto', null, { reload: 'progetto' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('progetto-detailx.allegato-new', {
            parent: 'progetto-detailx',
            url: '/allegato/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/allegato/allegato-dialog.html',
                    controller: 'AllegatoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                nome: null,
                                descrizione: null,
                                bytes: null,
                                bytesContentType: null,
                                id: null,
                                progettoId: $stateParams.progettoId
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: 'progetto-detailx' });
                }, function() {
                    $state.go('^');
                });
            }]
        }) 
        .state('progetto-detailx.fase-new', {
        	parent: 'progetto-detailx',
        	url: '/fase/new',
        	data: {
        		authorities: ['ROLE_USER']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/fase/fase-dialog.html',
        			controller: 'FaseDialogController',
        			controllerAs: 'vm',
        			backdrop: 'static',
        			size: 'lg',
        			resolve: {
        				entity: function () {
        					return {
        						id: null,
        						progettoId: $stateParams.progettoId
        					};
        				}
        			}
        		}).result.then(function() {
        			$state.go('^', null, { reload: 'progetto-detailx' });
        		}, function() {
        			$state.go('^');
        		});
        	}]
        }) 
        .state('progetto-detailx.sal-new', {
        	parent: 'progetto-detailx',
        	url: '/sal/new',
        	data: {
        		authorities: ['ROLE_USER']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/sal/sal-dialog.html',
        			controller: 'SalDialogController',
        			controllerAs: 'vm',
        			backdrop: 'static',
        			size: 'lg',
        			resolve: {
        				entity: function () {
        					return {
        						id: null,
        						progettoId : $stateParams.progettoId
        					};
        				}
        			}
        		}).result.then(function() {
        			$state.go('^', null, { reload: 'progetto-detailx' });
        		}, function() {
        			$state.go('^');
        		});
        	}]
        }) 
        .state('progetto-detailx.pm-new', {
        	parent: 'progetto-detailx',
        	url: '/pm/new',
        	data: {
        		authorities: ['ROLE_USER']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/progetto/progetto-profilo-dialog.html',
        			controller: 'ProgettoProfiloDialogController',
        			controllerAs: 'vm',
        			backdrop: 'static',
        			size: 'lg',
        			resolve: {
        				entity: function () {
        					return {
        						id: null,
        						progettoId : $stateParams.progettoId
        					};
        				},
        				livello: function() { return 'PR'; }
        			}
        		}).result.then(function() {
        			$state.go('^', null, { reload: 'progetto-detailx' });
        		}, function() {
        			$state.go('^');
        		});
        	}]
        })
        .state('progetto-detailx.fase-edit', {
        	parent: 'progetto-detailx',
        	url: '/fase/{faseId}/edit',
        	data: {
        		authorities: ['ROLE_USER']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/fase/fase-dialog.html',
        			controller: 'FaseDialogController',
        			controllerAs: 'vm',
        			backdrop: 'static',
        			size: 'lg',
        			resolve: {
        				 entity: ['Fase', function(Fase) {
                             return Fase.get({id : $stateParams.faseId}).$promise;
                         }]
        			}
        		}).result.then(function() {
        			$state.go('^', null, { reload: 'progetto-detailx' });
        		}, function() {
        			$state.go('^');
        		});
        	}]
        }) 
        .state('progetto-detailx.sal-edit', {
        	parent: 'progetto-detailx',
        	url: '/sal/{salId}/edit',
        	data: {
        		authorities: ['ROLE_USER']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/sal/sal-dialog.html',
        			controller: 'SalDialogController',
        			controllerAs: 'vm',
        			backdrop: 'static',
        			size: 'lg',
        			resolve: {
        				entity: ['Sal', function(Sal) {
        					return Sal.get({id : $stateParams.salId}).$promise;
        				}],
        				progetto: null
        			}
        		}).result.then(function() {
        			$state.go('^', null, { reload: 'progetto-detailx' });
        		}, function() {
        			$state.go('^');
        		});
        	}]
        }) 
        .state('progetto-detailx.fase-delete', {
            parent: 'progetto-detailx',
            url: '/fase/{faseId}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/fase/fase-delete-dialog.html',
                    controller: 'FaseDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Fase', function(Fase) {
                            return Fase.get({id : $stateParams.faseId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: 'progetto-detailx' });
                }, function() {
                    $state.go('^');
                });
            }]
        })        
        .state('progetto-detailx.sal-delete', {
        	parent: 'progetto-detailx',
        	url: '/sal/{salId}/delete',
        	data: {
        		authorities: ['ROLE_USER']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/sal/sal-delete-dialog.html',
        			controller: 'SalDeleteController',
        			controllerAs: 'vm',
        			size: 'md',
        			resolve: {
        				entity: ['Sal', function(Sal) {
        					return Sal.get({id : $stateParams.salId}).$promise;
        				}]
        			}
        		}).result.then(function() {
        			$state.go('^', null, { reload: 'progetto-detailx' });
        		}, function() {
        			$state.go('^');
        		});
        	}]
        })        
        .state('progetto-detailx.progetto-azienda-new', {
        	parent: 'progetto-detailx',
        	url: '/progetto-azienda-new',
        	data: {
        		authorities: ['ROLE_USER']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/progetto-azienda/progetto-azienda-dialog.html',
        			controller: 'ProgettoAziendaDialogController',
        			controllerAs: 'vm',
        			backdrop: 'static',
        			size: 'lg',
        			resolve: {
        				entity: function () {
        					return {
        						id: null,
        						progettoId: $stateParams.progettoId
        					};
        				}
        			}
        		}).result.then(function() {
        			$state.go('^', null, { reload: 'progetto-detailx' });
        		}, function() {
        			$state.go('^');
        		});
        	}]
        }) 
         .state('progetto-detailx.progetto-azienda-delete', {
            parent: 'progetto-detailx',
            url: '/progetto-azienda/{progettoAziendaId}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/progetto-azienda/progetto-azienda-delete-dialog.html',
                    controller: 'ProgettoAziendaDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['ProgettoAzienda', function(ProgettoAzienda) {
                            return ProgettoAzienda.get({id : $stateParams.progettoAziendaId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: 'progetto-detailx' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('progetto-detailx.allegato-delete', {
        	parent: 'progetto-detailx',
        	url: '/allegato/{allegatoId}/delete',
        	data: {
        		authorities: ['ROLE_USER']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/allegato/allegato-delete-dialog.html',
        			controller: 'AllegatoDeleteController',
        			controllerAs: 'vm',
        			size: 'md',
        			resolve: {
        				entity: ['Allegato', function(Allegato) {
        					return Allegato.get({id : $stateParams.allegatoId}).$promise;
        				}]
        			}
        		}).result.then(function() {
        			$state.go('^', null, { reload: 'progetto-detailx' });
        		}, function() {
        			$state.go('^');
        		});
        	}]
        })
        .state('progetto-detailx.allegato-edit', {
        	parent: 'progetto-detailx',
        	url: '/allegato/{allegatoId}/edit',
        	data: {
        		authorities: ['ROLE_USER']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/allegato/allegato-dialog.html',
        			controller: 'AllegatoDialogController',
        			controllerAs: 'vm',
        			size: 'md',
        			resolve: {
        				entity: ['Allegato', function(Allegato) {
        					return Allegato.get({id : $stateParams.allegatoId}).$promise;
        				}]
        			}
        		}).result.then(function() {
        			$state.go('^', null, { reload: 'progetto-detailx' });
        		}, function() {
        			$state.go('^');
        		});
        	}]
        })
        .state('progetto.delete', {
            parent: 'progetto',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/progetto/progetto-delete-dialog.html',
                    controller: 'ProgettoDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Progetto', function(Progetto) {
                            return Progetto.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('progetto', null, { reload: 'progetto' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
