/* globals $ */
(function() {
	'use strict';

	angular.module('rendicontaApp').directive('buttonhelp',
			['$compile', '$translate', function($compile, $translate) {
				  return {
				    require: '',
				    terminal: true,
				      priority: 1000,				    
				    scope : {},
				    link: function(scope,element, attrs) {
				    	
				    	$translate(attrs.buttonhelp).then(function (translated) {
				    		  if (translated) {
				    			  // add only when there is a non empty translation
				    			  scope.help = translated;
				    			  element.attr('uib-popover', '{{help}}');
				    			  element.attr('popover-trigger', 'mouseenter');
				    			  element.attr('popover-popup-delay', '500');
				    			  element.attr('popover-popup-close-delay', '500');
				    		      element.removeAttr("buttonhelp"); //remove the attribute to avoid indefinite loop
				    		       element.removeAttr("data-buttonhelp"); //also remove the same attribute with data- prefix in case users specify data-common-things in the html
				    			  
				    			  $compile(element)(scope);
				    		  }
				    		});
				    }
				  };
				}]);

})();
