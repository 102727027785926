(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('TitoloStudioController', TitoloStudioController);

    TitoloStudioController.$inject = ['$scope', '$state', 'TitoloStudio'];

    function TitoloStudioController ($scope, $state, TitoloStudio) {
        var vm = this;
        
        vm.titoloStudios = [];
        
		vm.currentPage = 1;
		vm.pageSize = 10;
		vm.totalItems = 0;		
		vm.currentPageItems = [];
		
		vm.updateCurrentPageItems = function() {
			if (vm.titoloStudios) {
				vm.currentPageItems = vm.titoloStudios.slice(((vm.currentPage-1)*vm.pageSize), ((vm.currentPage)*vm.pageSize)); 
			} else {
				vm.currentPageItems = [];
			}
		}

        loadAll();

        function loadAll() {
            TitoloStudio.query(function(result) {
            	result = (result || []).sort(function(a, b) { return (a.nome || '').localeCompare(b.nome || '') });
            	
                vm.titoloStudios = result;
				vm.totalItems = result ? result.length : 0;
				vm.updateCurrentPageItems();                     
            });
        }
    }
})();
