(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('ProfiloDetailController', ProfiloDetailController);

    ProfiloDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Profilo', 'Azienda', 'Ruolo', 'Progetto'];

    function ProfiloDetailController($scope, $rootScope, $stateParams, previousState, entity, Profilo, Azienda, Ruolo, Progetto) {
        var vm = this;

        vm.profilo = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('rendicontaApp:profiloUpdate', function(event, result) {
            vm.profilo = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
