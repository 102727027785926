(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('terms-and-conditions', {
            parent: 'app',
            url: '/terms-and-conditions',
            data: {
                authorities: [ 'ROLE_USER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/terms_and_conditions/terms_and_conditions.html',
                    controller: 'TermsAndConditionsController',
                    controllerAs: 'vm'
                }
            },
        	ncyBreadcrumb: {
        		label: 'Home'
        	}
        });
    }
})();
