(function() {
    'use strict';
    angular
        .module('rendicontaApp')
        .factory('ProgettoAzienda', ProgettoAzienda);

    ProgettoAzienda.$inject = ['$resource'];

    function ProgettoAzienda ($resource) {
        var resourceUrl =  'api/progetto-aziendas/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'responsabili': {
            	url: 'api/progetto-aziendas/:id/responsabili',
            	method: 'GET', 
            	isArray: true
            },
            'vocicosto': {
            	url: 'api/progetto-aziendas/:id/vocicosto',
            	method: 'GET', 
            	isArray: true
            },
            'getScadenziario': {
            	url: 'api/progetto-aziendas/:id/scadenziario',
            	method: 'GET', 
            	isArray: true
            },   
            'exportScadenziario': {
            	method: 'GET',
            	url: 'api/progetto-aziendas/:id/scadenziario.xls',
            	responseType: 'arraybuffer',
            	transformResponse: function(data, headersGetter) { 
            		return { data : data }
            	}            
            },            
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { 
            	method:'PUT' 
            },
            'addra': {
            	url: 'api/progetto-aziendas/:id/addra',
            	method: 'POST'
            }
        });
    }
})();
