/* globals $ */
(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .directive('validazione', validazione);

    function validazione () {
        var directive = {
            restrict: 'A',
            scope:{ 
            	vm: '=vm',
            	entity: '=entity'
            },
            templateUrl: 'app/components/progetto/validazione.directive.html'
        };
        return directive;
    }

})();
