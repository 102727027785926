/* globals $ */
(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .directive('curUser', curUser);

    curUser.$inject = ['Principal'];

    function curUser (Principal) {
        var directive = {
            restrict: 'A',
            link: linkFunc
        };
        
        return directive;
        
        function linkFunc(scope, element, attrs) {
        	
        	 scope.$on('authenticationSuccess', function() {
                 setAccount();
             });

        	 scope.$on('authenticationReset', function() {
        		 setAccount();
        	 });
        	 
        	 var setAccount = function() {
				Principal.identity().then(function(identity) {
					if (identity) {
						$('.cur-user-ph', element).html(identity.login);
						setVisible();
					} else {
						$('.cur-user-ph', element).html('');
						setHidden();
					}
				});
        	 }, setVisible = function () {
                 element.removeClass('hidden-har');
             },
             setHidden = function () {
                 element.addClass('hidden-har');
             };

        	 setAccount();
        }
    }

})();
