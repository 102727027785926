/* globals $ */
(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .directive('semaforoallegati', semaforoallegati);

    function semaforoallegati () {
        var directive = {
            restrict: 'E',
            scope:{ 
            	stato: '=stato' 
            },
            template: '<span class="glyphicon checkallegati checkallegati-{{stato.statoCalcolato.statoAllegati | lowercase}}{{stato.statoAllegati | lowercase}}"></span>'
        };
        return directive;
    }

})();
