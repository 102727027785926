/* globals $ */
(function() {
    'use strict';

    angular
    .module('rendicontaApp')
    .directive('allegatoicon', allegatoicon);
    
    function allegatoicon () {
    	var directive = {
    			restrict: 'E',
    			scope:{ 
    				entry: '=entry' ,
    				tipologie: '=tipologie' ,
    			},
    			template: '<span class="glyphicon allegatoicon allegatoicon-{{tipologie.indexOf(entry.tipologiaAllegatoId) != -1}}{{entry.obbligatorio}}"></span>'
    	};
    	return directive;
    }
})();
