(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('TipologiaConsulenzaController', TipologiaConsulenzaController);

    TipologiaConsulenzaController.$inject = ['$scope', '$state', 'TipologiaConsulenza'];

    function TipologiaConsulenzaController ($scope, $state, TipologiaConsulenza) {
        var vm = this;
        
        vm.tipologiaConsulenzas = [];
        
		vm.currentPage = 1;
		vm.pageSize = 10;
		vm.totalItems = 0;		
		vm.currentPageItems = [];
		
		vm.updateCurrentPageItems = function() {
			if (vm.tipologiaConsulenzas) {
				vm.currentPageItems = vm.tipologiaConsulenzas.slice(((vm.currentPage-1)*vm.pageSize), ((vm.currentPage)*vm.pageSize)); 
			} else {
				vm.currentPageItems = [];
			}
		}		

        loadAll();

        function loadAll() {
            TipologiaConsulenza.query(function(result) {
            	result = (result || []).sort(function(a, b) { return (a.nome || '').localeCompare(b.nome || '') });

                vm.tipologiaConsulenzas = result;
				vm.totalItems = result ? result.length : 0;
				vm.updateCurrentPageItems();                 
            });
        }
    }
})();
