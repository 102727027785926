(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('TraduzioneDetailController', TraduzioneDetailController);

    TraduzioneDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Traduzione'];

    function TraduzioneDetailController($scope, $rootScope, $stateParams, previousState, entity, Traduzione) {
        var vm = this;

        vm.traduzione = entity;
        vm.previousState = previousState.name;

        var editor;
        
        $( document ).ready(function() {
            // create the editor
	          const container = document.getElementById("jsoneditor_traduzione")
	          const options = { mode: 'view' }
	          editor = new JSONEditor(container, options)
	          
	          try {
	              editor.setText(entity.contenuto || {});
	          } catch (e) {
	              console.error(e);
	          }
	      });
        
        var unsubscribe = $rootScope.$on('rendicontaApp:traduzioneUpdate', function(event, result) {
            vm.traduzione = result;
            try {
	              editor.setText(result.contenuto || {});
	          } catch (e) {
	              console.error(e);
	          }
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
