(function() {
    /*jshint bitwise: false*/
    'use strict';

    angular
        .module('rendicontaApp')
        .factory('TimesheetUtil', TimesheetUtil);

    function TimesheetUtil () {

        var service = {
        	easterDate : easterDate,
        	buildDate : buildDate,
        	isHoliday : isHoliday
        };
        
        function buildDate(y, m, d) {
 			var date = new Date(y, m-1, d);
 			
 			if (date.getFullYear() == y && date.getMonth() + 1 == m && date.getDate() == d) {
 				//valid
 				return date;
 			} else {
 				//NE date
 				return null;
 			}
         }
        
        function easterDate( y ) // Takes a given year (y) then returns Date object of Easter Sunday
        { 
        	/*
        		Easter Date Function for JavaScript implemented by Furgelnod ( http://furgelnod.com )
        		Using algorithm published at The Date of Easter (on aa.usno.navy.mil, Oct 2007) 
        		(https://web.archive.org/web/20071015045929/http://aa.usno.navy.mil/faq/docs/easter.php)
        		The algorithm is credited to J.-M. Oudin (1940) and is reprinted in the 
        		Explanatory Supplement to the Astronomical Almanac, ed. P. K. Seidelmann (1992). 
        		See Chapter 12, "Calendars", by L. E. Doggett.
        	*/
        	try {
        		y = Number( y );
        		if ( y != y ) { 
        			throw new TypeError( "Value must be a number." );
        		}
        		else if ( y > 275760 || y < -271820 ) {
        			throw new RangeError( "Value be between -271820 and 275760 due to technical limitations of Date constructor." );
        		}
        	}
        	catch ( e ) { return null; }

        	y = Math.floor( y );
        	var c = Math.floor( y / 100 );
        	var n = y - 19 * Math.floor( y / 19 );
        	var k = Math.floor( ( c - 17 ) / 25 );
        	var i = c - Math.floor( c / 4 ) - Math.floor( ( c - k ) / 3 ) + 19 * n + 15;
        	i = i - 30 * Math.floor( i / 30 );
        	i = i - Math.floor( i / 28 ) * ( 1 - Math.floor( i / 28 ) * Math.floor( 29 / ( i + 1 ) ) * Math.floor( ( 21 - n ) / 11 ) );
        	var j = y + Math.floor( y / 4 ) + i + 2 - c + Math.floor( c / 4 );
        	j = j - 7 * Math.floor( j / 7 );
        	var l = i - j;
        	var m = 3 + Math.floor( ( l + 40 ) / 44 );
        	var d = l + 28 - 31 * Math.floor( m / 4 );
        	var z = new Date();
        	z.setFullYear( y, m-1, d );
        	return z;
        } // -- easterDate 

        function isHoliday (date, easter) {
      	  if (easter) {
      		  if (easter.getMonth() == date.getMonth()) {
      			  //pasqua
      			  if (easter.getDate() == date.getDate()) {
      				  return true;
      			  }
      			  //pasquetta
      			  if ((easter.getDate() +1 )== date.getDate()) {
      				  return true;
      			  }
      		  }
      	  }
      	  //capodanno
  	      if (date.getDate() == 1 && (date.getMonth()+1) == 1) {
  	    	  return true;
  	      }
  	      //befana
  	      if (date.getDate() == 6 && (date.getMonth()+1) == 1) {
  	    	  return true;
  	      }
  	      //liberaz
  	      if (date.getDate() == 25 && (date.getMonth()+1) == 4) {
  	    	  return true;
  	      }
  	      //lavoro
  	      if (date.getDate() == 1 && (date.getMonth()+1) == 5) {
  	    	  return true;
  	      }
  	      //repu
  	      if (date.getDate() == 2 && (date.getMonth()+1) == 6) {
  	    	  return true;
  	      }
  	      //ferra
  	      if (date.getDate() == 15 && (date.getMonth()+1) == 8) {
  	    	  return true;
  	      }
  	      //primonov
  	      if (date.getDate() == 1 && (date.getMonth()+1) == 11) {
  	    	  return true;
  	      }
  	      //imma
  	      if (date.getDate() == 8 && (date.getMonth()+1) == 12) {
  	    	  return true;
  	      }
  	      //nata
  	      if (date.getDate() == 25 && (date.getMonth()+1) == 12) {
  	    	  return true;
  	      }
  	      //boxing
  	      if (date.getDate() == 26 && (date.getMonth()+1) == 12) {
  	    	  return true;
  	      }
  	      
  	      return false
  	  } 
        
        return service;
    }
})();
