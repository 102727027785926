(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('DettaglioVCPersonaleController', DettaglioVCPersonaleController);

    DettaglioVCPersonaleController.$inject = ['$scope', '$state', 'DettaglioVCPersonale'];

    function DettaglioVCPersonaleController ($scope, $state, DettaglioVCPersonale) {
        var vm = this;
        
        vm.dettaglioVCPersonales = [];

        loadAll();

        function loadAll() {
            DettaglioVCPersonale.query(function(result) {
                vm.dettaglioVCPersonales = result;
            });
        }
    }
})();
