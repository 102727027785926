(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('ModelloDetailController', ModelloDetailController);

    ModelloDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Modello'];

    function ModelloDetailController($scope, $rootScope, $stateParams, previousState, entity, Modello) {
        var vm = this;

        vm.modello = entity;
        vm.previousState = previousState.name;
        
//        vm.modelloTipologiaAllegatoes = Modello.getTipologieAllegato({id : vm.modello.id});
        vm.modelloSals  = Modello.getSals({id : vm.modello.id});
        vm.modelloTipologiaPaccoLavoros  = Modello.getTipologiePaccoLavoro({id : vm.modello.id});
        vm.modelloTipologiaVoceCostos  = Modello.getTipologieVoceCosto({id : vm.modello.id});
        vm.modelloFases  = Modello.getFases({id : vm.modello.id});

		vm.currentPageTipologieAllegato = 1;
		vm.pageSize = 10;
		vm.totalItemsTipologieAllegato = 0;		
		vm.currentPageItemsTipologieAllegato = [];
		
		vm.updateCurrentPageItemsTipologieAllegato = function() {
			if (vm.modelloTipologiaAllegatoes) {
				vm.currentPageItemsTipologieAllegato = vm.modelloTipologiaAllegatoes.slice(((vm.currentPageTipologieAllegato-1)*vm.pageSize), ((vm.currentPageTipologieAllegato)*vm.pageSize)); 
			} else {
				vm.currentPageItemsTipologieAllegato = [];
			}
		}			
        
        vm.livelloLabels = {PR : 'Progetto', PA : 'Azienda', PL : 'Attività', VC: 'Voce Costo', DVC : 'Dettaglio Voce Costo'};
        
        var livelloOrder = ['PR', 'PA', 'PL', 'VC', 'DVC'];
        
        vm.livelloComparator = function(v1, v2) {
        	return (livelloOrder.indexOf(v1.value) > livelloOrder.indexOf(v2.value)) ? 1 : -1;
        };;
        
        loadAll();

        function loadAll() {
        	Modello.getTipologieAllegato({id : vm.modello.id}, function(result) {
                vm.modelloTipologiaAllegatoes = result || [];
                vm.modelloTipologiaAllegatoes.sort(function(v1, v2) {
                	if (livelloOrder.indexOf(v1.livello) > livelloOrder.indexOf(v2.livello)) {
                		return 1;
                	} else if (livelloOrder.indexOf(v1.livello) < livelloOrder.indexOf(v2.livello)) {
                		return -1;
                	} else {
                		return (v1.descrizione || '').localeCompare(v2.descrizione || '');
                	}
                });
				vm.totalItemsTipologieAllegato = result ? result.length : 0;
				vm.updateCurrentPageItemsTipologieAllegato();                     
            });
        }
        
        var unsubscribe = $rootScope.$on('rendicontaApp:modelloUpdate', function(event, result) {
            vm.modello = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
