(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .directive('hasAnyRole', hasAnyRole);

    hasAnyRole.$inject = ['RuoliInProgetto', 'Principal', 'StatoProgetto'];

    function hasAnyRole(RuoliInProgetto, Principal, StatoProgetto) {
        var directive = {
            restrict: 'A',
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs) {
            var roles = attrs.hasAnyRole.replace(/\s+/g, '');

            var setVisible = function () {
                    element.removeClass('hidden-har');
                },
                setHidden = function () {
                    element.addClass('hidden-har');
                },
                defineVisibility = function (reset) {

                    if (reset) {
                        setVisible();
                    }

                    /* check admin */
                    Principal.hasAuthority('ROLE_ADMIN')
                    .then(function (result) {
                        if (result) {
                            setVisible();
                            return;
                        }
                    });
                    
                    /* check disabled project */
                    if (StatoProgetto.isDisabilitato()) {
                    	setHidden();
                    	return;
                    }
                    
                    if (RuoliInProgetto.hasAnyRole(roles)) {
                        setVisible();
                    } else {
                        setHidden();
                    }
                };

            if (roles.length > 0) {
                defineVisibility(true);
            }
        }
    }
})();
