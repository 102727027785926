(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('voce-costo', {
            parent: 'entity',
            url: '/voce-costo',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'VoceCostos'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/voce-costo/voce-costos.html',
                    controller: 'VoceCostoController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('voce-costo-detail', {
            parent: 'entity',
            url: '/voce-costo/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Voce Costo'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/voce-costo/voce-costo-detail.html',
                    controller: 'VoceCostoDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'VoceCosto', function($stateParams, VoceCosto) {
                    return VoceCosto.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'voce-costo',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('voce-costo-detailx', {
        	parent: 'pacco-lavoro-detailx',
        	url: '/voce-costo/{voceCostoId}',
        	data: {
        		authorities: ['ROLE_USER'],
        		pageTitle: 'Voce Costo'
        	},
        	views: {
        		'content@': {
        			templateUrl: 'app/entities/voce-costo/voce-costo-detailx.html',
        			controller: 'VoceCostoDetailController',
        			controllerAs: 'vm'
        		}
        	},
        	resolve: {
        		entity: ['$stateParams', 'VoceCosto', function($stateParams, VoceCosto) {
        			return VoceCosto.get({id : $stateParams.voceCostoId}).$promise;
        		}],
        		previousState: ["$state", function ($state) {
        			var currentStateData = {
        					name: $state.current.name || 'voce-costo',
        					params: $state.params,
        					url: $state.href($state.current.name, $state.params)
        			};
        			return currentStateData;
        		}]
        	}, 
        	ncyBreadcrumb: {
        		label: 'Voce Costo',
        		parent: 'pacco-lavoro-detailx'
        	}
        })
        .state('voce-costo-detail.edit', {
            parent: 'voce-costo-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/voce-costo/voce-costo-dialog.html',
                    controller: 'VoceCostoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['VoceCosto', function(VoceCosto) {
                            return VoceCosto.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('voce-costo-detailx.edit', {
        	parent: 'voce-costo-detailx',
        	url: '/detail/edit',
        	data: {
        		authorities: ['ROLE_USER']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/voce-costo/voce-costo-dialog.html',
        			controller: 'VoceCostoDialogController',
        			controllerAs: 'vm',
        			backdrop: 'static',
        			size: 'lg',
        			resolve: {
        				entity: ['VoceCosto', function(VoceCosto) {
        					return VoceCosto.get({id : $stateParams.voceCostoId}).$promise;
        				}]
        			}
        		}).result.then(function() {
        			$state.go('^', {}, { reload: 'voce-costo-detailx' });
        		}, function() {
        			$state.go('^');
        		});
        	}]
        })
        .state('voce-costo.new', {
            parent: 'voce-costo',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/voce-costo/voce-costo-dialog.html',
                    controller: 'VoceCostoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                stato: null,
                                preventivo: null,
                                contributoPercentuale: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('voce-costo', null, { reload: 'voce-costo' });
                }, function() {
                    $state.go('voce-costo');
                });
            }]
        })
        .state('voce-costo.edit', {
            parent: 'voce-costo',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/voce-costo/voce-costo-dialog.html',
                    controller: 'VoceCostoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['VoceCosto', function(VoceCosto) {
                            return VoceCosto.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('voce-costo', null, { reload: 'voce-costo' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('voce-costo.delete', {
            parent: 'voce-costo',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/voce-costo/voce-costo-delete-dialog.html',
                    controller: 'VoceCostoDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['VoceCosto', function(VoceCosto) {
                            return VoceCosto.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('voce-costo', null, { reload: 'voce-costo' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('voce-costo-detailx.allegato-delete', {
            parent: 'voce-costo-detailx',
            url: '/allegato/{allegatoId}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/allegato/allegato-delete-dialog.html',
                    controller: 'AllegatoDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Allegato', function(Allegato) {
                            return Allegato.get({id : $stateParams.allegatoId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: 'voce-costo-detailx' });
                }, function() {
                    $state.go('^');
                });
            }]
        })     
        .state('voce-costo-detailx.allegato-edit', {
        	parent: 'voce-costo-detailx',
        	url: '/allegato/{allegatoId}/edit',
        	data: {
        		authorities: ['ROLE_USER']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/allegato/allegato-dialog.html',
        			controller: 'AllegatoDialogController',
        			controllerAs: 'vm',
        			size: 'md',
        			resolve: {
        				entity: ['Allegato', function(Allegato) {
        					return Allegato.get({id : $stateParams.allegatoId}).$promise;
        				}]
        			}
        		}).result.then(function() {
        			$state.go('^', null, { reload: 'voce-costo-detailx' });
        		}, function() {
        			$state.go('^');
        		});
        	}]
        })        
         .state('voce-costo-detailx.allegato-new', {
            parent: 'voce-costo-detailx',
            url: '/allegato/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/allegato/allegato-dialog.html',
                    controller: 'AllegatoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                nome: null,
                                descrizione: null,
                                bytes: null,
                                bytesContentType: null,
                                id: null,
                                voceCostoId : $stateParams.voceCostoId
                            };
                        },
                        relatedEntity: ['VoceCosto', function(VoceCosto) {
                            return VoceCosto.get({id : $stateParams.voceCostoId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: 'voce-costo-detailx' });
                }, function() {
                    $state.go('^');
                });
            }]
        })

        
        ;
    }

})();
