(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('FoglioPresenzaDetailController', FoglioPresenzaDetailController);

    FoglioPresenzaDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'FoglioPresenza', 'Personale'];

    function FoglioPresenzaDetailController($scope, $rootScope, $stateParams, previousState, entity, FoglioPresenza, Personale) {
        var vm = this;

        vm.foglioPresenza = entity;
        vm.previousState = previousState.name;
        vm.download = download;
        
        creaMatrice();

        function download() {
        	var data = FoglioPresenza.exportCSV({id: vm.foglioPresenza.id}).$promise.then(
        		function (data) {
        			 var file = new Blob([data.data], { type: 'application/vnd.ms-excel' });
        	         saveAs(file, 'registro_presenze_'+vm.foglioPresenza.personaleCognome+'_'+vm.foglioPresenza.anno+'.xls');
        		}
        	);
        }
        
        function creaMatrice () {
    		
        	if (vm.foglioPresenza.id && vm.foglioPresenza.dettagli) {
        		//parse value
        		vm.matrice = JSON.parse(vm.foglioPresenza.dettagli);
        	}
        }
        
        var unsubscribe = $rootScope.$on('rendicontaApp:foglioPresenzaUpdate', function(event, result) {
            vm.foglioPresenza = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
