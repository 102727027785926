(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('DettaglioVCMaterialeController', DettaglioVCMaterialeController);

    DettaglioVCMaterialeController.$inject = ['$scope', '$state', 'DettaglioVCMateriale'];

    function DettaglioVCMaterialeController ($scope, $state, DettaglioVCMateriale) {
        var vm = this;
        
        vm.dettaglioVCMateriales = [];

        loadAll();

        function loadAll() {
            DettaglioVCMateriale.query(function(result) {
                vm.dettaglioVCMateriales = result;
            });
        }
    }
})();
