(function() {
    'use strict';
    angular
        .module('rendicontaApp')
        .factory('PaccoLavoro', PaccoLavoro);

    PaccoLavoro.$inject = ['$resource'];

    function PaccoLavoro ($resource) {
        var resourceUrl =  'api/pacco-lavoros/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'validate': { 
            	url: 'api/pacco-lavoros/:id/validate/:value',
            	method:'PUT' 
            },            
            'certify': { 
            	url: 'api/pacco-lavoros/:id/certify/:value',
            	method:'PUT' 
            }            
        });
    }
})();
