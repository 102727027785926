(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('ModelloTipologiaVoceCostoController', ModelloTipologiaVoceCostoController);

    ModelloTipologiaVoceCostoController.$inject = ['$scope', '$state', 'ModelloTipologiaVoceCosto'];

    function ModelloTipologiaVoceCostoController ($scope, $state, ModelloTipologiaVoceCosto) {
        var vm = this;
        
        vm.modelloTipologiaVoceCostos = [];

        loadAll();

        function loadAll() {
            ModelloTipologiaVoceCosto.query(function(result) {
                vm.modelloTipologiaVoceCostos = result;
            });
        }
    }
})();
