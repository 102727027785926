(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('DettaglioVCConsulenzaController', DettaglioVCConsulenzaController);

    DettaglioVCConsulenzaController.$inject = ['$scope', '$state', 'DettaglioVCConsulenza'];

    function DettaglioVCConsulenzaController ($scope, $state, DettaglioVCConsulenza) {
        var vm = this;
        
        vm.dettaglioVCConsulenzas = [];

        loadAll();

        function loadAll() {
            DettaglioVCConsulenza.query(function(result) {
                vm.dettaglioVCConsulenzas = result;
            });
        }
    }
})();
