/* globals $ */
(function() {
	'use strict';

	angular.module('rendicontaApp').directive('semaforoprogress',
			semaforoprogress);

	function semaforoprogress() {
		var directive = {
			restrict : 'E',
			scope : {
				stato : '=stato',
				width : '=width',
				roles: '@roles'
			},
			templateUrl : 'app/components/semafori/semaforoprogress.directive.html',
			controller : [
					'$scope',
					function TimesheetController($scope) {
						var vm = {};
						$scope.vm = vm;
						
			            vm.roles = $scope.roles || "PM";

						var init = function(stato) {
							try {

								var preventivo = stato.statoCalcolato.preventivo || 0;
								var consuntivo = stato.statoCalcolato.consuntivo || 0;
								if (preventivo == 0) {
									vm.progress = '';
									vm.cssClass = 'progress-nd';
								} else if (consuntivo >= preventivo) {
									vm.progress = 100;
									vm.cssClass = 'progress-full';
								} else {
									vm.progress = Math
											.round(consuntivo
													/ preventivo
													* 100);
									if (vm.progress >= 60) {
										vm.cssClass = 'progress-high';
									} else {
										vm.cssClass = 'progress-low';
									}
								}
							} catch (e) {
								console.error(e);
							}
						}
						
						vm.progress = '';

						vm.maxWidth = $scope.width || 200;

						init($scope.stato);

					} ]
		};
		return directive;
	}

})();
