(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('rendicontaApp')
        .constant('VERSION', "1.2.0-SNAPSHOT")
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('DATEFORMAT', "dd/MM/yyyy")
;
})();
