(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('FaseDetailController', FaseDetailController);

    FaseDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Fase', 'Progetto'];

    function FaseDetailController($scope, $rootScope, $stateParams, previousState, entity, Fase, Progetto) {
        var vm = this;

        vm.fase = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('rendicontaApp:faseUpdate', function(event, result) {
            vm.fase = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
