
(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('ProgettoDetailController', ProgettoDetailController);

    ProgettoDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Progetto', 'Fase', 'ProgettoAzienda', 'Allegato', 'DataUtils', 'Sal', 'LivelloTipologiaAllegato'];

    function ProgettoDetailController($scope, $rootScope, $stateParams, previousState, entity, Progetto, Fase, ProgettoAzienda, Allegato, DataUtils, Sal, LivelloTipologiaAllegato) {
        var vm = this;

        vm.progetto = entity;
        vm.previousState = previousState.name;
        
        vm.sals = Sal.query({progettoId : vm.progetto.id});
        vm.progettoAziendas = ProgettoAzienda.query({progettoId : vm.progetto.id});
        
        vm.checklist = LivelloTipologiaAllegato.query({progettoId : vm.progetto.id});

        vm.fases = Fase.query({progettoId : vm.progetto.id});
        
        Progetto.getProfili({id : entity.id}).$promise.then(function(profilis) {
        	vm.profilis = profilis.filter(function(r) {return r.ruoloCodice != 'RA'});
        });
        
        vm.downloadSalDVC = downloadSalDVC;
        vm.downloadSalTimesheet = downloadSalTimesheet;
        
        function downloadSalDVC(sal) {
        	var data = Sal.downloadDVC({id: sal.id}).$promise.then(
        			function (data) {
        				var file = new Blob([data.data], { type: 'application/vnd.ms-excel' });
        				saveAs(file, 'sal_'+sal.numero+'_dettagli.xls');
        			}
        	);
        }
        
        function downloadSalTimesheet(sal) {
        	var data = Sal.downloadTS({id: sal.id}).$promise.then(
        		function (data) {
        			 var file = new Blob([data.data], { type: 'application/zip' });
        	         saveAs(file, 'sal_'+sal.numero+'_timesheets.zip');
        		}
        	);
        }
        
        Allegato.query({progettoId : entity.id}).$promise.then(function (allegati) {
        	vm.allegatos = allegati;
   		 	vm.tipologieCaricateIds = allegati.map(function(all) { return all.tipologiaId });
        });
        
        var unsubscribe = $rootScope.$on('rendicontaApp:progettoUpdate', function(event, result) {
            vm.progetto = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
