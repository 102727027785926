(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('foglio-presenza-detail', {
            parent: 'personale-detail',
            url: '/foglio-presenza/{foglioPresenzaId}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'FoglioPresenza'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/foglio-presenza/foglio-presenza-detail.html',
                    controller: 'FoglioPresenzaDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'FoglioPresenza', function($stateParams, FoglioPresenza) {
                    return FoglioPresenza.get({id : $stateParams.foglioPresenzaId}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'azienda',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            },
        	ncyBreadcrumb: {
        		label: 'Registro Presenze'
        	}
        })
        .state('foglio-presenza-detail.edit', {
            parent: 'foglio-presenza-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/foglio-presenza/foglio-presenza-dialog.html',
                    controller: 'FoglioPresenzaDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['FoglioPresenza', function(FoglioPresenza) {
                            return FoglioPresenza.get({id : $stateParams.foglioPresenzaId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('personale-detail.foglio-presenza-new', {
            parent: 'personale-detail',
            url: '/fp/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/foglio-presenza/foglio-presenza-dialog.html',
                    controller: 'FoglioPresenzaDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                anno: null,
                                id: null,
                                personaleId: $stateParams.personaleId
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('personale-detail', null, { reload: 'personale-detail' });
                }, function() {
                    $state.go('personale-detail');
                });
            }]
        })
        .state('foglio-presenza-delete', {
            parent: 'personale-detail',
            url: '/foglio-presenza/{foglioPresenzaId}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/foglio-presenza/foglio-presenza-delete-dialog.html',
                    controller: 'FoglioPresenzaDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['FoglioPresenza', function(FoglioPresenza) {
                            return FoglioPresenza.get({id : $stateParams.foglioPresenzaId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('personale-detail', null, { reload: 'personale-detail' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
