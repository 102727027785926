(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('AllegatoDialogController', AllegatoDialogController);

    AllegatoDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'Allegato', 'Progetto', 'ProgettoAzienda', 'PaccoLavoro', 'VoceCosto', 'DettaglioVC', 'TipologiaAllegato'];

    function AllegatoDialogController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, Allegato, Progetto, ProgettoAzienda, PaccoLavoro, VoceCosto, DettaglioVC, TipologiaAllegato) {
        var vm = this;

        vm.allegato = entity;
        vm.clear = clear;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        
		vm.tipologiaallegatoes = TipologiaAllegato.query({
			progettoId : entity.progettoId,
			progettoAziendaId : entity.progettoAziendaId,
			paccoLavoroId : entity.paccoLavoroId,
			voceCostoId : entity.voceCostoId,
			voceCostoParentId : entity.voceCostoParentId,
			dettaglioVoceCostoId : entity.dettaglioVoceCostoId
		});
        	
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.allegato.id !== null) {
                Allegato.update(vm.allegato, onSaveSuccess, onSaveError);
            } else {
                Allegato.save(vm.allegato, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('rendicontaApp:allegatoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        vm.setBytes = function ($file, allegato) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        allegato.bytes = base64Data;
                        allegato.bytesContentType = $file.type;
                        allegato.size = $file.size;
                        allegato.fileName = $file.name;
                    });
                });
            }
        };

    }
})();
