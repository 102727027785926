(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('LastChangesController', LastChangesController);

    LastChangesController.$inject = ['$filter', '$scope', '$state', 'Progetto', 'LastChanges', 'AlertService'];

    function LastChangesController ($filter, $scope, $state, Progetto, LastChanges, AlertService) {
        var vm = this;

        //to enable/disable search button
        vm.isSearching = false;

        //true when at least one query is done
        vm.isSearchCompleted = false;
        
        //date field
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.fromDate = null;
        vm.toDate = null;
        vm.datePickerOpenStatus.fromDate = false;
        vm.datePickerOpenStatus.toDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        //progetto field
        vm.progettoId = null;
        vm.progettos = Progetto.query({size : 200});
        vm.progettoAutocomplete = progettoAutocomplete;
        vm.localSearch = localSearch;

        vm.search = search;

        //search result
        vm.allegatos = [];
        vm.dvcs = [];
        
        //go to detail
        vm.getChangeUrl = getChangeUrl;
        
        function progettoAutocomplete (selected) {
        	if (selected && selected.originalObject) {
        		vm.progettoId = selected.originalObject.id;
        	} else {
        		vm.progettoId = null;
        	}
        }

		function localSearch(str, progetti) {
			var matches = [];
			progetti.forEach(function(progetto) {
				if ((progetto.nome.toLowerCase().indexOf(
						str.toString().toLowerCase()) >= 0)) {
					matches.push(progetto);
				}
			});
			return matches;
		};      
        
		//goto selected change
		function getChangeUrl (change) {
			var url = '';
			if (change.dettaglioVoceCostoId) {
				var state = '';
				var typeToConsider = change.entityType == 'Allegato' ? change.parentType : change.entityType;
				//which state?
				if (typeToConsider == 'DettaglioVCPersonale') {
					state = 'dettaglio-vc-personalex-detail';
				} else if (typeToConsider == 'DettaglioVCConsulenza') {
					state = 'dettaglio-vc-consulenzax-detail';
				} else if (typeToConsider == 'DettaglioVCAttrezzatura') {
					state = 'dettaglio-vc-attrezzaturax-detail';
				} else if (typeToConsider == 'DettaglioVCMateriale') {
					state = 'dettaglio-vc-materialex-detail';
				} 
				
				url = $state.href(state, { 
					dettaglioId: change.dettaglioVoceCostoId,
					voceCostoId: change.voceCostoId,
					paccoLavoroId: change.paccoLavoroId,
					progettoAziendaId: change.progettoAziendaId,
					progettoId: change.progettoId,
				});
			} else if (change.voceCostoId) {
				url = $state.href('voce-costo-detailx', { 
					voceCostoId: change.voceCostoId,
					paccoLavoroId: change.paccoLavoroId,
					progettoAziendaId: change.progettoAziendaId,
					progettoId: change.progettoId,
				});
			} else if (change.paccoLavoroId) {
				url = $state.href('pacco-lavoro-detailx', { 
					paccoLavoroId: change.paccoLavoroId,
					progettoAziendaId: change.progettoAziendaId,
					progettoId: change.progettoId,
				});
			} else if (change.progettoAziendaId) {
				url = $state.href('progetto-azienda-detailx', { 
					progettoAziendaId: change.progettoAziendaId,
					progettoId: change.progettoId,
				});
			} else if (change.progettoId) {
				url = $state.href('progetto-detailx', { 
					progettoId: change.progettoId,
				});
			}
			return url;
		}
		
		//search changes
		function search () {
			var dateFormat = 'yyyy-MM-dd';
            var fromDate = $filter('date')(vm.fromDate, dateFormat);
            var toDate = $filter('date')(vm.toDate, dateFormat);
            
            vm.isSearchCompleted = false;
            vm.isSearching = true;
			
            vm.allegatos = [];
            vm.dvcs = [];
            
            LastChanges.query({
				fromDate: fromDate, 
				toDate: toDate,
				projectId: vm.progettoId
            }, onSuccess, onError);
            
            function onSuccess(data, headers) {
            	vm.isSearching = false;
                vm.isSearchCompleted = true;

            	vm.allegatos = data.filter(function(entity) { return entity.entityType == 'Allegato'} );
                vm.dvcs = data.filter(function(entity) { return entity.entityType != 'Allegato'} );
            }
            function onError(error) {
            	vm.isSearching = false;
                vm.isSearchCompleted = true;

                AlertService.error(error.data.message);
            }
        }
	}
		
})();
