/* globals $ */
(function() {
    'use strict';

    angular
    .module('rendicontaApp')
    .directive('scadenziarioicon', scadenziarioicon);
    
    function scadenziarioicon () {
    	var directive = {
    			restrict: 'E',
    			scope:{ 
    				stato: '@stato' ,
    				obbligatorio: '@obbligatorio' ,
    			},
    			template: '<span class="glyphicon scadicon scadicon-{{stato.toLowerCase()}}-{{obbligatorio}}"></span>'
    	};
    	return directive;
    }
})();
