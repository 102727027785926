(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('ModelloSalDetailController', ModelloSalDetailController);

    ModelloSalDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ModelloSal', 'Modello'];

    function ModelloSalDetailController($scope, $rootScope, $stateParams, previousState, entity, ModelloSal, Modello) {
        var vm = this;

        vm.modelloSal = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('rendicontaApp:modelloSalUpdate', function(event, result) {
            vm.modelloSal = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
