/* globals $ */
(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .directive('allegatitable', ['DataUtils', allegatiTable]);

    function allegatiTable (DataUtils) {
        var directive = {
            restrict: 'A',
            scope:{ 
            	allegatos: '=allegatos',
            	roles: '@roles',
            },
            link: function (scope, element, attrs) {
                scope.download = DataUtils.download
                if (!scope.roles) {
                	scope.roles = "PM,RA";
                };
            },
            templateUrl: 'app/components/progetto/allegati.directive.html'
        };
        return directive;
    }

})();
