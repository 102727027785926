(function() {
    'use strict';

    angular
        .module('rendicontaApp', [
            'ngStorage', 
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'infinite-scroll',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
            'angular-loading-bar',
            'ncy-angular-breadcrumb',
            'angular-cookie-law',
            'tableSort',
            'angucomplete-alt',
            'pascalprecht.translate'
        ])
        .run(run);

    run.$inject = ['stateHandler', '$rootScope', '$location'];

    function run(stateHandler, $rootScope, $location) {
        stateHandler.initialize();
        

        $rootScope.$on('$stateChangeSuccess', function (event) {
        	if (typeof(gtag) !== 'undefined') {
        		gtag('config', 'G-8NETFD81BQ', {'page_path': $location.path()});
        	}
        });
    }

})();
