(function() {
	'use strict';

	angular.module('rendicontaApp').controller(
			'FoglioPresenzaDialogController', FoglioPresenzaDialogController);

	FoglioPresenzaDialogController.$inject = [ '$timeout', '$scope',
			'$stateParams', '$uibModalInstance', 'entity', 'FoglioPresenza',
			'Personale', 'TimesheetUtil'];

	function FoglioPresenzaDialogController($timeout, $scope, $stateParams,
			$uibModalInstance, entity, FoglioPresenza, Personale, TimesheetUtil) {
		var vm = this;

		vm.foglioPresenza = entity;
		vm.clear = clear;
		vm.save = save;

		//generatore
		vm.proponiTimesheet = proponiTimesheet;
		vm.toggleGeneratore = toggleGeneratore;
        vm.showGeneratore = false;
        vm.generatoreValue = 8;
		
		vm.matrice = '';

		// somma ore
		vm.totaleOre = 0;

		creaMatrice();
		calcolaTotaleOre();
		$scope.$watch('vm.matrice', calcolaTotaleOre, true);

        function toggleGeneratore () {
        	vm.showGeneratore = !vm.showGeneratore;
        }
		
		 function roundToTwo(num) {    
	        return +(Math.round(num + "e+2")  + "e-2");
	     }
		
		  function cleanupFinalPoint() {
	        	try {
		        		var dettagli = vm.matrice || [];
		        		for (var j = 0; j < dettagli.length; j++) {
		        			var mese = dettagli[j];
		        			for (var k = 0; k < mese.length; k++) {
		        				if (mese[k] && typeof mese[k] === 'string') {
		        					if (mese[k].indexOf('.') == mese[k].length -1) {
		        						//remove '.'
		        						mese[k] = mese[k].substr(0, mese[k].length-1); 
		        					}
		        				};
		        			}
		        		}
	        	} catch(err) {
					if (typeof console !== "undefined") {
						console.error(err);
					}

				}
	        }
		 
		function calcolaTotaleOre() {
			
			var somma = 0;
			var dettagli = vm.matrice;
			try {
				for (var j = 0; j < dettagli.length; j++) {
					var mese = dettagli[j];
					if (mese) {
						for (var k = 0; k < mese.length; k++) {
							if (mese[k]) {
								somma += Number(mese[k]);
							}
							;
						}
					}
				}
			}
			catch(err) {
				if (typeof console !== "undefined") {
					console.error(err);
				}

			}
        	vm.totaleOre = roundToTwo(somma);
		}

		function creaMatrice() {

			if (vm.foglioPresenza.id && vm.foglioPresenza.dettagli) {
				// parse value
				vm.matrice = JSON.parse(vm.foglioPresenza.dettagli);
			}
		}

		$timeout(function() {
			angular.element('.form-group:eq(1)>input').focus();
		});

		function clear() {
			$uibModalInstance.dismiss('cancel');
		}

		function save() {
			vm.isSaving = true;
			
            cleanupFinalPoint();
			
			vm.foglioPresenza.dettagli = JSON.stringify(vm.matrice);
			if (vm.foglioPresenza.id !== null) {
				FoglioPresenza.update(vm.foglioPresenza, onSaveSuccess,
						onSaveError);
			} else {
				FoglioPresenza.save(vm.foglioPresenza, onSaveSuccess,
						onSaveError);
			}
		}

		function onSaveSuccess(result) {
			$scope.$emit('rendicontaApp:foglioPresenzaUpdate', result);
			$uibModalInstance.close(result);
			vm.isSaving = false;
		}

		function onSaveError() {
			vm.isSaving = false;
		}
		
		function proponiTimesheet() {
        	var valueToSet = vm.generatoreValue;
        	
        		var value;
        		var currentDate;
        		
               	var easter = TimesheetUtil.easterDate(vm.foglioPresenza.anno);
        		
        		for (var m = 1; m <= 12; m++) {
        			for (var d = 1; d <= 31; d++) {
        				
        				currentDate = TimesheetUtil.buildDate(vm.foglioPresenza.anno, m, d);
        				
        				if (currentDate) {
        					
        					if (currentDate.getDay() === 0 || currentDate.getDay() === 6) {
        						//weekend!
        						continue;
        					}
        					
        					if (TimesheetUtil.isHoliday(currentDate, easter)) {
        						//holiday!
        						continue;
        					}
        					
        					value = valueToSet;
        					
	        				vm.matrice[m][d] = !value ? "" : value;
        				} else {
        					vm.matrice[m][d] = "";
        				}
        			}
        		}
        }

	}
})();
