(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('StatoCalcolatoController', StatoCalcolatoController);

    StatoCalcolatoController.$inject = ['$scope', '$state', 'StatoCalcolato'];

    function StatoCalcolatoController ($scope, $state, StatoCalcolato) {
        var vm = this;
        
        vm.statoCalcolatoes = [];

        loadAll();

        function loadAll() {
            StatoCalcolato.query(function(result) {
                vm.statoCalcolatoes = result;
            });
        }
    }
})();
