/* globals $ */
(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .directive('semaforovalidazione', semaforovalidazione);

    function semaforovalidazione () {
        var directive = {
            restrict: 'E',
            scope:{ 
            	entity: '=entity' 
            },
            template: '<span class="glyphicon checkvalidazione checkvalidazione-{{entity.validato | lowercase}}"></span>'
        };
        return directive;
    }

})();
