/* globals $ */
(function() {
	'use strict';

	angular.module('rendicontaApp').directive('inlinehelp',
			['$compile', '$translate', function($compile, $translate) {
				  return {
				    require: '',
				    scope : {},
				    link: function(scope, el, attrs, ctrl) {
				    	
				    	$translate(attrs.inlinehelp).then(function (translated) {
			    		  if (translated) {
			    			  // add only when there is a non empty translation
			    			  scope.help = translated;
			    			  var template = $compile('<span class="glyphicon glyphicon-question-sign inline-help-icon" uib-popover="{{help}}"/>')(scope);
			    			  el.after(template);
			    		  }
			    		});
				    	
				    }
				  };
				}]);

})();
