(function() {
    'use strict';
    angular
        .module('rendicontaApp')
        .factory('DettaglioVC', DettaglioVC);

    DettaglioVC.$inject = ['$resource'];

    function DettaglioVC ($resource) {
        var resourceUrl =  'api/dettaglio-vcs/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'validate': { 
            	url: 'api/dettaglio-vcs/:id/validate/:value',
            	method:'PUT' 
            },
            'exportCSV': {
            	method: 'GET',
            	url: 'api/dettaglio-vcs.xls',
            	responseType: 'arraybuffer',
            	transformResponse: function(data, headersGetter) { 
            		return { data : data }
            	}            
            }
        });
    }
})();
