/* globals $ */
(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .directive('checklisttable', checklisttable);

    function checklisttable () {
        var directive = {
            restrict: 'A',
            scope:{ 
            	checklist: '=checklist',
            	tipologie: '=tipologie'
            },
            templateUrl: 'app/components/progetto/checklist.directive.html'
        };
        return directive;
    }

})();
