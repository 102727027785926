(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('SalController', SalController);

    SalController.$inject = ['$scope', '$state', 'Sal'];

    function SalController ($scope, $state, Sal) {
        var vm = this;
        
        vm.sals = [];

        loadAll();

        function loadAll() {
            Sal.query(function(result) {
                vm.sals = result;
            });
        }
    }
})();
