(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('ProgettoAziendaDetailController', ProgettoAziendaDetailController);

    ProgettoAziendaDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ProgettoAzienda', 'Progetto', 'Azienda', 'PaccoLavoro', 'Allegato','DataUtils', 'LivelloTipologiaAllegato', 'RuoliInProgetto'];

    function ProgettoAziendaDetailController($scope, $rootScope, $stateParams, previousState, entity, ProgettoAzienda, Progetto, Azienda, PaccoLavoro, Allegato,DataUtils, LivelloTipologiaAllegato, RuoliInProgetto) {
        var vm = this;

        vm.progettoAzienda = entity;
        vm.azienda = entity.azienda || {};
        vm.previousState = previousState;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        
        vm.voci = ProgettoAzienda.vocicosto({id : entity.id});
        
        vm.paccoLavoros = PaccoLavoro.query({progettoAziendaId : entity.id});

        vm.responsabiles = ProgettoAzienda.responsabili({id : entity.id});

        vm.checklist = LivelloTipologiaAllegato.query({progettoAziendaId : entity.id});
        
        Allegato.query({progettoAziendaId : entity.id}).$promise.then(function (allegati) {
        	vm.allegatos = allegati;
   		 	vm.tipologieCaricateIds = allegati.map(function(all) { return all.tipologiaId });
        });
        
        var unsubscribe = $rootScope.$on('rendicontaApp:progettoAziendaUpdate', function(event, result) {
            vm.progettoAzienda = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
