(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('AziendaDetailController', AziendaDetailController);

    AziendaDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'Azienda', 'Allegato'];

    function AziendaDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, Azienda, Allegato) {
        var vm = this;

        vm.azienda = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        Allegato.query({aziendaId : entity.id}).$promise.then(function (allegati) {
        	vm.allegatos = allegati;
        });
        
        var unsubscribe = $rootScope.$on('rendicontaApp:aziendaUpdate', function(event, result) {
            vm.azienda = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
