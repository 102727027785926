(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('DettaglioVCAttrezzaturaDialogController', DettaglioVCAttrezzaturaDialogController);

    DettaglioVCAttrezzaturaDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'DettaglioVCAttrezzatura', 'Sal'];

    function DettaglioVCAttrezzaturaDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, DettaglioVCAttrezzatura, Sal) {
        var vm = this;

        vm.dettaglioVCAttrezzatura = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        vm.sals = Sal.query({progettoId:$stateParams.progettoId, voceCostoId:$stateParams.voceCostoId});
        
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.dettaglioVCAttrezzatura.id !== null) {
                DettaglioVCAttrezzatura.update(vm.dettaglioVCAttrezzatura, onSaveSuccess, onSaveError);
            } else {
                DettaglioVCAttrezzatura.save(vm.dettaglioVCAttrezzatura, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('rendicontaApp:dettaglioVCAttrezzaturaUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dataAcquisto = false;
        vm.datePickerOpenStatus.dataFattura = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
