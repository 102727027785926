(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('TipologiaVoceCostoDetailController', TipologiaVoceCostoDetailController);

    TipologiaVoceCostoDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TipologiaVoceCosto'];

    function TipologiaVoceCostoDetailController($scope, $rootScope, $stateParams, previousState, entity, TipologiaVoceCosto) {
        var vm = this;

        vm.tipologiaVoceCosto = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('rendicontaApp:tipologiaVoceCostoUpdate', function(event, result) {
            vm.tipologiaVoceCosto = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
