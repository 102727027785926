(function() {
    'use strict';
    angular
        .module('rendicontaApp')
        .factory('FoglioPresenza', FoglioPresenza);

    FoglioPresenza.$inject = ['$resource'];

    function FoglioPresenza ($resource) {
        var resourceUrl =  'api/foglio-presenzas/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'exportCSV': {
            	method: 'GET',
            	url: 'api/foglio-presenzas/:id/export',
            	responseType: 'arraybuffer',
            	transformResponse: function(data, headersGetter) { 
            		return { data : data }
            	}            
            },
        });
    }
})();
