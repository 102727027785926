(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('DettaglioVCConsulenzaDialogController', DettaglioVCConsulenzaDialogController);

    DettaglioVCConsulenzaDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'DettaglioVCConsulenza', 'TipologiaConsulenza', 'Sal'];

    function DettaglioVCConsulenzaDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, DettaglioVCConsulenza, TipologiaConsulenza, Sal) {
        var vm = this;

        vm.dettaglioVCConsulenza = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.tipologiaconsulenzas = TipologiaConsulenza.query();

        vm.sals = Sal.query({progettoId:$stateParams.progettoId, voceCostoId:$stateParams.voceCostoId});
        
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.dettaglioVCConsulenza.id !== null) {
                DettaglioVCConsulenza.update(vm.dettaglioVCConsulenza, onSaveSuccess, onSaveError);
            } else {
                DettaglioVCConsulenza.save(vm.dettaglioVCConsulenza, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('rendicontaApp:dettaglioVCConsulenzaUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dataFattura = false;
        vm.datePickerOpenStatus.dataPagamento = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
