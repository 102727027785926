(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('ProgettoInfoController', ProgettoInfoController);

    ProgettoInfoController.$inject = ['$scope', '$state', 'RuoliInProgetto', 'StatoProgetto'];

    function ProgettoInfoController ($scope, $state, RuoliInProgetto, StatoProgetto) {
        var vm = this;
        
        vm.showInfo = true;
        vm.showBread = true;
        vm.currentRoles = RuoliInProgetto.getRoles().map(function (r) {
        	if (r.aziendaRagioneSociale) {
        		return r.ruoloNome + "@" + r.aziendaRagioneSociale;
        	} else {
        		return r.ruoloNome;
        	}
        }).join(", ");
        vm.showWarning = StatoProgetto.isDisabilitato();
    }
})();
