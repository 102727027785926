/* globals $ */
(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .directive('tickedbox', tickedbox);

    function tickedbox () {
        var directive = {
            restrict: 'E',
            scope:{ 
            	stato: '=stato' 
            },
            template: '<span class="glyphicon tickedbox tickedbox-{{stato}}"></span>'
        };
        return directive;
    }

})();
