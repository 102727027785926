(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('AziendaController', AziendaController);

    AziendaController.$inject = ['$scope', '$state', 'DataUtils', 'Azienda', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants','Principal'];

    function AziendaController ($scope, $state, DataUtils, Azienda, ParseLinks, AlertService, pagingParams, paginationConstants, Principal) {
        var vm = this;
        
        //paging
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        
        vm.aziendas = [];
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;

        //search
        vm.search = pagingParams.search;
        vm.clear = clear;

        //hack: prefix azienda. for USER
        vm.sortPrefix = 'azienda.';
        
        
        Principal.hasAuthority('ROLE_ADMIN')
	        .then(function (result) {
	            if (result) {
	            	//no prefix for admin
	            	vm.sortPrefix = '';
	            }
	            query();
        });

        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== vm.sortPrefix+'id') {
                result.push(vm.sortPrefix+'id');
            }
            return result;
        }   
        
        function query () {
            Azienda.query({
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                search: vm.search
            }, onSuccess, onError);
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.aziendas = data;
                vm.searched = vm.search;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
        	query();
        	
        	
//            $state.transitionTo($state.$current, {
//                page: vm.page,
//                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
//                search: vm.search
//            });
        }
        
	    function clear() {
	    	vm.search = null;
	    	vm.transition();
	    }
        
    }
})();
