(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('ModelloTipologiaAllegatoDialogController', ModelloTipologiaAllegatoDialogController);

    ModelloTipologiaAllegatoDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ModelloTipologiaAllegato', 'Modello', 'TipologiaAllegato', 'TipologiaVoceCosto', 'DateUtils'];

    function ModelloTipologiaAllegatoDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ModelloTipologiaAllegato, Modello, TipologiaAllegato, TipologiaVoceCosto, DateUtils) {
        var vm = this;

        vm.modelloTipologiaAllegato = entity;
        vm.clear = clear;
        vm.save = save;
        
        TipologiaAllegato.query({notExpiredOn : DateUtils.convertLocalDateToServer(new Date)}).$promise.then(function (tipologiaallegatoes) {
        	vm.tipologiaallegatoes = (tipologiaallegatoes || []).filter(function(el) {  return !!el })
        	.sort(function(e1, e2) {
        			var n1 = e1.nome || '';
        			var n2 = e2.nome || '';
	        		return n1.localeCompare(n2);
	        	}
        	);
        });
        vm.tipologiavocecostos = Modello.getTipologieVoceCosto({id : vm.modelloTipologiaAllegato.modelloId});
        vm.modelloFases = Modello.getFases({id : vm.modelloTipologiaAllegato.modelloId});

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.modelloTipologiaAllegato.id !== null) {
                ModelloTipologiaAllegato.update(vm.modelloTipologiaAllegato, onSaveSuccess, onSaveError);
            } else {
                ModelloTipologiaAllegato.save(vm.modelloTipologiaAllegato, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('rendicontaApp:modelloTipologiaAllegatoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
