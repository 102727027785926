/* globals $ */

/*
 *  add to inline.json link to the help page
 *  
 *  e.g.
	
		//pagina specifica
		"progetto" : "content/help/progetti.html",
		
		//anchor all'interno di una pagina
		"azienda" : "content/help/help.html#azienda",
 */
(function() {
	'use strict';

	angular.module('rendicontaApp').directive('linktohelp',
			['$compile', '$translate', function($compile, $translate) {
				  return {
				    require: '',
				    scope : {},
				    link: function(scope, el, attrs, ctrl) {
				    	
				    	$translate('help-page.' + attrs.linktohelp).then(function (translated) {
			    		  if (translated) {
			    			  // add only when there is a non empty translation
			    			  scope.link = translated;
			    			  var template = $compile('<a href="{{link}}" target="_blank"><span class="glyphicon glyphicon-info-sign linktohelp"/></a>')(scope);
			    			  el.append(template);
			    		  }
			    		});
				    }
				  };
				}]);

})();
