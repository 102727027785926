/* globals $ */
(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .directive('semaforocertificazione', semaforocertificazione);

    function semaforocertificazione () {
        var directive = {
            restrict: 'E',
            scope:{ 
            	entity: '=entity' 
            },
            template: '<span class="glyphicon checkvalidazione checkvalidazione-{{entity.certificato | lowercase}}"></span>'
        };
        return directive;
    }

})();
