(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('CreditoImpostaDetailController', CreditoImpostaDetailController);

    CreditoImpostaDetailController.$inject = ['$scope', '$state', 'previousState', 'entity', 'Sal', 'progetto'];

    function CreditoImpostaDetailController ($scope, $state, previousState, entity, Sal, progetto) {
        var vm = this;
        
        vm.previousState = previousState;
        vm.sal = entity;
        
        //CSV export
        vm.download = download;
        
        function download() {
        	var data = Sal.downloadCreditoImposta({id: vm.sal.id}).$promise.then(
        		function (data) {
        			 var file = new Blob([data.data], { type: 'application/vnd.ms-excel' });
        	         saveAs(file, 'progetto_'+progetto.nome + '_sal_'+vm.sal.nome+'_credito_imposta.xls');
        		}
        	);
        }

        loadAll();

        function loadAll() {
        	Sal.getCreditoImposta({id: vm.sal.id}, (function(result) {
                 vm.creditoImposta = result;
             }));
        }
    }
})();
