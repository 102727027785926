(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('ProgettoProfiloDialogController', ProgettoProfiloDialogController);

    ProgettoProfiloDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ProgettoAzienda', 'Ruolo', 'Progetto', 'User', 'livello', 'Principal'];

    function ProgettoProfiloDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ProgettoAzienda, Ruolo, Progetto, User, livello, Principal) {
        var vm = this;

        vm.profilo = entity;
        vm.clear = clear;
        vm.save = save;

        Ruolo.query().$promise.then(function(ruoli) {
        	if (livello == 'PR') {
        		 Principal.hasAuthority('ROLE_ADMIN')
                 .then(function (result) {
                     if (result) {
                         vm.ruolos = ruoli.filter(function(r) {return r.codice != 'RA'});
                     } else {
                         vm.ruolos = ruoli.filter(function(r) {return r.codice != 'RA' && r.codice != 'PM'});
                     }
                 });
        	} else if (livello == 'PA') {
        		vm.ruolos = ruoli.filter(function(r) {return r.codice == 'RA'});
        	}
        });
        
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
        	$uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.profilo.ruoloCodice == 'RA') {
            	ProgettoAzienda.addra({id : vm.profilo.progettoAziendaId}, vm.profilo, onSaveSuccess, onSaveError);
            } else {
            	Progetto.addpm({id : vm.profilo.progettoId}, vm.profilo, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('rendicontaApp:profiloUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
