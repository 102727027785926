(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .directive('jhSort', jhSort);

    function jhSort () {
        var directive = {
            restrict: 'A',
            scope: {
                predicate: '=jhSort',
                ascending: '=',
                callback: '&'
            },
            controller: SortController,
            controllerAs: 'vm',
            bindToController: true
        };

        return directive;
    }

    SortController.$inject = ['$scope', '$element'];

    function SortController ($scope, $element) {
        var vm = this;

        vm.applyClass = applyClass;
        vm.resetClasses = resetClasses;
        vm.sort = sort;
//        vm.newField = false;
        vm.triggerApply = triggerApply;

        $scope.$watchGroup(['vm.predicate', 'vm.ascending'], vm.triggerApply);
        vm.triggerApply();

        function applyClass (element) {
            var 
                sortAsc = ' tablesort-asc',
                sortDesc = 'tablesort-desc',
                add, remove;
            
            if (vm.newField) {
            	var sib = element.siblings();
            	sib.removeClass(sortAsc);
            	sib.removeClass(sortDesc);
            	vm.newField = false;
            }
            
            if (!vm.ascending) {
                remove = sortAsc;
                add = sortDesc;
            } else {
            	remove = sortDesc;
            	add = sortAsc;
            }
            element.removeClass(remove);
            element.addClass(add);
        }

        function resetClasses () {
//            var allThIcons = $element.find('span.glyphicon'),
//                sortIcon = 'glyphicon-sort',
//                sortAsc = 'glyphicon-sort-by-attributes',
//                sortDesc = 'glyphicon-sort-by-attributes-alt';
//            $element.removeClass('jh-sorted');
//            allThIcons.addClass(sortIcon);
        }

        function sort (field) {
            if (field !== vm.predicate) {
                vm.ascending = true;
                vm.newField = true;
            } else {
                vm.ascending = !vm.ascending;
            }
            vm.predicate = field;
            $scope.$apply();
            vm.callback();
        }

        function triggerApply (values)  {
//            vm.resetClasses();
            if (values && values[0] !== '_score') {
                vm.applyClass($element.find('th[jh-sort-by=\'' + values[0] + '\']'));
            }
        }
    }
})();
