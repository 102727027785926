(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('LivelloTipologiaAllegatoController', LivelloTipologiaAllegatoController);

    LivelloTipologiaAllegatoController.$inject = ['$scope', '$state', 'LivelloTipologiaAllegato'];

    function LivelloTipologiaAllegatoController ($scope, $state, LivelloTipologiaAllegato) {
        var vm = this;
        
        vm.livelloTipologiaAllegatoes = [];

        loadAll();

        function loadAll() {
            LivelloTipologiaAllegato.query(function(result) {
                vm.livelloTipologiaAllegatoes = result;
            });
        }
    }
})();
