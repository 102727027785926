(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('TermsAndConditionsController', TermsAndConditionsController);

    TermsAndConditionsController.$inject = ['$scope', 'Principal', 'LoginService', '$state', 'TermsAndConditions', 'Auth'];

    function TermsAndConditionsController ($scope, Principal, LoginService, $state, TermsAndConditions, Auth) {
        var vm = this;
        
        vm.termsAccepted = false;
        vm.save = saveTerms;
        vm.isSaving = false;
        
        vm.termsAcceptedAndSaved = false;
        vm.error = false;
        
        vm.hideBreadcrumb = true;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;

        Principal.hasAuthority('ROLE_TERMS')
        .then(function (result) {
            if (result) {
           	  $state.go('home', { reload: true });
            } else {
            	getAccount();
            }
        });
        
        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        function saveTerms () {
            vm.isSaving = true;
            vm.error = false;
            TermsAndConditions.save({}, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            vm.termsAcceptedAndSaved = true;
            Auth.logout();
        }

        function onSaveError (err) {
            vm.isSaving = false;
            vm.termsAcceptedAndSaved = false;
            vm.error = err.data.message;
        }

    }
})();
