(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('ProgettoController', ProgettoController);

    ProgettoController.$inject = ['$scope', '$state', 'Progetto', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'DateUtils'];

    function ProgettoController ($scope, $state, Progetto, ParseLinks, AlertService, pagingParams, paginationConstants, DateUtils) {
        var vm = this;
        
        //paging
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.isDisabled = isDisabled;

        //search
        vm.search = pagingParams.search;
        vm.clear = clear;
        
        vm.today = new Date();
		vm.today.setHours(0, 0, 0, 0);
        
        loadAll();
        
        function isDisabled (entity) {
        	if (!entity.disabilitazione) {
        		return false;
        	}
        	var dataDisabilitazione = DateUtils.convertLocalDateFromServer(entity.disabilitazione)
        	var result = dataDisabilitazione < vm.today;
			return result;
        }

        function loadAll () {
            Progetto.query({
            	 page: vm.page - 1,
                 size: vm.itemsPerPage,
                 sort: sort(),
                 search: vm.search
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.progettos = data;
                vm.searched = vm.search;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
        	loadAll();
        }
        
        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }
        
	    function clear() {
	    	vm.search = null;
	    	vm.transition();
	    }
    }
})();
