(function() {
    'use strict';
    angular
        .module('rendicontaApp')
        .factory('Modello', Modello);

    Modello.$inject = ['$resource', 'DateUtils'];

    function Modello ($resource, DateUtils) {
        var resourceUrl =  'api/modellos/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'getTipologieAllegato': { 
            	method: 'GET',
            	isArray: true,
            	url: 'api/modellos/:id/tipologie-allegato'
            },
            'getTipologiePaccoLavoro': { 
            	method: 'GET',
            	isArray: true,
            	url: 'api/modellos/:id/tipologie-pacco-lavoro'
            },
            'getTipologieVoceCosto': { 
            	method: 'GET',
            	isArray: true,
            	url: 'api/modellos/:id/tipologie-voce-costo'
            },
            'getFases': { 
            	method: 'GET',
            	isArray: true,
            	url: 'api/modellos/:id/fasi'
            },
            'getSals': { 
            	method: 'GET',
            	isArray: true,
            	url: 'api/modellos/:id/sals'
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dataFine = DateUtils.convertLocalDateFromServer(data.dataFine);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dataFine = DateUtils.convertLocalDateToServer(copy.dataFine);
                    return angular.toJson(copy);
                }
            },
            'save': {
            	method: 'POST',
            	transformRequest: function (data) {
            		var copy = angular.copy(data);
            		copy.dataFine = DateUtils.convertLocalDateToServer(copy.dataFine);
            		return angular.toJson(copy);
            	}
            },
            'saveFromProject': {
                method: 'POST',
                url: 'api/modellos/fromproject'
            },
            'clone': {
            	method: 'POST',
            	url: 'api/modellos/:id/clone'
            }
        });
    }
})();
