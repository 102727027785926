(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('ModelloTipologiaVoceCostoDetailController', ModelloTipologiaVoceCostoDetailController);

    ModelloTipologiaVoceCostoDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ModelloTipologiaVoceCosto', 'Modello', 'TipologiaVoceCosto'];

    function ModelloTipologiaVoceCostoDetailController($scope, $rootScope, $stateParams, previousState, entity, ModelloTipologiaVoceCosto, Modello, TipologiaVoceCosto) {
        var vm = this;

        vm.modelloTipologiaVoceCosto = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('rendicontaApp:modelloTipologiaVoceCostoUpdate', function(event, result) {
            vm.modelloTipologiaVoceCosto = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
