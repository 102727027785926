(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('DettaglioVCPersonaleDeleteController',DettaglioVCPersonaleDeleteController);

    DettaglioVCPersonaleDeleteController.$inject = ['$uibModalInstance', 'entity', 'DettaglioVCPersonale'];

    function DettaglioVCPersonaleDeleteController($uibModalInstance, entity, DettaglioVCPersonale) {
        var vm = this;

        vm.dettaglioVCPersonale = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            DettaglioVCPersonale['delete']({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
