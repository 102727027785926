(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('PersonaleCostoDetailController', PersonaleCostoDetailController);

    PersonaleCostoDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PersonaleCosto', 'Personale'];

    function PersonaleCostoDetailController($scope, $rootScope, $stateParams, previousState, entity, PersonaleCosto, Personale) {
        var vm = this;

        vm.personaleCosto = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('rendicontaApp:personaleCostoUpdate', function(event, result) {
            vm.personaleCosto = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
