(function() {
	'use strict';

	angular.module('rendicontaApp').controller('TipologiaVoceCostoController',
			TipologiaVoceCostoController);

	TipologiaVoceCostoController.$inject = [ '$scope', '$state',
			'TipologiaVoceCosto' ];

	function TipologiaVoceCostoController($scope, $state, TipologiaVoceCosto) {
		var vm = this;

		vm.tipologiaVoceCostos = [];

		vm.currentPage = 1;
		vm.pageSize = 10;
		vm.totalItems = 0;		
		vm.currentPageItems = [];
		
		vm.updateCurrentPageItems = function() {
			if (vm.tipologiaVoceCostos) {
				vm.currentPageItems = vm.tipologiaVoceCostos.slice(((vm.currentPage-1)*vm.pageSize), ((vm.currentPage)*vm.pageSize)); 
			} else {
				vm.currentPageItems = [];
			}
		}

		loadAll();

		function loadAll() {
			TipologiaVoceCosto.query(function(result) {
				result = (result || []).sort(function(a, b) { return (a.nome || '').localeCompare(b.nome || '') });

				vm.tipologiaVoceCostos = result;
				vm.totalItems = result ? result.length : 0;
				vm.updateCurrentPageItems();                
			});
		}
	}
})();
