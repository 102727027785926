(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('dettaglio-vc-materiale', {
            parent: 'entity',
            url: '/dettaglio-vc-materiale',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'DettaglioVCMateriales'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/dettaglio-vc-materiale/dettaglio-vc-materiales.html',
                    controller: 'DettaglioVCMaterialeController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('dettaglio-vc-materiale-detail', {
            parent: 'entity',
            url: '/dettaglio-vc-materiale/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'DettaglioVCMateriale'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/dettaglio-vc-materiale/dettaglio-vc-materiale-detail.html',
                    controller: 'DettaglioVCMaterialeDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'DettaglioVCMateriale', function($stateParams, DettaglioVCMateriale) {
                    return DettaglioVCMateriale.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'dettaglio-vc-materiale',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('dettaglio-vc-materiale-detail.edit', {
            parent: 'dettaglio-vc-materiale-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/dettaglio-vc-materiale/dettaglio-vc-materiale-dialog.html',
                    controller: 'DettaglioVCMaterialeDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['DettaglioVCMateriale', function(DettaglioVCMateriale) {
                            return DettaglioVCMateriale.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('dettaglio-vc-materiale.new', {
            parent: 'dettaglio-vc-materiale',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/dettaglio-vc-materiale/dettaglio-vc-materiale-dialog.html',
                    controller: 'DettaglioVCMaterialeDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                descrizione: null,
                                costoIniziale: null,
                                fornitore: null,
                                dataAcquisto: null,
                                numeroFattura: null,
                                dataFattura: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('dettaglio-vc-materiale', null, { reload: 'dettaglio-vc-materiale' });
                }, function() {
                    $state.go('dettaglio-vc-materiale');
                });
            }]
        })
        .state('dettaglio-vc-materialex-new', {
        	parent: 'voce-costo-detailx',
        	url: '/materiale/new',
        	data: {
        		authorities: ['ROLE_USER']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/dettaglio-vc-materiale/dettaglio-vc-materiale-dialog.html',
        			controller: 'DettaglioVCMaterialeDialogController',
        			controllerAs: 'vm',
        			backdrop: 'static',
        			size: 'lg',
        			resolve: {
        				entity: function () {
        					return {
        						descrizione: null,
        						costoIniziale: null,
        						fornitore: null,
        						dataAcquisto: null,
        						numeroFattura: null,
        						dataFattura: null,
        						id: null,
        						voceCostoId : $stateParams.voceCostoId
        					};
       				 }
        		}
        		}).result.then(function() {
        			$state.go('^', null, { reload: 'voce-costo-detailx' });
        		}, function() {
        			$state.go('^');
        		});
        	}]
        })
        .state('dettaglio-vc-materiale.edit', {
            parent: 'dettaglio-vc-materiale',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/dettaglio-vc-materiale/dettaglio-vc-materiale-dialog.html',
                    controller: 'DettaglioVCMaterialeDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['DettaglioVCMateriale', function(DettaglioVCMateriale) {
                            return DettaglioVCMateriale.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('dettaglio-vc-materiale', null, { reload: 'dettaglio-vc-materiale' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('dettaglio-vc-materialex-edit', {
        	parent: 'dettaglio-vc-materialex-detail',
        	url: '/edit',
        	data: {
        		authorities: ['ROLE_USER']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/dettaglio-vc-materiale/dettaglio-vc-materiale-dialog.html',
        			controller: 'DettaglioVCMaterialeDialogController',
        			controllerAs: 'vm',
        			backdrop: 'static',
        			size: 'lg',
        			resolve: {
        				entity: ['DettaglioVCMateriale', function(DettaglioVCMateriale) {
        					return DettaglioVCMateriale.get({id : $stateParams.dettaglioId}).$promise;
        				}]       			}
        		}).result.then(function() {
        			$state.go('^', null, { reload: 'dettaglio-vc-materialex-detail' });
        		}, function() {
        			$state.go('^');
        		});
        	}], 
        	ncyBreadcrumb: {
        		label: 'Edit'
        	}
        })
        .state('dettaglio-vc-materiale.delete', {
            parent: 'dettaglio-vc-materiale',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/dettaglio-vc-materiale/dettaglio-vc-materiale-delete-dialog.html',
                    controller: 'DettaglioVCMaterialeDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['DettaglioVCMateriale', function(DettaglioVCMateriale) {
                            return DettaglioVCMateriale.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('dettaglio-vc-materiale', null, { reload: 'dettaglio-vc-materiale' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('dettaglio-vc-materialex-delete', {
        	parent: 'voce-costo-detailx',
        	url: '/materiale/{dettaglioId}/delete',
        	data: {
        		authorities: ['ROLE_USER']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/dettaglio-vc-materiale/dettaglio-vc-materiale-delete-dialog.html',
        			controller: 'DettaglioVCMaterialeDeleteController',
        			controllerAs: 'vm',
        			size: 'md',
        			resolve: {
        				entity: ['DettaglioVCMateriale', function(DettaglioVCMateriale) {
        					return DettaglioVCMateriale.get({id : $stateParams.dettaglioId}).$promise;
        				}]
        			}
        		}).result.then(function() {
        			$state.go('^', null, { reload: 'voce-costo-detailx' });
        		}, function() {
        			$state.go('^');
        		});
        	}]
        })
        .state('dettaglio-vc-materialex-detail', {
        	parent: 'voce-costo-detailx',
        	url: '/materiale/{dettaglioId}/detail',
        	data: {
        		authorities: ['ROLE_USER'],
        		pageTitle: 'Dettaglio Voce Costo'
        	},
        	views: {
        		'content@': {
        			templateUrl: 'app/entities/dettaglio-vc-materiale/dettaglio-vc-materiale-detail.html',
        			controller: 'DettaglioVCMaterialeDetailController',
        			controllerAs: 'vm'
        		}
        	},
        	resolve: {
        		entity: ['$stateParams', 'DettaglioVCMateriale', function($stateParams, DettaglioVCMateriale) {
        			return DettaglioVCMateriale.get({id : $stateParams.dettaglioId}).$promise;
        		}],
        		previousState: ["$state", function ($state) {
        			var currentStateData = {
        					name: $state.current.name || 'dettaglio-vc-materialex-detail',
        					params: $state.params,
        					url: $state.href($state.current.name, $state.params)
        			};
        			return currentStateData;
        		}]
        	}, 
        	ncyBreadcrumb: {
        		label: 'Dettaglio Materiale',
        		parent: 'voce-costo-detailx'
        	}
        })
        .state('dettaglio-vc-materialex-detail.allegato-edit', {
        	parent: 'dettaglio-vc-materialex-detail',
        	url: '/allegato/{allegatoId}/edit',
        	data: {
        		authorities: ['ROLE_USER']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/allegato/allegato-dialog.html',
        			controller: 'AllegatoDialogController',
        			controllerAs: 'vm',
        			size: 'md',
        			resolve: {
        				entity: ['Allegato', function(Allegato) {
        					return Allegato.get({id : $stateParams.allegatoId}).$promise;
        				}]
        			}
        		}).result.then(function() {
        			$state.go('^', null, { reload: 'dettaglio-vc-materialex-detail' });
        		}, function() {
        			$state.go('^');
        		});
        	}]
        })         
        .state('dettaglio-vc-materialex-detail.allegato-delete', {
            parent: 'dettaglio-vc-materialex-detail',
            url: '/allegato/{allegatoId}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/allegato/allegato-delete-dialog.html',
                    controller: 'AllegatoDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Allegato', function(Allegato) {
                            return Allegato.get({id : $stateParams.allegatoId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: 'dettaglio-vc-materialex-detail' });
                }, function() {
                    $state.go('^');
                });
            }]
        })       
         .state('dettaglio-vc-materialex-detail.allegato-new', {
            parent: 'dettaglio-vc-materialex-detail',
            url: '/allegato/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/allegato/allegato-dialog.html',
                    controller: 'AllegatoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                nome: null,
                                descrizione: null,
                                bytes: null,
                                bytesContentType: null,
                                id: null,
                                voceCostoParentId : $stateParams.voceCostoId,
                                dettaglioVoceCostoId : $stateParams.dettaglioId
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: 'dettaglio-vc-materialex-detail' });
                }, function() {
                    $state.go('^');
                });
            }]
        })         
        ;
    }

})();
