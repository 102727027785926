/* globals $ */
(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .directive('gaclick', gaclick);

    function gaclick () {
        var directive = {
            restrict: 'A',
            priority: 10,
            link: function (scope, element, attrs) {
            	element.bind('click', function (event) {	
            		var descr = '';
            		try {
            			descr = attrs.gaclick || element.text().substring(0,20);;
            		} catch (e) {
            			console.error(e);
            		}
            		if (typeof(gtag) !== 'undefined') {
            			gtag('event', descr);
            		}
            	});
            },
        };
        return directive;
    }

})();
