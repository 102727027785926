(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .factory('LastChanges', LastChanges);

    LastChanges.$inject = ['$resource'];

    function LastChanges ($resource) {
        var service = $resource('api/changes', {}, {
            'query': {
                method: 'GET',
                isArray: true,
                params: {fromDate: null, toDate: null, projectId: null}
            }
        });

        return service;
    }
})();
