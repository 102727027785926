(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('FileDialogController', FileDialogController);

    FileDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'DataUtils', 'entity', 'File', 'Allegato'];

    function FileDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, DataUtils, entity, File, Allegato) {
        var vm = this;

        vm.file = entity;
        vm.clear = clear;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.allegatoes = Allegato.query({filter: 'file-is-null'});
        $q.all([vm.file.$promise, vm.allegatoes.$promise]).then(function() {
            if (!vm.file.allegato || !vm.file.allegato.id) {
                return $q.reject();
            }
            return Allegato.get({id : vm.file.allegato.id}).$promise;
        }).then(function(allegato) {
            vm.allegatoes.push(allegato);
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.file.id !== null) {
                File.update(vm.file, onSaveSuccess, onSaveError);
            } else {
                File.save(vm.file, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('rendicontaApp:fileUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        vm.setBytes = function ($file, file) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        file.bytes = base64Data;
                        file.bytesContentType = $file.type;
                    });
                });
            }
        };

    }
})();
