(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('AllegatoDetailController', AllegatoDetailController);

    AllegatoDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'Allegato', 'Progetto', 'ProgettoAzienda', 'PaccoLavoro', 'VoceCosto', 'TipologiaAllegato'];

    function AllegatoDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, Allegato, Progetto, ProgettoAzienda, PaccoLavoro, VoceCosto, TipologiaAllegato) {
        var vm = this;

        vm.allegato = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('rendicontaApp:allegatoUpdate', function(event, result) {
            vm.allegato = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
