(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('AziendaInfoController', AziendaInfoController);

    AziendaInfoController.$inject = ['$scope', '$state'];

    function AziendaInfoController ($scope, $state) {
        var vm = this;
        vm.showInfo = false;
        vm.showWarning = false;
        vm.showBread = true;
    }
})();
