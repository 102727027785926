(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('FoglioPresenzaController', FoglioPresenzaController);

    FoglioPresenzaController.$inject = ['$scope', '$state', 'FoglioPresenza'];

    function FoglioPresenzaController ($scope, $state, FoglioPresenza) {
        var vm = this;
        
        vm.foglioPresenzas = [];

        loadAll();

        function loadAll() {
            FoglioPresenza.query(function(result) {
                vm.foglioPresenzas = result;
            });
        }
    }
})();
