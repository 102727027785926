(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .directive('notCertificato', notCertificato);

    notCertificato.$inject = ['StatoCertificazione', 'Principal'];

    function notCertificato(StatoCertificazione, Principal) {
        var directive = {
            restrict: 'A',
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs) {
            var setVisible = function () {
                    element.removeClass('hidden-nc');
                },
                setHidden = function () {
                    element.addClass('hidden-nc');
                },
                defineVisibility = function (reset) {

                    if (reset) {
                        setVisible();
                    }

                    /* check admin */
                    Principal.hasAuthority('ROLE_ADMIN')
                    .then(function (result) {
                        if (result) {
                            setVisible();
                            return;
                        }
                    });
                    
                    if (!StatoCertificazione.isCertificato()) {
                        setVisible();
                    } else {
                        setHidden();
                    }
                };

           defineVisibility(true);
        }
    }
})();
