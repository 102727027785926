(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('TraduzioneDialogController', TraduzioneDialogController);

    TraduzioneDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Traduzione', '$rootScope'];

    function TraduzioneDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Traduzione, $rootScope) {
        var vm = this;

        vm.traduzione = entity;
        vm.clear = clear;
        vm.save = save;
        var editor;
       
        $( document ).ready(function() {
            // create the editor
	          const container = document.getElementById("jsoneditor_traduzione")
	          const options = { modes :  ['code','form'], mode: 'form'}
	          editor = new JSONEditor(container, options)
	
	          try {
	              editor.setText(entity.contenuto || {});
	          } catch (e) {
	              console.error(e);
	          }
	      });
	     

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            vm.traduzione.contenuto = editor.getText();	
            
            if (vm.traduzione.id !== null) {
                Traduzione.update(vm.traduzione, onSaveSuccess, onSaveError);
            } else {
                Traduzione.save(vm.traduzione, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('rendicontaApp:traduzioneUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
