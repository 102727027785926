(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('modello', {
            parent: 'entity',
            url: '/modello',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'Modellos'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/modello/modellos.html',
                    controller: 'ModelloController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('modello-detail', {
            parent: 'entity',
            url: '/modello/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'Modello'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/modello/modello-detail.html',
                    controller: 'ModelloDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'Modello', function($stateParams, Modello) {
                    return Modello.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'modello',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
         .state('modello.clone', {
        	parent: 'modello',
        	url: '/{id}/clone',
        	data: {
        		authorities: ['ROLE_USER']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/modello/modello-clone-dialog.html',
        			controller: 'ModelloCloneController',
        			controllerAs: 'vm',
        			backdrop: 'static',
        			size: 'lg',
        			resolve: {
        				  entity: ['Modello', function(Modello) {
                              return Modello.get({id : $stateParams.id}).$promise;
                          }]
        			}
        		}).result.then(function() {
        			$state.go('modello', null, { reload: true });
        		}, function() {
        			$state.go('modello');
        		});
        	}]
        })
        .state('modello-detail.tipologia-allegato-new', {
        	parent: 'modello-detail',
        	url: '/tipologia-allegato/new',
        	data: {
        		authorities: ['ROLE_ADMIN']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/modello-tipologia-allegato/modello-tipologia-allegato-dialog.html',
        			controller: 'ModelloTipologiaAllegatoDialogController',
        			controllerAs: 'vm',
        			backdrop: 'static',
        			size: 'lg',
        			resolve: {
        				 entity: function () {
                             return {
                                 id: null,
                                 modelloId : $stateParams.id
                             };
                         }
        			}
        		}).result.then(function() {
        			$state.go('modello-detail', null, { reload: 'modello-detail' });
        		}, function() {
        			$state.go('modello-detail');
        		});
        	}]
        })         
        .state('modello-detail.tipologia-allegato-edit', {
        	parent: 'modello-detail',
        	url: '/tipologia-allegato/{tipologiaAllegatoId}/edit',
        	data: {
        		authorities: ['ROLE_ADMIN']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/modello-tipologia-allegato/modello-tipologia-allegato-dialog.html',
        			controller: 'ModelloTipologiaAllegatoDialogController',
        			controllerAs: 'vm',
        			backdrop: 'static',
        			size: 'lg',
        			resolve: {
        				entity: ['ModelloTipologiaAllegato', function(ModelloTipologiaAllegato) {
        					return ModelloTipologiaAllegato.get({id : $stateParams.tipologiaAllegatoId}).$promise;
        				}],
        			}
        		}).result.then(function() {
        			$state.go('modello-detail', null, { reload: 'modello-detail' });
        		}, function() {
        			$state.go('modello-detail');
        		});
        	}]
        })         
        .state('modello-detail.tipologia-allegato-delete', {
        	parent: 'modello-detail',
        	url: '/tipologia-allegato/{tipologiaAllegatoId}/delete',
        	data: {
        		authorities: ['ROLE_ADMIN']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/modello-tipologia-allegato/modello-tipologia-allegato-delete-dialog.html',
        			controller: 'ModelloTipologiaAllegatoDeleteController',
        			controllerAs: 'vm',
        			backdrop: 'static',
        			size: 'md',
        			resolve: {
        				entity: ['ModelloTipologiaAllegato', function(ModelloTipologiaAllegato) {
        					return ModelloTipologiaAllegato.get({id : $stateParams.tipologiaAllegatoId}).$promise;
        				}],
        			}
        		}).result.then(function() {
        			$state.go('^', null, { reload: 'modello-detail' });
        		}, function() {
        			$state.go('^');
        		});
        	}]
        })         
        .state('modello-detail.sal-new', {
        	parent: 'modello-detail',
        	url: '/sal/new',
        	data: {
        		authorities: ['ROLE_ADMIN']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/modello-sal/modello-sal-dialog.html',
        			controller: 'ModelloSalDialogController',
        			controllerAs: 'vm',
        			backdrop: 'static',
        			size: 'lg',
        			resolve: {
        				entity: function () {
        					return {
        						id: null,
        						modelloId : $stateParams.id
        					};
        				}
        			}
        		}).result.then(function() {
        			$state.go('modello-detail', null, { reload: 'modello-detail' });
        		}, function() {
        			$state.go('modello-detail');
        		});
        	}]
        })         
        .state('modello-detail.sal-edit', {
        	parent: 'modello-detail',
        	url: '/sal/{salId}/edit',
        	data: {
        		authorities: ['ROLE_ADMIN']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/modello-sal/modello-sal-dialog.html',
        			controller: 'ModelloSalDialogController',
        			controllerAs: 'vm',
        			backdrop: 'static',
        			size: 'lg',
        			resolve: {
        				entity: ['ModelloSal', function(ModelloSal) {
        					return ModelloSal.get({id : $stateParams.salId}).$promise;
        				}],
        			}
        		}).result.then(function() {
        			$state.go('modello-detail', null, { reload: 'modello-detail' });
        		}, function() {
        			$state.go('modello-detail');
        		});
        	}]
        })         
        .state('modello-detail.sal-delete', {
        	parent: 'modello-detail',
        	url: '/sal/{salId}/delete',
        	data: {
        		authorities: ['ROLE_ADMIN']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/modello-sal/modello-sal-delete-dialog.html',
        			controller: 'ModelloSalDeleteController',
        			controllerAs: 'vm',
        			backdrop: 'static',
        			size: 'md',
        			resolve: {
        				entity: ['ModelloSal', function(ModelloSal) {
        					return ModelloSal.get({id : $stateParams.salId}).$promise;
        				}],
        			}
        		}).result.then(function() {
        			$state.go('^', null, { reload: 'modello-detail' });
        		}, function() {
        			$state.go('^');
        		});
        	}]
        })         
        .state('modello-detail.fase-new', {
        	parent: 'modello-detail',
        	url: '/fase/new',
        	data: {
        		authorities: ['ROLE_ADMIN']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/modello-fase/modello-fase-dialog.html',
        			controller: 'ModelloFaseDialogController',
        			controllerAs: 'vm',
        			backdrop: 'static',
        			size: 'lg',
        			resolve: {
        				entity: function () {
        					return {
        						id: null,
        						modelloId : $stateParams.id
        					};
        				}
        			}
        		}).result.then(function() {
        			$state.go('modello-detail', null, { reload: 'modello-detail' });
        		}, function() {
        			$state.go('modello-detail');
        		});
        	}]
        })         
        .state('modello-detail.fase-edit', {
        	parent: 'modello-detail',
        	url: '/fase/{faseId}/edit',
        	data: {
        		authorities: ['ROLE_ADMIN']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/modello-fase/modello-fase-dialog.html',
        			controller: 'ModelloFaseDialogController',
        			controllerAs: 'vm',
        			backdrop: 'static',
        			size: 'lg',
        			resolve: {
        				entity: ['ModelloFase', function(ModelloFase) {
        					return ModelloFase.get({id : $stateParams.faseId}).$promise;
        				}],
        			}
        		}).result.then(function() {
        			$state.go('modello-detail', null, { reload: 'modello-detail' });
        		}, function() {
        			$state.go('modello-detail');
        		});
        	}]
        })         
        .state('modello-detail.fase-delete', {
        	parent: 'modello-detail',
        	url: '/fase/{faseId}/delete',
        	data: {
        		authorities: ['ROLE_ADMIN']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/modello-fase/modello-fase-delete-dialog.html',
        			controller: 'ModelloFaseDeleteController',
        			controllerAs: 'vm',
        			backdrop: 'static',
        			size: 'md',
        			resolve: {
        				entity: ['ModelloFase', function(ModelloFase) {
        					return ModelloFase.get({id : $stateParams.faseId}).$promise;
        				}],
        			}
        		}).result.then(function() {
        			$state.go('^', null, { reload: 'modello-detail' });
        		}, function() {
        			$state.go('^');
        		});
        	}]
        })         
        .state('modello-detail.pacco-lavoro-new', {
        	parent: 'modello-detail',
        	url: '/pacco-lavoro/new',
        	data: {
        		authorities: ['ROLE_ADMIN']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/modello-tipologia-pacco-lavoro/modello-tipologia-pacco-lavoro-dialog.html',
        			controller: 'ModelloTipologiaPaccoLavoroDialogController',
        			controllerAs: 'vm',
        			backdrop: 'static',
        			size: 'lg',
        			resolve: {
        				entity: function () {
        					return {
        						id: null,
        						modelloId : $stateParams.id
        					};
        				}
        			}
        		}).result.then(function() {
        			$state.go('modello-detail', null, { reload: 'modello-detail' });
        		}, function() {
        			$state.go('modello-detail');
        		});
        	}]
        })         
        .state('modello-detail.pacco-lavoro-edit', {
        	parent: 'modello-detail',
        	url: '/pacco-lavoro/{tipologiaPaccoLavoroId}/edit',
        	data: {
        		authorities: ['ROLE_ADMIN']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/modello-tipologia-pacco-lavoro/modello-tipologia-pacco-lavoro-dialog.html',
        			controller: 'ModelloTipologiaPaccoLavoroDialogController',
        			controllerAs: 'vm',
        			backdrop: 'static',
        			size: 'lg',
        			resolve: {
        				entity: ['ModelloTipologiaPaccoLavoro', function(ModelloTipologiaPaccoLavoro) {
        					return ModelloTipologiaPaccoLavoro.get({id : $stateParams.tipologiaPaccoLavoroId}).$promise;
        				}],
        			}
        		}).result.then(function() {
        			$state.go('modello-detail', null, { reload: 'modello-detail' });
        		}, function() {
        			$state.go('modello-detail');
        		});
        	}]
        })         
        .state('modello-detail.pacco-lavoro-delete', {
        	parent: 'modello-detail',
        	url: '/pacco-lavoro/{tipologiaPaccoLavoroId}/delete',
        	data: {
        		authorities: ['ROLE_ADMIN']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/modello-tipologia-pacco-lavoro/modello-tipologia-pacco-lavoro-delete-dialog.html',
        			controller: 'ModelloTipologiaPaccoLavoroDeleteController',
        			controllerAs: 'vm',
        			backdrop: 'static',
        			size: 'md',
        			resolve: {
        				entity: ['ModelloTipologiaPaccoLavoro', function(ModelloTipologiaPaccoLavoro) {
        					return ModelloTipologiaPaccoLavoro.get({id : $stateParams.tipologiaPaccoLavoroId}).$promise;
        				}],
        			}
        		}).result.then(function() {
        			$state.go('^', null, { reload: 'modello-detail' });
        		}, function() {
        			$state.go('^');
        		});
        	}]
        })         
        .state('modello-detail.tipologia-voce-costo-new', {
        	parent: 'modello-detail',
        	url: '/tipologia-voce-costo/new',
        	data: {
        		authorities: ['ROLE_ADMIN']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/modello-tipologia-voce-costo/modello-tipologia-voce-costo-dialog.html',
        			controller: 'ModelloTipologiaVoceCostoDialogController',
        			controllerAs: 'vm',
        			backdrop: 'static',
        			size: 'lg',
        			resolve: {
        				entity: function () {
        					return {
        						id: null,
        						modelloId : $stateParams.id
        					};
        				}
        			}
        		}).result.then(function() {
        			$state.go('modello-detail', null, { reload: 'modello-detail' });
        		}, function() {
        			$state.go('modello-detail');
        		});
        	}]
        })         
        .state('modello-detail.tipologia-voce-costo-edit', {
        	parent: 'modello-detail',
        	url: '/tipologia-voce-costo/{tipologiaVoceCostoId}/edit',
        	data: {
        		authorities: ['ROLE_ADMIN']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/modello-tipologia-voce-costo/modello-tipologia-voce-costo-dialog.html',
        			controller: 'ModelloTipologiaVoceCostoDialogController',
        			controllerAs: 'vm',
        			backdrop: 'static',
        			size: 'lg',
        			resolve: {
        				entity: ['ModelloTipologiaVoceCosto', function(ModelloTipologiaVoceCosto) {
        					return ModelloTipologiaVoceCosto.get({id : $stateParams.tipologiaVoceCostoId}).$promise;
        				}],
        			}
        		}).result.then(function() {
        			$state.go('modello-detail', null, { reload: 'modello-detail' });
        		}, function() {
        			$state.go('modello-detail');
        		});
        	}]
        })         
        .state('modello-detail.tipologia-voce-costo-delete', {
        	parent: 'modello-detail',
        	url: '/tipologia-voce-costo/{tipologiaVoceCostoId}/delete',
        	data: {
        		authorities: ['ROLE_ADMIN']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/modello-tipologia-voce-costo/modello-tipologia-voce-costo-delete-dialog.html',
        			controller: 'ModelloTipologiaVoceCostoDeleteController',
        			controllerAs: 'vm',
        			backdrop: 'static',
        			size: 'md',
        			resolve: {
        				entity: ['ModelloTipologiaVoceCosto', function(ModelloTipologiaVoceCosto) {
        					return ModelloTipologiaVoceCosto.get({id : $stateParams.tipologiaVoceCostoId}).$promise;
        				}],
        			}
        		}).result.then(function() {
        			$state.go('^', null, { reload: 'modello-detail' });
        		}, function() {
        			$state.go('^');
        		});
        	}]
        })         
        .state('modello-detail.edit', {
            parent: 'modello-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/modello/modello-dialog.html',
                    controller: 'ModelloDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Modello', function(Modello) {
                            return Modello.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('modello.new', {
            parent: 'modello',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/modello/modello-dialog.html',
                    controller: 'ModelloDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                nome: null,
                                descrizione: null,
                                dataFine: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('modello', null, { reload: 'modello' });
                }, function() {
                    $state.go('modello');
                });
            }]
        })
        .state('modello.edit', {
            parent: 'modello',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/modello/modello-dialog.html',
                    controller: 'ModelloDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Modello', function(Modello) {
                            return Modello.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('modello', null, { reload: 'modello' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('modello.delete', {
            parent: 'modello',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/modello/modello-delete-dialog.html',
                    controller: 'ModelloDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Modello', function(Modello) {
                            return Modello.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('modello', null, { reload: 'modello' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
