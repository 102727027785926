(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('FaseController', FaseController);

    FaseController.$inject = ['$scope', '$state', 'Fase'];

    function FaseController ($scope, $state, Fase) {
        var vm = this;
        
        vm.fases = [];

        loadAll();

        function loadAll() {
            Fase.query(function(result) {
                vm.fases = result;
            });
        }
    }
})();
