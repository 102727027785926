(function() {
	'use strict';
	angular.module('rendicontaApp').factory('StatoProgetto', StatoProgetto);

	function StatoProgetto() {
		var disabilitato = false;
		
		function process(entity) {
			disabilitato = false;
			var today = new Date();
			today.setHours(0, 0, 0, 0);
			if (entity.disabilitazione && entity.disabilitazione < today) {
				disabilitato = true;
			}
		}

		function isDisabilitato() {
			return disabilitato;
		}

		return {
			isDisabilitato : isDisabilitato,
			process : process
		};
	}
})();
