(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('DettaglioVCPersonaleDialogController', DettaglioVCPersonaleDialogController);

    DettaglioVCPersonaleDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'DettaglioVCPersonale', 'Personale', 'Sal', 'paccoLavoro', 'TimesheetUtil', '$http'];

    function DettaglioVCPersonaleDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, DettaglioVCPersonale, Personale, Sal, paccoLavoro, TimesheetUtil, $http) {
        var vm = this;

        vm.dettaglioVCPersonale = entity;
        vm.clear = clear;
        vm.save = save;
        vm.searchPersonale = searchPersonale;
        vm.personaleAutocomplete = personaleAutocomplete;
//        vm.personales = Personale.autocomplete({progettoAziendaId:$stateParams.progettoAziendaId});
//        vm.localSearch = localSearch;
        vm.timesheetUpperBounds = [];
        vm.progettoAziendaId = $stateParams.progettoAziendaId;

        Sal.query({progettoId:$stateParams.progettoId, voceCostoId:$stateParams.voceCostoId}).$promise.then(function (sals) {
        	vm.sals = sals;
       		changeSal();
            creaMatrice();
            calcolaTotaleOre();
            $scope.$watch('vm.matrice', calcolaTotaleOre, true);
        });
        
	    function searchPersonale(userInputString, timeoutPromise) {
			return $http.get('/api/personales-ac', { params: { q : userInputString, progettoAziendaId : $stateParams.progettoAziendaId },  timeout : timeoutPromise });
		}

	    /*function localSearch(str, people) {
			var matches = [];
			people.forEach(function(person) {
				var fullName = person.nome + ' ' + person.cognome;
				if ((person.nome.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0)
						|| (person.cognome.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0)
						|| (fullName.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0)) {
					matches.push(person);
				}
			});
			return matches;
		};*/
        
        function personaleAutocomplete (selected) {
        	if (selected && selected.originalObject) {
        		vm.dettaglioVCPersonale.personaleId = selected.originalObject.id;
        	} else {
        		vm.dettaglioVCPersonale.personaleId = null;
        	}
        }

        //somma ore
        vm.totaleOre = 0;
        
        //generatore
        vm.mesi = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];
        vm.generatore = [0,100,8];
        vm.showGeneratore = false;
        vm.toggleGeneratore = toggleGeneratore;
        vm.generaTimesheet = generaTimesheet;
        vm.generaTimesheet2 = generaTimesheet2;

        vm.isTimesheetHidden = isTimesheetHidden;
        
        vm.faseNome = paccoLavoro.nome;
        vm.faseDataInizio = removeHHMM(new Date(paccoLavoro.faseInizio));
		vm.faseDataFine = removeHHMM(new Date(paccoLavoro.faseFine));

        vm.salDataInizio = "";
        vm.salDataFine = "";
        
        vm.changeSal = changeSal;
        
        vm.matrice = [];
        vm.registroPresenza = [];
        
        vm.checkub = true;
        vm.costoOrarioCalcolato = {};
        
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.$watch('vm.generatore', generatoreChange, true);
        $scope.$watch('vm.dettaglioVCPersonale.personaleId', changePersonale, true);
        $scope.$watch('vm.costoOrarioCalcolato', costoOrarioCalcolatoChange, true);

        
        function isActiveDate(date) {
	   		 if (vm.salDataInizio && vm.salDataFine) {
	   			 if (date >= vm.salDataInizio && date <= vm.salDataFine) {
	   				 return true;
	   			 } else {
	   				 return false;
	   			 }
	   		 } else {
	   			 return true;
	   		 }
       }
       
       function isTimesheetHidden(year) {
      	 if (!vm.salDataInizio|| !vm.salDataFine) {
  			 //no from/to param set
  			 return false;
  		 }
  		 
  		 var active = false;

  		 //check id there is at least one row to show
      	 for (var index = 1; index <= 12; index++) {
      		 var firstDay = new Date(year, index-1); //month in javascript starts from 0
      		 var lastDay = new Date(year, index, 0 ); //day 0: day before
      		 
      		 if (!isActiveDate(firstDay) && !isActiveDate(lastDay)
      				 && !(vm.salDataInizio < lastDay && vm.salDataFine > firstDay)
      		 ) {
      			 //no need to show row
      		 } else {
      			 active = true;
      			 break;
      		 }
      	 }
      	 return !active;
      }
        
        
        function generatoreChange() {
        	if (vm.generatore[0] == 100) {
        		vm.generatore[1] = 100;
        	} else if (vm.generatore[0] > vm.generatore[1]) {
        		vm.generatore[0] = vm.generatore[1];
        	}
        }

        function costoOrarioCalcolatoChange() {
        	if (vm.costoOrarioCalcolato && !vm.dettaglioVCPersonale.id) {
        		vm.dettaglioVCPersonale.costoOrario = vm.costoOrarioCalcolato.costo;
        	}
        }
        
        function roundToTwo(num) {    
            return +(Math.round(num + "e+2")  + "e-2");
        }
        
        function cleanupFinalPoint() {
        	try {
	        	for (var i = 0; i < vm.matrice.length; i++) {
	        		var dettagli = vm.matrice[i].dettagli;
	        		for (var j = 0; j < dettagli.length; j++) {
	        			var mese = dettagli[j];
	        			for (var k = 0; k < mese.length; k++) {
	        				if (mese[k] && typeof mese[k] === 'string') {
	        					if (mese[k].indexOf('.') == mese[k].length -1) {
	        						//remove '.'
	        						mese[k] = mese[k].substr(0, mese[k].length-1); 
	        					}
	        				};
	        			}
	        		}
	        	}
        	} catch(err) {
				if (typeof console !== "undefined") {
					console.error(err);
				}

			}
        }
        
        function calcolaTotaleOre() {
        	var somma = 0;
			try {

	        	for (var i = 0; i < vm.matrice.length; i++) {
	        		var dettagli = vm.matrice[i].dettagli;
	        		for (var j = 0; j < dettagli.length; j++) {
	        			var mese = dettagli[j];
	        			for (var k = 0; k < mese.length; k++) {
	        				if (mese[k]) {
	        					somma += Number(mese[k]) || 0;
	        				};
	        			}
	        		}
	        	}
        	
			} catch(err) {
				if (typeof console !== "undefined") {
					console.error(err);
				}

			}
        	vm.totaleOre = roundToTwo(somma);
        }
        
        function toggleGeneratore () {
        	vm.showGeneratore = !vm.showGeneratore;
        }
        
        function generaTimesheet2() {
        	var matriceArray = [];
        	
        	var valueToSet = vm.generatore[2];

        	
        	for (var i = 0; i < vm.matrice.length; i++) {
        		var mat = matriceArray[vm.matrice[i].anno] = vm.matrice[i].dettagli;
//        	}
        	
//        	for (var reg = 0; reg < matriceArray.length; reg++) {
//        		var mat = matriceArray[reg]; 
        		
        		var value;
        		var maxValue;
        		var currentDate;
        		
               	var easter = TimesheetUtil.easterDate(vm.matrice[i].anno);
        		
        		for (var m = 1; m <= 12; m++) {
        			for (var d = 1; d <= 31; d++) {
        				
        				currentDate = TimesheetUtil.buildDate(vm.matrice[i].anno, m, d);
        				
        				if (currentDate && 
        						currentDate >= vm.salDataInizio && 
        							currentDate <= vm.salDataFine) {
        					
        					if (currentDate.getDay() === 0 || currentDate.getDay() === 6) {
        						//weekend!
        						continue;
        					}
        					
        					if (TimesheetUtil.isHoliday(currentDate, easter)) {
        						//holiday!
        						continue;
        					}
        					
        					if (vm.timesheetUpperBounds[vm.matrice[i].anno][m][d] == 24) {
        						//no registro - no maxValue
        						maxValue = valueToSet;
        					} else {
        						maxValue = vm.timesheetUpperBounds[vm.matrice[i].anno][m][d];
        					}
        					
        					value = valueToSet < maxValue ? valueToSet : maxValue;
        					
	        				mat[m][d] = !value ? "" : value;
        				} else {
        					mat[m][d] = "";
        				}
        			}
        		}
        	}
        }
        
        function generaTimesheet() {

        	var matriceArray = [];
        	for (var i = 0; i < vm.matrice.length; i++) {
        		matriceArray[vm.matrice[i].anno] = vm.matrice[i].dettagli;
        	}
        	
        	for (var reg = 0; reg < vm.registroPresenza.length; reg++) {
        		var anno = vm.registroPresenza[reg].anno;

        		var mat = matriceArray[anno]; 
        		var registro = vm.registroPresenza[reg].dettagli; 
        		
        		var componenteRandom;
        		var componenteMinima;
        		var value;
        		var currentDate;
        		
        		
        		for (var m = 1; m <= 12; m++) {
        			for (var d = 1; d <= 31; d++) {
        				
        				currentDate = TimesheetUtil.buildDate(anno, m, d);
        				if (currentDate && currentDate >= vm.salDataInizio && currentDate <= vm.salDataFine) {
        					
        					
        					componenteMinima = Math.round(registro[m][d] * vm.generatore[0] / 100);
        					componenteRandom = Math.round((registro[m][d] * vm.generatore[1] / 100) - componenteMinima);
	        				
	        				value = componenteMinima + Math.round(Math.random() * componenteRandom);
	        				
        					var upperBound = vm.timesheetUpperBounds[anno][m][d];
	        				if (value > upperBound) {
        						value = upperBound;
	        				}
	        				
	        				mat[m][d] = !value ? "" : value;
        				} else {
        					mat[m][d] = "";
        				}
        			}
        		}
        	}
        }
        
        function initUpperBoundTimesheet() {
        	
        	//per ogni dettaglio
        		//skip quello corrente
        		//riempio matrice risultato
        	
        	vm.timesheetUpperBounds.length = 0;
        	var result = vm.timesheetUpperBounds;

        	var registroPresenzaArray = [];
        	for (var i = 0; i < vm.registroPresenza.length; i++) {
        		registroPresenzaArray[vm.registroPresenza[i].anno] = vm.registroPresenza[i].dettagli;
        	}

        	//per ogni registro presenza, inizializzo
        	
        	var startYear = vm.salDataInizio.getFullYear();
        	var endYear = vm.salDataFine.getFullYear();
        	
        	
	        	for (var anno = startYear ; anno <= endYear; anno++) {
	        		
	        		for (var m = 1; m <= 12; m++) {
	        			for (var d = 1; d <= 31; d++) {
	        				var boxDate = TimesheetUtil.buildDate(anno, m, d);
	        				if (!boxDate) {
	        					//day does not exist
	        					continue;
	        				}
	        				if (boxDate > vm.salDataFine || boxDate < vm.salDataInizio) {
	        					//outside range
	        					//TODO consider fase too?
	        					continue;
	        				}
	        				
	        				if (!result[anno]) {
	        					result[anno] = [];
	        				}
	        				if (!result[anno][m]) {
	        					result[anno][m] = [];
	        				}
	        				if (typeof(result[anno][m][d]) === 'undefined') {
	        					//init
	        					if (typeof(registroPresenzaArray[anno]) !== 'undefined') {
	        						result[anno][m][d] = Number(registroPresenzaArray[anno][m][d]);
		        				} else {
		        					result[anno][m][d] = 24;
		        				}
	        				}
	        			}
	        		}
	        	}
        }

        function calcolaUpperBoundTimesheet(dettagli) {
        	//init
        	initUpperBoundTimesheet();
        	
        	//per ogni dettaglio
        		//skip quello corrente
        		//riempio matrice risultato
        	
        	var result = vm.timesheetUpperBounds;

        	var registroPresenzaArray = [];
        	for (var i = 0; i < vm.registroPresenza.length; i++) {
        		registroPresenzaArray[vm.registroPresenza[i].anno] = vm.registroPresenza[i].dettagli;
        	}
        	
        	for (var i = 0; i < dettagli.length; i++) {
        		
        		var dettaglio = dettagli[i];
        		if (vm.dettaglioVCPersonale.id && (vm.dettaglioVCPersonale.id == dettaglio.id)) {
        			//non considero dettaglio corrente
        			continue;
        		}
        		
        		//check timesheet intersect iniziosal/finesal
        		var inizioTimesheet = removeHHMM(new Date(dettaglio.salDataInizio));
        		var fineTimesheet = removeHHMM(new Date(dettaglio.salDataFine));
        		
        		//se non interseca, skip
        		if (inizioTimesheet > vm.salDataFine) {
        			continue;
        		}
        		if (fineTimesheet < vm.salDataInizio) {
        			continue;
        		}
        		
        		if (!dettaglio.timesheet) {
        			//something wrong with that timesheet!
        			continue;
        		}
        		
        		//se sto qui, ho un'intersezione non vuota
        		var dettaglioTimesheets = JSON.parse(dettaglio.timesheet);
        		
	        	for (var t = 0; t < dettaglioTimesheets.length; t++) {
	        		
	        		var anno = dettaglioTimesheets[t].anno;
	        		var timesheet = dettaglioTimesheets[t].dettagli;
	        		
	        		for (var m = 1; m <= 12; m++) {
	        			for (var d = 1; d <= 31; d++) {
	        				var boxDate = TimesheetUtil.buildDate(anno, m, d);
	        				if (!boxDate) {
	        					//day does not exist
	        					continue;
	        				}
	        				if (boxDate > vm.salDataFine || boxDate < vm.salDataInizio) {
	        					//outside range
	        					continue;
	        				}
	        				//should be considered
	        				//init if needed
	        				if (typeof(registroPresenzaArray[anno]) === 'undefined') {
								// skip
								continue;
							}
	        				result[anno][m][d] -= Number(timesheet[m][d]);
	        				if (result[anno][m][d] < 0) {
	        					result[anno][m][d] = 0;
	        				}
	        			}
	        		}
	        	}
        	}
        }
        
        function creaMatrice () {
        	if (vm.dettaglioVCPersonale.id && vm.dettaglioVCPersonale.timesheet) {
        		//parse value
        		vm.matrice = JSON.parse(vm.dettaglioVCPersonale.timesheet);
        	}
        }
        
        function removeHHMM(date) {
        	return new Date(date.getFullYear(), date.getMonth(), date.getDate());
        }
        
        function changeSal() {
        	if (vm.dettaglioVCPersonale.salId) {
        		//set anno
        		var sal = vm.sals.filter(function(sal) { return sal.id == vm.dettaglioVCPersonale.salId})[0];
        		
        		var salDataInizio = removeHHMM(new Date(sal.dataInizio));
        		var salDataFine = removeHHMM(new Date(sal.dataFine));
        		
        		//intersect salData* with faseData*
        		vm.salDataInizio = salDataInizio > vm.faseDataInizio ? salDataInizio : vm.faseDataInizio;
       			vm.salDataFine = salDataFine < vm.faseDataFine ? salDataFine : vm.faseDataFine;
        		
        		vm.anno = [];
        		vm.matrice = [];
        		for (var i = vm.salDataInizio.getFullYear(); i <= vm.salDataFine.getFullYear(); i++) {
        			vm.anno.push(i);
        			vm.matrice.push({anno: i, dettagli: ""});
        		}
            	changePersonale();
        	} else {
        		vm.anno = "";
   				vm.registroPresenza = "";
   				vm.salDataInizio = "";
        		vm.salDataFine = "";
        	}
        }

        function changePersonale () {
        	var promises = [];
        	var timesheets;
        	if (vm.dettaglioVCPersonale.personaleId && vm.dettaglioVCPersonale.salId) {
        		Personale.getCostoBySal({id: vm.dettaglioVCPersonale.personaleId, salId: vm.dettaglioVCPersonale.salId}).$promise.then(function(result) {
        			vm.costoOrarioCalcolato.costo = result.costoOrario || '';
        			vm.costoOrarioCalcolato.anno = result.anno || '';
        		});
        		
            	//carica presenze registrate in tutti i dettagli
        		var promise = Personale.getTimesheets({id: vm.dettaglioVCPersonale.personaleId}).$promise;
        		promises.push(promise);
        		promise.then(function(result) {
        			timesheets = result;
        		});
        	} else {
        		vm.costoOrarioCalcolato.costo = '';
        		vm.costoOrarioCalcolato.anno = '';
        		vm.timesheetUpperBounds.length = 0;
        	}
        	
        	if (vm.dettaglioVCPersonale.personaleId && vm.anno) {
        		vm.registroPresenza = [];
        		
        		for (var i = 0; i < vm.anno.length; i++) {
        			var annoCorrente = vm.anno[i];
        			
        			//carica registro presenze
        			var promise = Personale.getFoglioPresenzaByAnno({id: vm.dettaglioVCPersonale.personaleId, anno: annoCorrente}).$promise;
            		promises.push(promise);

            		promise.then(function(result) {
        				if (result.dettagli) {
        					vm.registroPresenza.push({anno: result.anno, dettagli: JSON.parse(result.dettagli)});
        				} else {
        					//can return 204, does nothing
        				}
        			});
        		}
        	}
        	
        	if (promises.length) {
        		Promise.all(promises).then(function(result) {
        			calcolaUpperBoundTimesheet(timesheets)
        		});
        	}
        	
        }

        function save () {
            vm.isSaving = true;

            cleanupFinalPoint();
            
            vm.dettaglioVCPersonale.timesheet = JSON.stringify(vm.matrice);
            
            if (vm.dettaglioVCPersonale.id !== null) {
                DettaglioVCPersonale.update(vm.dettaglioVCPersonale, onSaveSuccess, onSaveError);
            } else {
                DettaglioVCPersonale.save(vm.dettaglioVCPersonale, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('rendicontaApp:dettaglioVCPersonaleUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
