(function() {
    'use strict';
    angular
        .module('rendicontaApp')
        .factory('Personale', Personale);

    Personale.$inject = ['$resource', 'DateUtils'];

    function Personale ($resource, DateUtils) {
        var resourceUrl =  'api/personales/:id';

        return $resource(resourceUrl, {}, {
        	'query': { method: 'GET', isArray: true},
            'autocomplete': { method: 'GET', isArray: true, url: 'api/personales-ac'},
            'getFogliPresenza': { method: 'GET', isArray: true, url: 'api/personales/:id/foglio-presenzas'},
            'getCostos': { method: 'GET', isArray: true, url: 'api/personales/:id/costos'},
            'getTimesheets': { method: 'GET', isArray: true, url: 'api/personales/:id/timesheets'},
            'getCostoBySal': {
                method: 'GET',
                url: 'api/personales/:id/costos-by-sal/:salId',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dataAssunzione = DateUtils.convertLocalDateFromServer(data.dataAssunzione);
                        data.dataNascita = DateUtils.convertLocalDateFromServer(data.dataNascita);
                        data.tsData = DateUtils.convertLocalDateFromServer(data.tsData);
                    }
                    return data;
                }
            },
            'getFoglioPresenzaByAnno': {
            	method: 'GET',
            	url: 'api/personales/:id/foglio-presenzas/anno/:anno',
            	transformResponse: function (data) {
            		if (data) {
            			data = angular.fromJson(data);
            		}
            		return data;
            	}
            },
            'get': {
            	method: 'GET',
            	transformResponse: function (data) {
            		if (data) {
            			data = angular.fromJson(data);
            			data.dataAssunzione = DateUtils.convertLocalDateFromServer(data.dataAssunzione);
            			data.dataNascita = DateUtils.convertLocalDateFromServer(data.dataNascita);
            			data.tsData = DateUtils.convertLocalDateFromServer(data.tsData);
            		}
            		return data;
            	}
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dataAssunzione = DateUtils.convertLocalDateToServer(copy.dataAssunzione);
                    copy.dataNascita = DateUtils.convertLocalDateToServer(copy.dataNascita);
                    copy.tsData = DateUtils.convertLocalDateToServer(copy.tsData);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dataAssunzione = DateUtils.convertLocalDateToServer(copy.dataAssunzione);
                    copy.dataNascita = DateUtils.convertLocalDateToServer(copy.dataNascita);
                    copy.tsData = DateUtils.convertLocalDateToServer(copy.tsData);
                    return angular.toJson(copy);
                }
            }
        });
    }
})();
