(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .controller('ModelloFaseController', ModelloFaseController);

    ModelloFaseController.$inject = ['$scope', '$state', 'ModelloFase'];

    function ModelloFaseController ($scope, $state, ModelloFase) {
        var vm = this;
        
        vm.modelloFases = [];

        loadAll();

        function loadAll() {
            ModelloFase.query(function(result) {
                vm.modelloFases = result;
            });
        }
    }
})();
