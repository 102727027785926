(function() {
    'use strict';

    angular
        .module('rendicontaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('progetto-azienda', {
            parent: 'entity',
            url: '/progetto-azienda',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'ProgettoAziendas'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/progetto-azienda/progetto-aziendas.html',
                    controller: 'ProgettoAziendaController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('progetto-azienda-detailx.ra-new', {
        	parent: 'progetto-azienda-detailx',
        	url: '/ra/new',
        	data: {
        		authorities: ['ROLE_USER']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/progetto/progetto-profilo-dialog.html',
        			controller: 'ProgettoProfiloDialogController',
        			controllerAs: 'vm',
        			backdrop: 'static',
        			size: 'lg',
        			resolve: {
        				entity: function () {
        					return {
        						id: null,
        						progettoId : $stateParams.progettoId,
        						progettoAziendaId: $stateParams.progettoAziendaId
        					};
        				},
        				livello: function() { return 'PA'; }
        			}
        		}).result.then(function() {
        			$state.go('^', null, { reload: 'progetto-azienda-detailx' });
        		}, function() {
        			$state.go('^');
        		});
        	}]
        })        
        .state('progetto-azienda-detail', {
            parent: 'entity',
            url: '/progetto-azienda/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'ProgettoAzienda'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/progetto-azienda/progetto-azienda-detail.html',
                    controller: 'ProgettoAziendaDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'ProgettoAzienda', function($stateParams, ProgettoAzienda) {
                    return ProgettoAzienda.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'progetto-azienda',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('progetto-azienda-detail.edit', {
            parent: 'progetto-azienda-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/progetto-azienda/progetto-azienda-dialog.html',
                    controller: 'ProgettoAziendaDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ProgettoAzienda', function(ProgettoAzienda) {
                            return ProgettoAzienda.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('progetto-azienda.new', {
            parent: 'progetto-azienda',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/progetto-azienda/progetto-azienda-dialog.html',
                    controller: 'ProgettoAziendaDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('progetto-azienda', null, { reload: 'progetto-azienda' });
                }, function() {
                    $state.go('progetto-azienda');
                });
            }]
        })
        .state('progetto-azienda.edit', {
            parent: 'progetto-azienda',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/progetto-azienda/progetto-azienda-dialog.html',
                    controller: 'ProgettoAziendaDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ProgettoAzienda', function(ProgettoAzienda) {
                            return ProgettoAzienda.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('progetto-azienda', null, { reload: 'progetto-azienda' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('progetto-azienda.delete', {
            parent: 'progetto-azienda',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/progetto-azienda/progetto-azienda-delete-dialog.html',
                    controller: 'ProgettoAziendaDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['ProgettoAzienda', function(ProgettoAzienda) {
                            return ProgettoAzienda.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('progetto-azienda', null, { reload: 'progetto-azienda' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
          .state('progetto-azienda-detailx.edit', {
            parent: 'progetto-azienda-detailx',
            url: '/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/progetto-azienda/progetto-azienda-dialog2.html',
                    controller: 'ProgettoAziendaDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ProgettoAzienda', function(ProgettoAzienda) {
                            return ProgettoAzienda.get({id : $stateParams.progettoAziendaId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: 'progetto-azienda-detailx' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
          .state('progetto-azienda-detailx', {
        	parent: 'progetto-detailx',
        	url: '/progetto-azienda/{progettoAziendaId}',
        	data: {
        		authorities: ['ROLE_USER'],
        		pageTitle: 'Azienda'
        	},
        	views: {
        		'content@': {
        			templateUrl: 'app/entities/progetto-azienda/progetto-azienda-detailx.html',
        			controller: 'ProgettoAziendaDetailController',
        			controllerAs: 'vm'
        		}
        	},
        	resolve: {
        		entity: ['$stateParams', 'ProgettoAzienda', 'StatoCertificazione', function($stateParams, ProgettoAzienda, StatoCertificazione) {
        			var entity =  ProgettoAzienda.get({id : $stateParams.progettoAziendaId}).$promise;
        			entity.then(function (entity) {StatoCertificazione.setCertificato(entity.certificato)});
        			return entity;
        		}],
        		previousState: ["$state", function ($state) {
        			var currentStateData = {
        					name: $state.current.name || 'progetto-aziendax',
        					params: $state.params,
        					url: $state.href($state.current.name, $state.params)
        			};
        			return currentStateData;
        		}]
        	}, 
        	ncyBreadcrumb: {
        		label: 'Azienda',
        		parent: 'progetto-detailx'
        	}
        })
        .state('progetto-azienda-detailx.allegato-edit', {
        	parent: 'progetto-azienda-detailx',
        	url: '/allegato/{allegatoId}/edit',
        	data: {
        		authorities: ['ROLE_USER']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/allegato/allegato-dialog.html',
        			controller: 'AllegatoDialogController',
        			controllerAs: 'vm',
        			size: 'md',
        			resolve: {
        				entity: ['Allegato', function(Allegato) {
        					return Allegato.get({id : $stateParams.allegatoId}).$promise;
        				}]
        			}
        		}).result.then(function() {
        			$state.go('^', null, { reload: 'progetto-azienda-detailx' });
        		}, function() {
        			$state.go('^');
        		});
        	}]
        })        
        .state('progetto-azienda-detailx.allegato-delete', {
            parent: 'progetto-azienda-detailx',
            url: '/allegato/{allegatoId}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/allegato/allegato-delete-dialog.html',
                    controller: 'AllegatoDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Allegato', function(Allegato) {
                            return Allegato.get({id : $stateParams.allegatoId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: 'progetto-azienda-detailx' });
                }, function() {
                    $state.go('^');
                });
            }]
        })       
         .state('progetto-azienda-detailx.allegato-new', {
            parent: 'progetto-azienda-detailx',
            url: '/allegato/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/allegato/allegato-dialog.html',
                    controller: 'AllegatoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                nome: null,
                                descrizione: null,
                                bytes: null,
                                bytesContentType: null,
                                id: null,
                                progettoAziendaId : $stateParams.progettoAziendaId
                            };
                        },
                        relatedEntity: ['ProgettoAzienda', function(ProgettoAzienda) {
                            return ProgettoAzienda.get({id : $stateParams.progettoAziendaId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: 'progetto-azienda-detailx' });
                }, function() {
                    $state.go('^');
                });
            }]
        })    
        .state('progetto-azienda-detailx.pacco-lavoro-new', {
        	parent: 'progetto-azienda-detailx',
        	url: '/pacco-lavoro/new',
        	data: {
        		authorities: ['ROLE_USER']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/pacco-lavoro/pacco-lavoro-dialog.html',
        			controller: 'PaccoLavoroDialogController',
        			controllerAs: 'vm',
        			backdrop: 'static',
        			size: 'lg',
        			resolve: {
        				entity: function () {
        					return {
        						id: null,
        						progettoAziendaId: $stateParams.progettoAziendaId
        					};
        				}
        			}
        		}).result.then(function() {
        			$state.go('^', null, { reload: 'progetto-detailx' });
        		}, function() {
        			$state.go('^');
        		});
        	}]
        })  
        .state('progetto-azienda-detailx.pacco-lavoro-delete', {
            parent: 'progetto-azienda-detailx',
            url: '/pacco-lavoro/{paccoLavoroId}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/pacco-lavoro/pacco-lavoro-delete-dialog.html',
                    controller: 'PaccoLavoroDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['PaccoLavoro', function(PaccoLavoro) {
                            return PaccoLavoro.get({id : $stateParams.paccoLavoroId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: 'progetto-azienda-detailx' });
                }, function() {
                    $state.go('^');
                });
            }]
        })    
        .state('scadenziario', {
        	parent: 'progetto-azienda-detailx',
        	url: '/scadenziario',
        	data: {
        		authorities: ['ROLE_USER'],
        		pageTitle: 'Scadenziario'
        	},
        	views: {
        		'content@': {
        			templateUrl: 'app/entities/progetto-azienda/scadenziario.html',
        			controller: 'ScadenziarioDetailController',
        			controllerAs: 'vm'
        		}
        	},
        	resolve: {
        		entity: ['$stateParams', 'ProgettoAzienda', function($stateParams, ProgettoAzienda) {
        			return ProgettoAzienda.get({id : $stateParams.progettoAziendaId}).$promise;
        		}],
        	}, 
        	ncyBreadcrumb: {
        		label: 'Scadenziario',
        		parent: 'progetto-azienda-detailx'
        	}
        })
        ;
    }

})();
